import { useSelector } from 'react-redux';
import {
  country as companyCountry,
  electronicInvoicing,
} from '../../selectors/company';
import { useTicketBaiInformation } from '../useTicketBaiElegible';
import { COUNTRIES } from '../../utils/enums/countries';

const useElectronicPayrollStatus = () => {
  const isElectronic = useSelector(electronicInvoicing);
  const country = useSelector(companyCountry);
  const { isTicketBaiElegible, isTicketBaiEnabled } = useTicketBaiInformation();

  const availableCountries = [
    'peru',
    'panama',
    'republicaDominicana',
    'colombia',
    'argentina',
    'costaRica',
    'mexico',
    'spain'
  ];

  return {
    isActive: country === COUNTRIES.SPAIN ? isTicketBaiEnabled : isElectronic,
    availableCountries,
    canElectronicPayroll: country === COUNTRIES.SPAIN ? availableCountries.includes(country) && isTicketBaiElegible : availableCountries.includes(country),
  };
};

export default useElectronicPayrollStatus;
