import React, { useRef, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'aws-amplify';
import { get, isArray } from 'lodash'

import { companySelector, country as countrySelector, decimalPrecision } from '../../../../selectors/company'
import { getMainCurrency } from '../../../../selectors/currencies'

import BigNumber from 'bignumber.js';
import { decreaseItem, removeItem, increaseItem, updateItem } from '../../../../reducers/activeRefund';
import { calculateSingleItemValues, calculateSingleItemValuesWithDecimals } from '../../../../utils';
import { useDecimalsVersionsGroup } from '../../../../hooks/useDecimalsVersionsGroup';
import { DECIMALS_ACTIVE_COUNTRIES, DECIMALS_ACTIVE_IDS } from '../../../../hooks/useDecimalsVersionsGroup/hook';
import { Tooltip } from '@alegradev/smile-ui-react';

import { Icon } from '@alegradev/smile-ui-react';

const getItemTypeText = (itemType) => {
  switch (itemType) {
    case 'product':
      return 'item';
    case 'service':
      return 'service';
    case 'kit':
      return 'kit';
    default:
      return 'item';
  }
}

const Item = ({ item, index, openEditItem}) => {
  const itemRef = useRef();
  const company = useSelector(companySelector);
  const dispatch = useDispatch();
  const country = useSelector(countrySelector)
  const decimal = useSelector(decimalPrecision);
  const mainCurrency = useSelector(getMainCurrency);
  const taxReduced = useMemo(() => {
    const taxes = isArray(get(item, "tax")) ? get(item, "tax") : [];
    return taxes.reduce((prev, t) => prev + Number(t.percentage), 0) / 100;
  } , [item])
  const isInactive = get(item, 'status') === 'inactive';
  const { isDecimalActive } = useDecimalsVersionsGroup();

  useEffect(() => {
    itemRef.current.scrollIntoView({
      behavior: 'smooth', block: 'nearest'
    });
  }, [])


  const increaseQty = useCallback((e) => {
    e.stopPropagation();
    if (isInactive) return;
    dispatch(increaseItem(index));
  }, [item])

  const discardItem = useCallback((e) => {
    e.stopPropagation();
    dispatch(removeItem(index));
  }, [])

  const decreaseQty = useCallback((e) => {
    e.stopPropagation();
    if (isInactive) return;
    if (item.quantity > 1)
      return dispatch(decreaseItem(index));

    discardItem(e);
  }, [item])

  const updateQty = useCallback((e) => {
    e.stopPropagation();
    const value = e.target.value;
    dispatch(updateItem({ index, values: { quantity: value } }));
  }, [])

  if (!item)
    return null;

  const prefix = !!get(mainCurrency, 'symbol') ? get(mainCurrency, 'symbol') : ''

  const fmt = {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  }

  const itemValues = useMemo(() => {
    if(DECIMALS_ACTIVE_COUNTRIES.includes(country) || DECIMALS_ACTIVE_IDS.includes(company?.id)) {
      return calculateSingleItemValuesWithDecimals(item, decimal)
    }

    return calculateSingleItemValues(item, decimal)
  }, [item, decimal, country, company]);

  const itemType = get(item, 'type', 'product');
  const itemTypeText = getItemTypeText(itemType);

  const itemTotal = new BigNumber(item.price).times(item.quantity).times(1 + taxReduced).toFormat(decimal, fmt);

  const changeDecimalsToCompanyDefaults = (value) => {
    if(!isDecimalActive) return value;
    const bigValue = new BigNumber(value);
    return bigValue.decimalPlaces(decimal).toNumber();
  };

  return (
    <div className={`d-flex position-relative new-refund__item-container ${isInactive ? 'disabled' : 'pointer'}`} 
      onClick={() => {if (!isInactive) openEditItem(index)}}
    >
      <div className={`new-refund__item ${isInactive && 'inactive'} d-flex flex-column-reverse py-3 pl-3`} ref={itemRef}>
        <div className='px-3'>
          {get(item, 'status') === 'inactive' && (
            <div className='w-100 d-flex align-items-center'>
              <Icon icon='alert-triangle' extraClass="warning-icon-refund" />
              <span className="warning-refund-item-text pl-3">
                {
                  I18n.get(itemTypeText, 'Producto') + ' ' +
                  I18n.get('refundItemInactive', 'desactivado, actívalo de nuevo para poder devolverlo.')
                }
              </span>
            </div>)
          }
        </div>

        <div className="new-refund__item-info d-flex align-items-center justify-content-between px-3 py-1">
          <div className="d-flex flex-column text-break text-truncate text-nowrap new-refund__item-data">
            <div className="new-refund__item-name name-large text-truncate">
              {get(item, 'name')}
            </div>
            <div className="d-flex text-break text-truncate text-nowrap">
              <div className="new-refund__item-price">
                {new BigNumber(item.price).toFormat(decimal, fmt)}
              </div>
              <div className="new-refund__item-reference ml-2">
                {!!get(item, 'reference.reference')
                  ? " | " + get(item, 'reference.reference') : !!get(item, 'reference')
                    ? " | " + get(item, 'reference') : ''
                }
              </div>
            </div>
            <div className="text-left text-truncate text-nowrap text-muted discount">
              {!!get(item, 'discount') && BigNumber(item.discount).gt(0) && (
                "-" + itemValues.discountValue.toFormat(decimal, fmt)
              )}
            </div>
          </div>

          <div style={{flex: 'auto'}} className="d-flex flex-column justify-content-md-center align-items-end new-refund__item-total-price">
            <div style={{position: 'relative', height: '32px'}} className="text-right text-truncate text-nowrap new-refund__item-container-price-actions">
              <div className={`new-refund__item-actions`}>
                {
                  !isInactive &&
                  <Tooltip
                    placement="top"
                    overlay="Editar">
                    <div className="new-refund__item-actions-button d-flex justify-content-center first"
                      onClick={() => openEditItem(index)}>
                      <Icon icon='pencil' />
                    </div>
                  </Tooltip>
                }
                <Tooltip
                  placement="top"
                  overlay="Quitar">
                  <div className="new-refund__item-actions-button d-flex justify-content-center"
                    onClick={discardItem}>
                    <Icon icon='trash' />
                  </div>
                </Tooltip>
              </div>
              {itemTotal}
            </div>


            <div className={`d-flex new-refund__item-quantity-container align-items-center`}>
              <div
                className={`d-flex justify-content-center align-items-center ${isInactive ? 'disabled' : 'pointer'}`}
                onClick={decreaseQty}
              >
                <Icon icon='minus' extraClass="icon-gray" />
              </div>
              <div className="d-flex justify-content-center align-items-center new-refund__item-quantity">
                <input type="number" name="item.quantity" value={changeDecimalsToCompanyDefaults(item.quantity)} onChange={updateQty} />
              </div>
              <div
                className={`d-flex justify-content-center align-items-center ${isInactive ? 'disabled' : 'pointer'}`}
                onClick={increaseQty}
              >
                <Icon icon='plus' extraClass="icon-gray" />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

Item.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.any.isRequired
}

export default Item;

