import { I18n } from 'aws-amplify';
import { get, capitalize } from 'lodash';
import { validateCreditLimit } from '../validations';

export const republicaDominicanaValidation = (values) => {
  let errors = {};
  const identificationType = get(values, 'identification.type', '');
  const identificationNumber = get(values, 'identification.number', '');

  const isElectronic = get(values, 'isElectronic', '');
  const isCorrectFormat = !!identificationType
    ? true
    : identificationNumber?.match(/^(?:\d{9}|\d{11})$/);

  if (!identificationNumber && !!isElectronic)
    errors.identification = {
      ...errors.identification,
      number: capitalize(I18n.get('enterAnID', 'Ingresa una identificación')),
    };

  if (!!identificationNumber && !isCorrectFormat)
    errors.identification = {
      ...errors.identification,
      number: capitalize(
        I18n.get(
          'identificationDefaultError',
          'Debe ser un número de 9 a 11 dígitos'
        )
      ),
    };

  if (
    identificationType &&
    identificationType === 'RNC' &&
    identificationNumber &&
    identificationNumber.length !== 9
  )
    errors.identification = {
      ...errors.identification,
      number: I18n.get(
        'identificationFailed.rnc',
        'El RNC debe ser un valor numérico de 9 dígitos de longitud'
      ),
    };

  if (
    identificationType &&
    identificationType === 'CED' &&
    identificationNumber &&
    identificationNumber.length !== 11
  )
    errors.identification = {
      ...errors.identification,
      number: I18n.get(
        'identificationFailed.ced',
        'La cédula debe ser un valor numérico de 11 dígitos de longitud'
      ),
    };
  const creditLimitError = validateCreditLimit(values);
  if (creditLimitError) {
    errors.creditLimit = creditLimitError;
  }
  return errors;
};
