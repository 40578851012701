import { Grid, toastHandler, useModal } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { get, upperCase } from 'lodash';
import { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  companySelector,
  country as countrySelector,
  electronicInvoicing,
} from '../../../../selectors/company';

import { COUNTRIES } from '../../../../utils/enums/countries';

import * as contactsDB from '../../../../database/contactsDB';
import contactsBearerAPI from '../../../../reducers/contactsBearerAPI';

import kindOfPersonOptions from '../../../../components/countriesData/colombia/kindOfPerson';
import argIvaConditions from '../../../../components/countriesData/argentina/ivaConditions';
import initialValues from './utils/initialValues';
import {
  getDefaultAccountReceivableCategory,
  getDefaultDebtToPayCategory,
} from '../../../../selectors/categories';
import CreatedClient from './modals/CreatedClient';
import { useParams } from 'react-router-dom';
import { useContactQuery } from '../../services/hooks';
import { useContact } from '../ContactProvider';
import useConnectionStatus from '../../../../hooks/useConnectionStatus/hook';
import { hasPermissionTo } from '../../../../selectors/auth';
import { activePriceLists } from '../../../../selectors/priceLists';
import { activePaymentTerms } from '../../../../selectors/paymentTerms';
import { activeSellers } from '../../../../selectors/sellers';
import { updateSideModal } from '../../../../reducers/sideModals';

const ContactFormContext = createContext({
  contact: null,
  searchingClient: false,
  duplicatedContact: false,
  handleClientSearch: () => {},
  setIsDuplicatedContact: () => {},
  openDuplicateContactModal: () => {},
});

export const canScrappingByCountry = (country, identificationType, online) => {
  if (!online) return false;
  switch (country) {
    case COUNTRIES.COLOMBIA:
      return ['CC', 'NIT'].includes(identificationType);
    case COUNTRIES.COSTA_RICA:
      return ['CF', 'CJ', 'DIMEX', 'NITE'].includes(identificationType);
    case COUNTRIES.ARGENTINA:
      return ['CUIT'].includes(identificationType);
    case COUNTRIES.PERU:
      return ['DNI', 'RUC'].includes(identificationType);
    case COUNTRIES.PANAMA:
      return true;
    case COUNTRIES.REPUBLICA_DOMINICANA:
      return ['RNC', 'CED'].includes(identificationType);
    default:
      return false;
  }
};

function ContactFormProvider({ children, from = 'form' }) {
  const { Wrapper } = Grid;
  const [duplicatedContact, setIsDuplicatedContact] = useState(null);
  const { openModal, closeModal, openendModals } = useModal();
  const online = useConnectionStatus();
  const { toggleEditable, toggleContact, contact, toggleIgnoreRepeated } =
    useContact();
  const can = useSelector(hasPermissionTo);
  const params = useParams();
  const { data } = useContactQuery(params?.id);
  const company = useSelector(companySelector);
  const country = useSelector(countrySelector);
  const form = useForm();
  const history = useHistory();
  const priceLists = useSelector(activePriceLists);
  const paymentTerms = useSelector(activePaymentTerms);
  const sellers = useSelector(activeSellers);

  const isElectronic = useSelector(electronicInvoicing);
  const defaultAccountReceivableCategory = useSelector(
    getDefaultAccountReceivableCategory
  );
  const defaultDebtToPayCategory = useSelector(getDefaultDebtToPayCategory);

  const sidebarContact = useSelector((state) =>
    get(state, 'sideModals.contact.params.contact', null)
  );

  const [searchingClient, setSearchingClient] = useState(false);

  useEffect(() => {
    setIsDuplicatedContact(null);
  }, []);

  useEffect(() => {
    if (sidebarContact) toggleContact(sidebarContact);
    if (sidebarContact && sidebarContact?.status === 'active') toggleEditable();
  }, [sidebarContact]);

  useEffect(() => {
    const values = form.getState().values;
    const formInitialValues = initialValues({
      country,
      defaultAccountReceivableCategory,
      defaultDebtToPayCategory,
      isElectronic,
      contact,
      values,
      company,
      priceLists,
      paymentTerms,
      sellers,
    });
    if (formInitialValues) {
      Object.keys(formInitialValues).forEach((key) =>
        form.change(key, formInitialValues[key])
      );
    }
  }, [contact]);

  useEffect(() => {
    const values = form.getState().values;
    if (!values?.accounting?.accountReceivable)
      form.change(
        'accounting.accountReceivable',
        defaultAccountReceivableCategory
      );
    if (!values?.accounting?.debtToPay)
      form.change('accounting.debtToPay', defaultDebtToPayCategory);
  }, []);

  useEffect(() => {
    if (data) {
      toggleContact(data);
      toggleEditable();
    }
  }, [data]);

  const openDuplicateContactModal = ({ client }) => {
    if (from === 'sideModal') {
      setIsDuplicatedContact(client);
      return;
    }
    const modalProps = {
      name: 'createdContact',
      title: I18n.get(
        'existedContact',
        '¡Ya existe un contacto con esta identificación!'
      ),
      component: CreatedClient,
      props: { client },
      description: I18n.get(
        'existedContactDescription',
        'El numero de identificación que ingresaste se encuentra <br/> asociado a:'
      ),
    };

    if (!can('edit', 'contacts')) {
      openModal({
        ...modalProps,
        secondaryAction: {
          text: I18n.get('cancel', 'Cancelar'),
          closeOnClick: true,
          onClick: () => history.push('/contacts'),
        },
        primaryAction: {
          text: I18n.get(
            'duplicatedIdentificationContinueButton',
            'Crear cliente duplicado'
          ),
          closeOnClick: true,
          onClick: () => toggleIgnoreRepeated(),
        },
      });
    } else {
      openModal({
        ...modalProps,
        primaryAction: {
          text: I18n.get('editExistingContact', 'Editar contacto existente'),
          closeOnClick: true,
          onClick: () => {
            toggleContact(client);
            toggleEditable();
          },
        },
        secondaryAction: {
          text: I18n.get(
            'duplicatedIdentificationContinueButton',
            'Crear cliente duplicado'
          ),
          closeOnClick: true,
          onClick: () => toggleIgnoreRepeated(),
        },
      });
    }
  };

  const handleClientSearch = async (scrapping = true) => {
    try {
      if (openendModals.includes('createdContact'))
        closeModal('createdContact');

      const formValues = form.getState().values;
      const formErrors = form.getState().errors;

      const identificationType = get(formValues, 'identification.type', null);
      const MEXICO_FOREIGN_DEFAULT_RFC = 'XEXX010101000';
      const mexicoIdentification =
        get(formValues, 'rfc', null) === MEXICO_FOREIGN_DEFAULT_RFC
          ? get(formValues, 'fiscalId', null)
          : get(formValues, 'rfc', null);
      const identificationNumber =
        country === COUNTRIES.MEXICO
          ? mexicoIdentification
          : get(formValues, 'identification.number', null);

      if (formErrors?.identification?.number) return;
      if (!identificationNumber) return;

      setSearchingClient(true);
      form.change('ignoreRepeated', false);

      if (identificationNumber) {
        const result = await contactsDB.searchContactByIdentification(
          identificationNumber?.trim(),
          country
        );

        if (result?.identification) {
          openDuplicateContactModal({ client: result });
          form.change('ignoreRepeated', true);
          setSearchingClient(false);
          return;
        }
      }

      if (!scrapping) {
        setSearchingClient(false);
        return;
      }

      const canCostaRicaScrapping =
        country === COUNTRIES.COSTA_RICA && identificationType !== 'COF';

      const canScrapping =
        canCostaRicaScrapping ||
        ([
          COUNTRIES.COLOMBIA,
          COUNTRIES.PERU,
          COUNTRIES.ARGENTINA,
          COUNTRIES.REPUBLICA_DOMINICANA,
          COUNTRIES.MEXICO,
          COUNTRIES.PANAMA,
        ].includes(country) &&
          canScrappingByCountry(country, identificationType, online) &&
          identificationType);

      if (canScrapping) {
        const kindOfPerson = get(
          formValues,
          'identification.kindOfPerson',
          null
        );

        if (!identificationNumber) {
          setSearchingClient(false);
          return;
        }

        let transformedIdentificationType = {};
        if (country === COUNTRIES.PANAMA) {
          transformedIdentificationType = { kindOfPerson };
        } else if (country !== COUNTRIES.COSTA_RICA) {
          transformedIdentificationType = { idType: identificationType };
        }

        const response = await contactsBearerAPI.get('/search-by-id-number', {
          identification: identificationNumber?.trim(),
          ...transformedIdentificationType,
          version: country,
        });

        const data = get(response, 'data', null) ? get(response, 'data') : null;

        if (!!data) {
          switch (country) {
            case COUNTRIES.PERU:
              form.change('firstName', get(data, 'name', null));
              form.change(
                'address.combined',
                `${data?.address?.city}, ${data?.address?.province}, ${data?.address?.department}, ${data?.address?.ubigeo}`
              );
              form.change(
                'address.address',
                get(data, 'address.address', null)
              );
              break;
            case COUNTRIES.COLOMBIA:
              if (identificationType !== 'NIT' && get(data, 'socialReason')) {
                form.change('identification.type', 'NIT');
              }

              if (!!get(data, 'name', null)) {
                form.change('firstName', get(data, 'name', null));
              } else {
                if (!!get(data, 'firstName', null)) {
                  form.change('firstName', get(data, 'firstName', null));
                  form.change('kindOfPerson', kindOfPersonOptions[1].key);
                }
                if (!!get(data, 'socialReason', null)) {
                  form.change('firstName', get(data, 'socialReason', null));
                  form.change('kindOfPerson', kindOfPersonOptions[0].key);
                }
                if (!!get(data, 'firstLastname', null))
                  form.change(
                    'lastName',
                    `${get(data, 'firstLastname', null)}${
                      !!get(data, 'secondLastname', null)
                        ? ' ' + get(data, 'secondLastname', null)
                        : ''
                    }`
                  );
                form.change('secondName', get(data, 'secondName', null));
              }
              if (!!get(data, 'email', null))
                form.change('email', get(data, 'email', null));
              break;
            case COUNTRIES.COSTA_RICA:
              form.change('firstName', get(data, 'name', null));
              break;
            case COUNTRIES.REPUBLICA_DOMINICANA:
              form.change('firstName', get(data, 'name', null));
              break;
            case COUNTRIES.ARGENTINA:
              const _ivaCondition = get(
                argIvaConditions.filter(
                  (i) =>
                    upperCase(i.value) ===
                    upperCase(get(data, 'ivaCondition.Name'))
                ),
                '0',
                null
              );

              form.change('firstName', get(data, 'name', null));
              form.change(
                'address.address',
                get(data, 'address.0.Address', null)
              );
              form.change(
                'address.postalCode',
                get(data, 'address.0.PostalCode', null)
              );
              form.change('ivaCondition', _ivaCondition?.key);

              if (!get(data, 'name', null)) {
                toastHandler({
                  type: 'warning',
                  title: I18n.get(
                    'checkTheCUIT',
                    'Revisá el CUIT de tu cliente. 🔍'
                  ),
                  description: I18n.get(
                    'checkTheCUITSubtitle',
                    'Validá que esté bien escrito y si es correcto, te recomendamos completar los datos de forma manual.'
                  ),
                });
              }
              break;
            case COUNTRIES.PANAMA:
              const isSuccess = get(data, 'success', null);
              if (isSuccess) {
                form.change('isCorrectRuc', true);
                if (kindOfPerson === 'PERSON_ENTITY') {
                  const [name, lastName] = get(data, 'name', null).split(' ');
                  form.change('firstName', name);
                  form.change('lastName', lastName);
                } else {
                  form.change('firstName', get(data, 'name', null));
                }
                form.change('identification.dv', get(data, 'checkDigit', null));
              } else {
                form.change('isCorrectRuc', false);
                form.change('firstName', null);
                form.change('lastName', null);
                form.change('identification.dv', null);
              }
              break;
            default:
              break;
          }
        }
      }

      setSearchingClient(false);
    } catch (error) {
      setSearchingClient(false);
      toastHandler({
        type: 'info',
        title: I18n.get(
          'autocompletingNotAvailable',
          'Autocompletado no disponible'
        ),
        description: I18n.get(
          'autocompletingNotAvailableDescription',
          'No encontramos datos para autocompletar tu <br/> formulario. Por favor, verifica el número y tipo <br/> de identificación, o ingresa los datos tú mismo.'
        ),
      });
    } finally {
      setSearchingClient(false);
    }
  };

  return (
    <ContactFormContext.Provider
      value={{
        contact,
        searchingClient,
        handleClientSearch,
        duplicatedContact,
        setIsDuplicatedContact,
        openDuplicateContactModal,
      }}
    >
      <Wrapper fullWidth fullHeight>
        {children}
      </Wrapper>
    </ContactFormContext.Provider>
  );
}

function useContactForm() {
  return useContext(ContactFormContext);
}

export { ContactFormProvider, useContactForm };
