import { capitalize, get } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../common/Modal';
import { useHistory } from 'react-router-dom';
import { closeModal } from '../../../reducers/modals';
import { Typography } from '@alegradev/smile-ui-react';
import { Field, Form } from 'react-final-form';
import { I18n } from 'aws-amplify';
import { renderField, renderSelect } from '../../forms/fields/V0/Fields';
import { invoiceClients } from '../../../selectors/clients';
import Bottom from '../common/Bottom';
import PayInvoiceTable from './table';

const PayInvoice = () => {
    const clients = useSelector(invoiceClients)
    console.log('clients', clients);
    const isOpen = useSelector(state => get(state, 'modals.payInvoice.isOpen', false));
    const dispatch = useDispatch();
    const onCloseModal = async () => {
        dispatch(closeModal({ modal: 'payInvoice' }));
    };
    const title = (
        <Typography
            type='heading-4'
            text='Agregar pago'
        />
    )

    return (
        <Modal
            title={title}
            onRequestClose={() => (onCloseModal())}
            isOpen={isOpen}
            className='modal__invoice'
        >

            <Form
                onSubmit={() => { }}
            >
                {({ handleSubmit, form, values, submitError, pristine }) => (

                    <form noValidate onSubmit={handleSubmit}>
                        <div className='col-md-12 d-flex justify-content-md-center mt-5 p-3'>
                            <Typography
                                type='label-1'
                                text='Por pagar'
                                variant='tertiary'
                            />
                        </div>
                        <div className='col-md-12 d-flex justify-content-md-center'>
                            <Typography
                                text='$0.0'
                                type='heading-1'
                            />
                        </div>
                        <div className="form-body h-100 p-4 overflow-hidden">
                            <div className="col-sm-12 form-row d-flex justify-content-md-center  align-items-center">
                                <Field
                                    name="client"
                                    className='col-md-6'
                                    type='text'
                                    options={clients}
                                    component={(fieldProps) =>
                                        renderSelect({
                                            ...fieldProps,
                                            height: '40px',
                                            fontSize: '16px',
                                        })
                                    }
                                    label={capitalize(I18n.get('client', 'Cliente'))}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    required
                                />
                                <Field
                                    name="name"
                                    className='col-md-6'
                                    type='text'
                                    component={(fieldProps) =>
                                        renderSelect({
                                            ...fieldProps,
                                            height: '40px',
                                            fontSize: '16px',
                                        })
                                    }
                                    label={capitalize(I18n.get('paymentDate', 'Fecha de pago'))}
                                    required
                                />
                            </div>
                            <div className='d-flex flex-column mt-3'>
                                <div className='border-top-rounded px-4 py-3 border border-slate-200'>
                                    <Typography type='body-3-regular' text='2 facturas por cobrar' />
                                </div>
                                <PayInvoiceTable />
                            </div>
                            <div>
                                <Bottom
                                    onClose={() => onCloseModal()}
                                    submitText={"Continuar"}
                                    hideRequired={true}
                                />

                            </div>
                        </div>

                    </form>
                )}

            </Form>
        </Modal>
    )
}

export default PayInvoice;