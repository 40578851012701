import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import { get } from 'lodash';
import { I18n } from 'aws-amplify';
import BigNumber from "bignumber.js"

import { isSelectingItems, items } from '../../../../selectors/activeRefund';
import { updateItem, setIsSelectingItems, populateItem, clearDiscardedItems, updateIsAllItemsPopulated, updateItemStatusById } from '../../../../reducers/activeRefund';

import alegraAPI from '../../../../reducers/alegraAPI';
import Item from './Item';
import Summary from './Summary';
import Search from '../../../modals/refunds/SearchItems/Search';
import SearchableItems from './SearchableItems';
import Button from '../../../common/Button';
import { useFormat } from '../../../../hooks/useFormat';

import { Icon } from '@alegradev/smile-ui-react';
import { getItem } from '../../../../database/itemsDB';

const chekIfItemIsLoaded = async (id) => {
  const item = await getItem(Number(id));
  if (!item) return id;
}

const getItemsNotLoaded = async (items) => {
  const itemsPromises = items.map(item => chekIfItemIsLoaded(Number(item.id)));
  const itemsNotLoaded = await Promise.all(itemsPromises);
  //eliminamos los items que si se cargaron que en el array quedan como undefined
  return itemsNotLoaded.filter(item => item);
}

const transformItemFromInvoice = (item) => {
  return {
    id: item.id,
    name: item.name,
    description: item.description,
    price: [{
      price: item.price,
    }],
    quantity: item.quantity,
    discount: item.discount,
    tax: item.tax
  }
}

const getItemFromAlegra = async ({ id }) => {
  const response = await alegraAPI.get(`/items/${id}`);

  const data = get(response, 'data', null)
  return data;
}

const ItemsForm = ({ changeStep, openEditItem, openEditTip }) => {
  const [loadingItems, setLoadingItems] = useState(false);
  const isSelectingMode = useSelector(isSelectingItems);
  const invoiceSelected = useSelector(state => get(state, 'modals.newRefunds.params.invoice', null));
  const itemsInRefund = useSelector(items);
  const { values } = useFormState();
  const { decimal, fmt } = useFormat();
  const dispatch = useDispatch();
  const devolutionType = get(values, 'refund.method.value');
  const outstandingInvoiceAmount = new BigNumber(get(values, 'refund.document.balance')).toFormat(decimal, fmt);

  useEffect(() => {
    if (!isSelectingMode) {
      dispatch(clearDiscardedItems());
    }
  }, [isSelectingMode])

  useEffect(() => {
    if (itemsInRefund.length) return
    const populateItemsFromInvoice = async () => {
      const devolutionType = get(values, 'refund.method.value')
      const populatedInvoice = !!invoiceSelected
        ? invoiceSelected
        : devolutionType === 'creditToSales' ? get(values, 'refund.document', null) : null

      if (!!populatedInvoice && !!get(populatedInvoice, 'items.length', 0)) {
        dispatch(setIsSelectingItems(false));
        setLoadingItems(true);
        // items que esten sin cargar
        const itemsToFetch = await getItemsNotLoaded(populatedInvoice.items);

        populatedInvoice.items.forEach((item, index) => {
          const itemFound = transformItemFromInvoice(item)
          dispatch(populateItem(itemFound));
          if (item.quantity > 1) {
            dispatch(updateItem({
              index,
              values: {
                quantity: item.quantity
              }
            }));
          }
        })

        if (get(itemsToFetch, "length", false)) {
          const itemsPromises = itemsToFetch.map(itemId => getItemFromAlegra({ id: itemId }));
          const results = await Promise.allSettled(itemsPromises);

          results.forEach(result => {
            if (result.status === 'fulfilled') {
              const item = get(result, "value", null);
              if (get(item, "id", false) && get(item, "status", "") === 'inactive')
                dispatch(updateItemStatusById({ id: String(item.id), status: 'inactive' }));

            } else {
              console.log('Error fetching item from Alegra');
            }
          })
        }
        setLoadingItems(false);
        dispatch(updateIsAllItemsPopulated(true));
      }
    }
    populateItemsFromInvoice();
  }, [])

  return (
    <div className="new-refund-items-form">
      <p className="items-form_subtitle">
        {devolutionType === 'creditToSales'
          ? I18n.get('itemsToAddInDevolution.creditToSales', 'Productos que se incluirán en la devolución de tu cliente')
          : I18n.get('itemsToAddInDevolution', 'Elige los productos del documento asociado que devuelve el cliente')
        }
      </p>
      <div className="items-form_items-container">
        <div className="d-flex flex-column justify-content-between items-container-header">
          <p className="items-container-header_title mb-0">{get(values, 'refund.client.name')}</p>
          {
            devolutionType === 'creditToSales' && !!outstandingInvoiceAmount &&
            <div className='d-flex flex-column mt-4'>
              <p className="items-form_items-caption p-0 mb-md-1">
                {I18n.get('associatedDocument', 'Documento asociado')}:{" "}
                {get(values, 'refund.document.numberTemplate.fullNumber')}
              </p>
              <p className="items-form_items-caption p-0">
                {I18n.get('outstandingInvoiceAmount', 'Pendiente por cobrar')}:{" "}{outstandingInvoiceAmount}
              </p>
            </div>
          }
        </div>
        {isSelectingMode && (
          <>
            <Search newRefund />
            <SearchableItems />
          </>
        )}
        {!isSelectingMode && (
          <>
            <p className="items-form_items-caption font-weight-bold">
              {I18n.get('productsToRefund', 'Productos a devolver')}
            </p>
            <div className="d-flex flex-column justify-content-between h-100" style={{ position: "relative", height: "100%" }}>
              <section className="items-list">
                {!loadingItems && itemsInRefund.map((item, index) => {
                  return (
                    <Item
                      key={index}
                      index={index}
                      item={item}
                      openEditItem={openEditItem}
                    />
                  )
                })}

                {loadingItems && (
                  <div className="d-flex w-100 justify-content-center">
                    <Icon icon='loader-2' animated extraClass=' icon-primary icon x4' />
                  </div>
                )}

                {!loadingItems &&
                  <button className="btn-md-default-filled" onClick={() => dispatch(setIsSelectingItems(true))}>
                    <Icon icon='plus' extraClass="icon-white" width={16} height={16} />
                    {I18n.get('addItems', 'Agregar productos')}
                  </button>
                }
              </section>
              <section className="items-summary">
                <Summary openEditTip={openEditTip} />
              </section>
            </div>
          </>
        )}
      </div>
      {isSelectingMode && (
        <div className='d-flex flex-end justify-content-between mt-3 bg-white'>
          <button
            type='button'
            className='refunds-cancel-button'
            style={{ border: 'none', fontWeight: '500' }}
            onClick={!itemsInRefund.length ? () => changeStep(2) : () => dispatch(setIsSelectingItems(false))}>
            <Icon icon='arrow-bar-left' extraClass="icon-black mr-2" />
            {I18n.get('goBack', 'Volver')}
          </button>
          <Button
            className='ml-4'
            disabled={!itemsInRefund.length}
            type='button'
            onClick={() => { dispatch(setIsSelectingItems(false)) }}>
            {I18n.get('addItems', 'Agregar productos')}
          </Button>
        </div>
      )}
    </div >
  )
}

export default ItemsForm