import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { I18n } from 'aws-amplify';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { graphqlOperation } from 'aws-amplify';

import { updateUser } from '../../../graphql/mutations';
import { setUser } from '../../../reducers/auth';
import { closeModal } from '../../../reducers/modals';

import Modal from '../../common/Modal';
import Bottom from './Bottom';
import { APIGraphqlSelector } from '../../../selectors/app';
import Typography from '../../common/Typography';
import CustomDataReceiptImage from '../../../assets/images/new-custom-data-receipt.png';
import { notificationsTransformer } from '../../notifications/utils';


const CustomDataReceipt = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) =>
    get(state, 'modals.customDataReceipt.isOpen', false)
  );
  const APIGraphql = useSelector(APIGraphqlSelector);

  const addSeenCustomDataReceipt = async () => {
    try {
      const response = await APIGraphql(graphqlOperation(updateUser, notificationsTransformer('custom-data-receipt')));

      if (!!get(response, 'data.updateUser.id', null)) {
        dispatch(setUser(get(response, 'data.updateUser')));
      } else {
        throw new Error('error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = async () => {
    await addSeenCustomDataReceipt();
    dispatch(closeModal({ modal: 'customDataReceipt' }));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        handleClose();
      }}
      className='modal__activate-wompi'
      headerImg={CustomDataReceiptImage}
      hideClose
      bordelessHeader
    >
      <Typography
        type='body-2-bold'
        variant='primary'
        text={I18n.get(
          '',
          'Personaliza la tirilla de tu terminal 🎨'
        )}
        className='w-100 text-center'
      />
      <Typography
        type='body-3-regular'
        variant='primary'
        text={I18n.get(
          '',
          'Ya puedes configurar la ubicación y datos de contacto de tus terminales para diferenciar las facturas de cada punto de venta.'
        )}
        className='w-100 text-center'
      />
      <Bottom
        onConfirm={() => {
          handleClose();
        }}
      />
    </Modal>
  );
};

export default CustomDataReceipt;