import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';

import { Icon, Tooltip, Typography } from '@alegradev/smile-ui-react';

const Bottom = ({
  onClose,
  closeText,
  submitText,
  disabled,
  submitting,
  onConfirm,
  hideCancel,
  submitId,
  hideRequired = false,
  onResetForm,
  resetForm,
  saveText,
  disableCancel,
  backButton,
  onBack,
  isInvoice = false,
  borderlessHeader = false,
  buttonsCenter = false,
  requiredFieldsText,
  tooltipVisible = false,
  tooltipText = '',
}) => (
  <div
    className={`modal__bottom w-100 px-5 py-4 d-flex flex-column flex-sm-row justify-content-between 
    align-items-sm-center align-items-start ${
      !borderlessHeader ? 'border-top' : ''
    }`}
  >
    {!backButton ? (
      hideRequired ? (
        <div className='required-message mb-3 mb-sm-0'></div>
      ) : (
        <div className='required-message mb-3 mb-sm-0'>
          {!requiredFieldsText && <i className='text-primary mr-2'>* </i>}
          <span className='text-capitalize-first text-muted'>
            {requiredFieldsText
              ? requiredFieldsText
              : I18n.get('requiredFields', 'Campos obligatorios')}
          </span>
        </div>
      )
    ) : (
      <button className='btn back-btn' type='button' onClick={onBack}>
        <Typography text={I18n.get('back', 'atrás')} type='label-1' />
      </button>
    )}

    <div
      className={`modal__bottom-buttons d-flex ${buttonsCenter && 'justify-content-center w-100'}`}
    >
      {!hideCancel && (
        <button
          type='button'
          className={`btn ${
            isInvoice ? 'btn-cancel-invoice' : 'btn-cancel'
          } text-capitalize-first`}
          onClick={() => onClose()}
          disabled={disableCancel}
        >
          {closeText || I18n.get('cancel', 'cancelar')}
        </button>
      )}

      {!!onResetForm && (
        <button
          id='test'
          type='submit'
          className={`btn ${
            isInvoice ? 'btn-cancel-invoice' : 'btn-cancel'
          } text-capitalize-first`}
          disabled={!!submitting || !!disabled}
          onClick={() => {
            onResetForm(true);
          }}
        >
          {submitting && resetForm ? (
            <Icon icon='loader-2' animated extraClass=' icon-white' />
          ) : (
            saveText || I18n.get('saveAndCreateNew', 'Guardar y crear nueva')
          )}
        </button>
      )}
      <Tooltip visible={tooltipVisible} overlay={tooltipText}>
        <button
          id={submitId}
          type={!!onConfirm ? 'button' : 'submit'}
          data-testid={`modal-${!!onConfirm ? 'confirm' : 'submit'}-btn`}
          className={`btn ${
            isInvoice
              ? 'btn-submit-invoice'
              : 'btn-submit d-flex align-items-center justify-content-center'
          } text-capitalize-first`}
          disabled={!!submitting || !!disabled}
          onClick={!!onConfirm ? () => onConfirm() : null}
        >
          {submitting && !resetForm ? (
            <Icon icon='loader-2' animated extraClass=' icon-white' />
          ) : (
            submitText || I18n.get('save', 'guardar')
          )}
        </button>
      </Tooltip>
    </div>
  </div>
);

Bottom.propTypes = {
  closeText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
  onConfirm: PropTypes.func,
  hideCancel: PropTypes.bool,
  hideRequired: PropTypes.bool,
  submitId: PropTypes.string,
  backButton: PropTypes.bool,
  onBack: PropTypes.func,
  isInvoice: PropTypes.bool,
  borderlessHeader: PropTypes.bool,
  requiredFieldsText: PropTypes.string,
  buttonsCenter: PropTypes.bool,
  tooltipText: PropTypes.string,
  tooltipVisible: PropTypes.bool
};

export default Bottom;
