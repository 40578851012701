import React from 'react';
import { useSelector } from 'react-redux';

import { country as countrySelector } from '../../../selectors/company';
import renderAddress from './Address';
import renderInfo from './Info';

const CustomDataReceipt = (props) => {
  const country = useSelector(countrySelector);

  if (country !== 'colombia') {
    return null;
  }

  return (
    <div className="form-body__fields form-row justify-content-start">
      {renderInfo(props)}
      {renderAddress({ ...props, country })}
    </div>
  );
};

export default CustomDataReceipt;