import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

import { numerationSelector } from '../reducers/numerations'
import { country, isOnlyInvoicingPlan, ivaConditionSelector, companySelector, electronicInvoicing } from './company'
import { I18n } from 'aws-amplify';
import { COUNTRIES } from '../utils/enums/countries';
import { checkNumerationBlock } from '../components/home/NewActiveInvoice/utils';
import { isMexicoFeAvailable } from '../utils/deploys/mexicoFeDeployGroups';

export const allNumerations = createDraftSafeSelector(
  numerationSelector.selectAll,
  numerations => !!numerations ? numerations : []
)

export const activeNumerations = createDraftSafeSelector(
  numerationSelector.selectAll,
  numerations => !!numerations
    ? numerations.filter(numeration => (get(numeration, 'status') === 'active' && !!get(numeration, 'autoincrement')))
    : []
)

export const getNumerationById = id => createDraftSafeSelector(
  state => numerationSelector.selectById(state, id),
  numeration => !!numeration && get(numeration, 'status') === 'active' ? numeration : null
)

export const getElectronicNumerations = createDraftSafeSelector(
  [activeNumerations],
  (numerations) => {
    return numerations.filter(numeration => (get(numeration, 'isElectronic') || false) === true)
  }
)

export const getOnlyInvoiceNumerations = createDraftSafeSelector(
  [activeNumerations],
  (numerations) => {
    return numerations.filter(numeration => get(numeration, 'documentType') === 'invoice')
  }
)

export const getAllInvoiceNumerations = createDraftSafeSelector(
  [activeNumerations],
  (numerations) => {
    return numerations.filter(numeration => get(numeration, 'documentType') === 'invoice')
  }
)

export const getInvoiceTicketNumerations = createDraftSafeSelector(
  [activeNumerations, country, ivaConditionSelector],
  (numerations, country, ivaCondition) => {
    let finalNumerations = numerations.filter(numeration =>
      (get(numeration, 'documentType') === 'invoice' || get(numeration, 'documentType') === 'saleTicket')
    )

    if (country === 'argentina') {
      if (ivaCondition === 'IVA_RESPONSABLE')
        finalNumerations = finalNumerations.filter(numeration => get(numeration, 'subDocumentType') !== 'INVOICE_C')

      if (ivaCondition === 'UNIQUE_TRIBUTE_RESPONSABLE' || ivaCondition === 'IVA_EXEMPT')
        finalNumerations = finalNumerations.filter(numeration => get(numeration, 'subDocumentType') !== 'INVOICE_A' || get(numeration, 'subDocumentType') !== 'INVOICE_B')
    }

    if (country === 'republicaDominicana') {
      finalNumerations = finalNumerations.filter(numeration => {
        return (get(numeration, 'isElectronic') || false) === false
          || ["E31", "E32"].includes(get(numeration, 'prefix', ''))
      })
    }

    return finalNumerations
  }
)

export const getTicketNumerations = createDraftSafeSelector(
  [activeNumerations, country, ivaConditionSelector],
  (numerations, country, ivaCondition) => {
    let finalNumerations = numerations.filter(numeration => get(numeration, 'documentType') === 'saleTicket')

    if (country === 'argentina') {
      if (ivaCondition === 'IVA_RESPONSABLE')
        finalNumerations = finalNumerations.filter(numeration => get(numeration, 'subDocumentType') !== 'INVOICE_C')

      if (ivaCondition === 'UNIQUE_TRIBUTE_RESPONSABLE' || ivaCondition === 'IVA_EXEMPT')
        finalNumerations = finalNumerations.filter(numeration => get(numeration, 'subDocumentType') !== 'INVOICE_A' || get(numeration, 'subDocumentType') !== 'INVOICE_B')
    }

    return finalNumerations
  }
)

export const getCashReceiptNumerations = createDraftSafeSelector(
  activeNumerations,
  numerations => numerations.filter(numeration => get(numeration, 'documentType') === 'transactionIn')
)

export const getRefundNumerations = createDraftSafeSelector(
  activeNumerations,
  numerations => numerations.filter(numeration =>
    (get(numeration, 'documentType') === 'creditNote' || get(numeration, 'documentType') === 'creditNoteSaleTicket')
  )
)

export const getRefundNumerationsNoElectronic = createDraftSafeSelector(
  activeNumerations,
  numerations => numerations.filter(numeration =>
    (get(numeration, 'documentType') === 'creditNote' && !get(numeration, 'isElectronic'))
  )
)

export const getRefundNumerationsAdjustNote = createDraftSafeSelector(
  activeNumerations,
  numerations => numerations.filter(numeration =>
    (get(numeration, 'documentType') === 'incomeAdjustmentNote')
  )
)

export const getRefundNumerationsElectronic = createDraftSafeSelector(
  activeNumerations,
  numerations => numerations.filter(numeration =>
    (get(numeration, 'documentType') === 'creditNote' && !!get(numeration, 'isElectronic'))
  )
)

export const getRefundNumerationsSaleTicket = createDraftSafeSelector(
  activeNumerations,
  numerations => numerations.filter(numeration =>
    (get(numeration, 'documentType') === 'creditNoteSaleTicket') && !!get(numeration, 'isElectronic')
  )
)

export const getAdjustmentNoteNumerations = createDraftSafeSelector(
  activeNumerations,
  numerations => numerations.filter(numeration => get(numeration, 'documentType') === 'adjustmentNote')
)

export const getInventoryAdjustmentNumerations = createDraftSafeSelector(
  activeNumerations,
  numerations => numerations.filter(numeration =>
    (get(numeration, 'documentType') === 'inventoryAdjustment')
  )
)

export const getSaleTicketNumerations = createDraftSafeSelector(
  activeNumerations,
  numerations => numerations.filter(numeration => get(numeration, 'documentType') === 'saleTicket')
)

export const getInvoiceNumerations = createDraftSafeSelector(
  [country, getInvoiceTicketNumerations, getTicketNumerations, companySelector, electronicInvoicing],
  (country, invoiceTicketNumerations, ticketNumerations, company, electronicInvoicing) => {
    const isFeMexActive = isMexicoFeAvailable(country, electronicInvoicing);
    if (country === COUNTRIES.MEXICO && !isFeMexActive) {
      return ticketNumerations;
    }

    if (country === COUNTRIES.PERU && company?.regime === 'Nuevo RUS') {
      return invoiceTicketNumerations.filter(
        (item) => item?.documentType === 'saleTicket'
      );
    }

    if (country === COUNTRIES.SPAIN && company?.address?.province === 'Bizkaia' && electronicInvoicing) {
      return invoiceTicketNumerations.filter(
        (item) => item.isElectronic === true
      );
    };

    return invoiceTicketNumerations;
  }
);

export const isCashReceiptNumerationActive = createDraftSafeSelector(
  [country, isOnlyInvoicingPlan],
  (country, isOnlyInvoicingPlan) => {
    const multipleNumbering =
      country === 'colombia' ||
      country === 'costaRica' ||
      country === 'peru' ||
      country === 'republicaDominicana' ||
      country === 'chile' ||
      country === 'argentina';

    return multipleNumbering && !isOnlyInvoicingPlan;
  }
)

export const isRefundNumerationActive = createDraftSafeSelector(
  [country, isOnlyInvoicingPlan],
  (country, isOnlyInvoicingPlan) => {
    const multipleNumbering =
      country === 'colombia' ||
      country === 'costaRica' ||
      country === 'peru' ||
      country === 'republicaDominicana' ||
      country === 'chile' ||
      country === 'argentina';

    return multipleNumbering && !isOnlyInvoicingPlan;
  }
)


export const groupedNumerations = createDraftSafeSelector(
  [getInvoiceNumerations, country, companySelector],
  (numerations, country, company) => {
    const checkedNumerations = numerations.map(numeration => {
      const disabledReason = checkNumerationBlock(numeration, country, company?.registryDate);
      return {
        ...numeration,
        isDisabled: !!disabledReason,
        disabledReason: get(disabledReason, 'name', null)
      }
    })

    if (country === COUNTRIES.MEXICO) {
      return [
        {
          label: I18n.get('tickets', 'Tickets'),
          options: checkedNumerations.filter(numeration => numeration?.documentType === 'saleTicket')
        },
        {
          label: I18n.get('invoices', 'Facturas'),
          options: checkedNumerations.filter(numeration => numeration?.documentType === 'invoice')
        }
      ]
    }

    return [
      {
        label: I18n.get('noElectronicNumerations', 'No electrónicas'),
        options: checkedNumerations.filter(numeration => !get(numeration, 'isElectronic'))
      },
      {
        label: I18n.get('electronicNumerations', 'Electrónicas'),
        options: checkedNumerations.filter(numeration => get(numeration, 'isElectronic'))
      }
    ]
  }
)

export const isElectronicPOSDocumentAvailableSelector = createDraftSafeSelector(
  [country, getElectronicNumerations],
  (country, numerations) => {
    if (country !== 'colombia') return false;

    const existsElectronicPOSDocument = numerations.find(n => n.documentType === 'saleTicket');

    return !!existsElectronicPOSDocument;
  }
);