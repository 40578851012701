import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';

import { renderField } from '../fields/V0/Fields';

const renderInfo = () => (
  <>
    <Field
      name="tradeName"
      className="col-md-6"
      component={renderField}
      type="text"
      label={I18n.get('tradeName', 'Nombre comercial')}
    />
    <Field
      name="email"
      className="col-md-6"
      component={renderField}
      type="text"
      label={I18n.get('email', 'Correo')}
    />
    <Field
      name="phone"
      className="col-md-6"
      component={renderField}
      type="text"
      label={I18n.get('phone', 'Teléfono')}
    />

  </>
)

export default renderInfo;