import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { country as countrySelector, membershipSelector, notificationsSelector } from "../../selectors/company";
import { stations as stationsSelector } from "../../selectors/station";
import { openModal } from "../../reducers/modals";

export const useCustomDataReceiptAvailability = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const stations = useSelector(stationsSelector);
  const membership = useSelector(membershipSelector);
  const notifications = useSelector(notificationsSelector);
  const country = useSelector(countrySelector);

  const showCustomDataReceiptAvailable = useMemo(() => {
    if (country !== 'colombia') return false;
    if (notifications.some(n => n['custom-data-receipt'])) return false;
    if (stations.length <= 1) return false;
    if (membership?.readOnly ?? true) return false;
    if (membership?.plan?.keyword === 'demo') return false;
    return true;
  }, [country, notifications, stations.length, membership]);

  useEffect(() => {
    const shouldOpenModal = showCustomDataReceiptAvailable && pathname.includes('/settings/station');
    if (shouldOpenModal) {
      dispatch(openModal({ modal: 'customDataReceipt' }));
    }
  }, [showCustomDataReceiptAvailable, pathname, dispatch]);

  return { showCustomDataReceiptAvailable };
};