import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash'
import { BigNumber } from 'bignumber.js'

import { closeModal } from '../../../reducers/modals';
import { decimalPrecision } from '../../../selectors/company'
import { getMainCurrency } from '../../../selectors/currencies'
import Modal from '../../common/Modal';

import { Icon, Typography } from '@alegradev/smile-ui-react';
import Bottom from './Bottom';

const InvoiceSavedWithProvisionalTicket = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => get(state, 'modals.invoiceSavedWithProvisionalTicket.isOpen', false));
  const invoiceCreated = useSelector(state => get(state, 'print.invoice', null));
  const decimal = useSelector(decimalPrecision)
  const mainCurrency = useSelector(getMainCurrency)

  const prefix = !!get(invoiceCreated, 'currency.symbol') ? get(invoiceCreated, 'currency.symbol')
    : !!get(mainCurrency, 'symbol') ? get(mainCurrency, 'symbol') : ''

  const fmt = {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  }

  const handleClose = () => {
    dispatch(closeModal({ modal: 'invoiceSavedWithProvisionalTicket' }))
  }

  return (
    <Modal
      isOpen={isOpen}
      className="modal__invoice-provisional-ticket"
      includeHeader={false}
    >
      <div className="d-flex flex-column p-3 justify-content-center align-items-center">
        <div className="modal__invoice-provisional-ticket__icon p-4 d-flex justify-content-center align-items-center">
          <Icon icon='file-report' color='#64748B' size='extraLarge' />
        </div>

        <div className='modal__invoice-provisional-ticket__total'>
          <Typography variant='h2' color='primary' type='heading-4'
            text={`${I18n.get('total', 'Total')} ${new BigNumber(get(invoiceCreated, "total", 0)).toFormat(decimal, fmt)}`}
          />
        </div>

        <div className='modal__invoice-provisional-ticket__message'>
          <Icon icon='info-circle' color='#F59E0B' size='medium' />
          <Typography variant='body1' type='body-3-bold'
            text={I18n.get('invoiceSavedWithProvisionalTicket', 'Se sincronizará cuando tengas conexión')}
          />
        </div>

        <div>
          <Typography variant='body1' type='body-3-regular' style={{ textAlign: 'center' }}
            text={I18n.get('invoiceSavedWithProvisionalTicketMessage', 'Tu cliente recibirá la factura electrónica por correo o podrá descargarla escaneando el QR de su ticket provisional.')}
          />
        </div>

        <Bottom handleClose={handleClose} />

      </div>
    </Modal>
  )
}

export default InvoiceSavedWithProvisionalTicket;