import { useSelector } from "react-redux"
import { address as addressSelector, country as countrySelector, electronicInvoicing } from "../../selectors/company"
import { COUNTRIES } from "../../utils/enums/countries";

const useTicketBaiInformation = () => {
  let isTicketBaiElegible = false
  let isTicketBaiEnabled = false;

  const country = useSelector(countrySelector);
  const address = useSelector(addressSelector);
  const isElectronic = useSelector(electronicInvoicing);

  if (country !== COUNTRIES.SPAIN) return { isTicketBaiElegible, isTicketBaiEnabled };
  if (!address) return { isTicketBaiElegible, isTicketBaiEnabled };
  if (!address?.province === 'Bizkaia') return { isTicketBaiElegible, isTicketBaiEnabled };

  isTicketBaiElegible = true;

  return { isTicketBaiElegible, isTicketBaiEnabled: isTicketBaiElegible && isElectronic };
};

export default useTicketBaiInformation;