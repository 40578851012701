import React from 'react';
import { I18n } from '@aws-amplify/core';
import { BigNumber } from 'bignumber.js';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { decimalPrecision } from '../../../selectors/company';
import { currency } from '../../../selectors/activeInvoice';
import { getMainCurrency } from '../../../selectors/currencies';
import Typography from '../../common/Typography';
import useInvoiceChange from './hooks/useInvoiceChange';

const NewChange = ({ values, total }) => {
  const decimal = useSelector(decimalPrecision);
  const mainCurrency = useSelector(getMainCurrency);
  const selectedCurrency = useSelector(currency);

  const prefix = !!get(selectedCurrency, 'symbol')
    ? get(selectedCurrency, 'symbol')
    : !!get(mainCurrency, 'symbol')
      ? get(mainCurrency, 'symbol')
      : '';

  const fmt = {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  };

  if (!values) return null;

  const { payment, change } = useInvoiceChange(values, total);

  const paymentMethod = get(values, 'method', 'cash');

  return (
    <div className='d-flex justify-content-between align-items-center gap-3 mt-3 mb-4'>
      {paymentMethod === 'combined' ? (
        <div className='d-flex flex-column'>
          <div className='d-flex justify-content-end align-items-center gap-3'>
            <div className='new-text-tertiary'>
              <Typography
                text={I18n.get('totalReceived', 'total recibido')}
                type='label-2'
              />
            </div>
            <div className='text-change'>
              <Typography
                text={payment.abs().toFormat(decimal, fmt)}
                type='body-3-regular'
              />
            </div>
          </div>
          <div className='d-flex justify-content-end align-items-center gap-3 mt-3'>
            <Typography
              text={
                change.lt(0)
                  ? I18n.get('change', 'cambio') + ':'
                  : I18n.get('receivable', 'por cobrar') + ':'
              }
              type='body-2-bold'
            />
            <div className='text-change'>
              <Typography
                text={change.abs().toFormat(decimal, fmt)}
                type='body-3-bold'
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <Typography
            text={
              change.lt(0)
                ? I18n.get('change', 'cambio') + ':'
                : I18n.get('receivable', 'por cobrar') + ':'
            }
            type='body-2-bold'
          />
          <div className='text-change'>
            <Typography
              text={change.abs().toFormat(decimal, fmt)}
              type='body-3-bold'
            />
          </div>
        </>
      )}
    </div>
  );
};

export default NewChange;
