import React, { useEffect, useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize, replace, get, forEach } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { hasPermissionTo } from '../../../../selectors/auth';
import { activeBanks } from '../../../../selectors/banks';
import { renderField, renderSelect } from '../../fields/V0/Fields';
import Typography from '../../../common/Typography';
import UseField from './UseField';
import { activeSellers } from '../../../../selectors/sellers';
import { openModal } from '../../../../reducers/modals';
import { checkFeatureLimit } from '../../../../reducers/membership';
import { useDeviceType } from '@alegradev/smile-ui-react';
import { COUNTRIES } from '../../../../utils/enums/countries';
import { isMexicoFeAvailable } from '../../../../utils/deploys/mexicoFeDeployGroups';
import useElectronicPayrollStatus from '../../../../hooks/useElectronicPayrollStatus/hook';

import { allRegimes } from '../../../countriesData/mexico/regimes';
import {
  MEXICO_DEFAULT_OTHER_RFC,
  MEXICO_DEFAULT_RFC,
} from '../../../countriesData/mexico/rfc';
import configAPI from '../../../../reducers/configAPI';
import { client as clientSelector } from '../../../../selectors/activeInvoice';
import { DEFAULT_CFDI_USE } from '../../../countriesData/mexico/cfdiUses';
import {
  companySelector,
  country as countrySelector,
  decimalPrecision,
} from '../../../../selectors/company';

const NewCard = ({ form, values, total, anotationMaxLimit }) => {
  const sellers = useSelector(activeSellers);
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const banks = useSelector(activeBanks);
  const deviceType = useDeviceType();

  const country = useSelector(countrySelector);
  const company = useSelector(companySelector);
  const { isActive } = useElectronicPayrollStatus();
  const documentType = get(values, 'numeration.documentType', '');
  const client = useSelector(clientSelector);
  const [uses, setUses] = useState([]);

  if (!form || !values) return null;
  const { method } = values;

  useEffect(() => {
    const loadCfdiUses = async () => {
      if (country !== COUNTRIES.MEXICO) return;
      const response = await configAPI.get('/cfdi-uses', {
        application_version: country,
      });
      setUses(get(response, 'data', []));
    };
    loadCfdiUses();
  }, [client]);

  const isFeMexActive = useMemo(() => {
    return isMexicoFeAvailable(country, isActive);
  }, [country, company, isActive]);

  const isUseDisabled = useMemo(() => {
    if (!client) return true;
    if (
      (client && client?.identification === MEXICO_DEFAULT_RFC) ||
      client?.identification === MEXICO_DEFAULT_OTHER_RFC
    )
      return true;
    return false;
  }, [client]);

  const useOptions = useMemo(() => {
    const options = [];
    if (country === COUNTRIES.MEXICO) {
      if (client) {
        forEach(client?.regimeObject, (value) => {
          if (allRegimes[value]) {
            forEach(uses, (use) => {
              const splitRegimes = use?.regimes?.split('|');
              if (splitRegimes.includes(`${allRegimes[value].code}`)) {
                options.push({
                  key: use.key,
                  value: use.value,
                });
              }
            });
          }
        });
      } else {
        options.push(DEFAULT_CFDI_USE);
      }
    }

    return options;
  }, [uses, client]);

  return (
    <div className='form-row col-sm-12 pl-2 pr-0'>
      <div className='col-sm-12 col-md-6'>
        <Field
          name={values.method}
          className='col-md-12 p-0'
          defaultValue={total}
          type='number'
          label={
            <Typography
              type='label-2'
              text={capitalize(
                replace(
                  I18n.get('valueOfCardPayment', 'valor del pago en tarjeta'),
                  '{}',
                  method === 'credit'
                    ? I18n.get('creditCard', 'tarjeta de crédito')
                    : I18n.get('debitCardSaleModal', 'tarjeta débito')
                )
              )}
              variant='secondary'
            />
          }
          required
        >
          {(props) => (
            <>
              {renderField({
                ...props,
                fontSize: '16px',
                height: '48px',
                fontColor: '#334155',
                paddingLeft: '10px',
              })}
            </>
          )}
        </Field>

        <Field
          name={`${values.method}Bank`}
          className='col-md-12 p-0'
          component={(fieldProps) =>
            renderSelect({
              ...fieldProps,
              fontSize: '16px',
              borderRadius: '12px',
              height: '48px',
              borderColor: '#CBD5E1',
              fontColor: '#334155',
            })
          }
          label={
            <Typography
              type='label-2'
              text={capitalize(I18n.get('bank', 'banco'))}
              variant='secondary'
            />
          }
          options={
            !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
              ? []
              : banks
          }
          selectInfo={
            !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
              ? I18n.get(
                  'userNotAllowed.bank-accounts.view',
                  'no tienes permisos para ver la información de las cuentas de bancos'
                )
              : null
          }
          hideNoOptionMessage={
            !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
          }
          required
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          menuPosition='absolute'
        />
      </div>
      <div
        className={`col-sm-12 col-md-6  ${deviceType === 'mobile' ? 'mobile' : 'pr-0 form__invoice-advanced-data'}`}
      >
        {country === COUNTRIES.MEXICO &&
          isFeMexActive &&
          documentType === 'invoice' && (
            <Field
              name='use'
              className={`col-sm-12 ${deviceType === 'mobile' ? 'p-0' : 'pl-2 pr-0'}`}
              options={useOptions}
              disabled={isUseDisabled}
              required
              label={
                <Typography
                  text={capitalize(I18n.get('use', 'Uso'))}
                  type='label-2'
                  variant='secondary'
                />
              }
              defaultValue={useOptions.length > 1 ? null : useOptions[0]}
              getOptionLabel={(option) => option.value}
              getOptionValue={(option) => option.key}
            >
              {(props) => (
                <>
                  {renderSelect({
                    ...props,
                    fontSize: '16px',
                    borderRadius: '12px',
                    height: '48px',
                    fontColor: '#334155',
                  })}
                </>
              )}
            </Field>
          )}
        <div className='d-flex justify-content-center'>
          <Field
            name='seller'
            className={`col-sm-12 ${deviceType === 'mobile' ? 'p-0' : 'pl-2 pr-0'}`}
            options={sellers}
            label={
              <Typography
                text={capitalize(I18n.get('seller', 'vendedor'))}
                type='label-2'
                variant='secondary'
              />
            }
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            addOptionText={
              can('add', 'sellers')
                ? capitalize(I18n.get('newSeller', 'Nuevo vendedor'))
                : null
            }
            addOptionAction={() =>
              dispatch(
                checkFeatureLimit(
                  'sellers',
                  can('add', 'sellers')
                    ? () => dispatch(openModal({ modal: 'seller' }))
                    : null
                )
              )
            }
            menuPosition='absolute'
          >
            {(props) => (
              <>
                {renderSelect({
                  ...props,
                  fontSize: '16px',
                  borderRadius: '12px',
                  height: '48px',
                  borderColor: '#CBD5E1',
                  fontColor: '#334155',
                })}
              </>
            )}
          </Field>
        </div>
        <div
          className={`col-sm-12 ${deviceType === 'mobile' ? 'p-0' : 'pl-2 pr-0'}`}
        >
          <Field
            name='anotation'
            className={`modal__invoice-anotation-field ${
              anotationMaxLimit ? 'has-error' : ''
            }`}
            label={
              <Typography
                type='label-2'
                text={capitalize(I18n.get('observations', 'observaciones'))}
                variant='secondary'
              />
            }
            placeholder={I18n.get(
              'addYourObservation',
              'Ingresa tu observación'
            )}
            type='textarea'
          >
            {(props) =>
              renderField({
                ...props,
                fontSize: '16px',
                height: '48px',
                borderRadius: '12px',
                fontColor: '#334155',
                paddingLeft: '10px',
              })
            }
          </Field>
        </div>
      </div>
    </div>
  );
};

export default NewCard;
