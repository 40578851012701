import { Button } from "@alegradev/smile-ui-react";
import { I18n } from "aws-amplify";
import React from "react";

const Bottom = ({ onChangeNumeration, disabled, submitting }) => {
  return (
    <div className="w-100 p-3 mt-3 d-flex justify-content-between align-items-center">
      <Button
        emphasis="outline"
        onClick={() => onChangeNumeration()}
        text={I18n.get('', 'Cambiar a factura simplificada')}
        disabled={disabled || submitting}
      />
      <Button
        text={I18n.get('', 'Guardar datos y continuar')}
        disabled={disabled}
        loading={submitting}
      />
    </div>
  );
};

export default Bottom;