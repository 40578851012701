import React, { useEffect, useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize, forEach, get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { hasPermissionTo } from '../../../../selectors/auth';
import { activeBanks } from '../../../../selectors/banks';
import { renderField, renderSelect } from '../../fields/V0/Fields';
import Typography from '../../../common/Typography';
import { useDeviceType } from '../../../../hooks/useDeviceType';
import { Button } from '@alegradev/smile-ui-react';
import UseField from './UseField';
import { activeSellers } from '../../../../selectors/sellers';
import { checkFeatureLimit } from '../../../../reducers/membership';
import { openModal } from '../../../../reducers/modals';
import { COUNTRIES } from '../../../../utils/enums/countries';
import { isMexicoFeAvailable } from '../../../../utils/deploys/mexicoFeDeployGroups';
import useElectronicPayrollStatus from '../../../../hooks/useElectronicPayrollStatus/hook';

import { allRegimes } from '../../../countriesData/mexico/regimes';
import {
  MEXICO_DEFAULT_OTHER_RFC,
  MEXICO_DEFAULT_RFC,
} from '../../../countriesData/mexico/rfc';
import configAPI from '../../../../reducers/configAPI';
import { client as clientSelector } from '../../../../selectors/activeInvoice';
import { DEFAULT_CFDI_USE } from '../../../countriesData/mexico/cfdiUses';
import {
  companySelector,
  country as countrySelector,
  decimalPrecision,
} from '../../../../selectors/company';

const NewCombined = ({ values, fmt, form, anotationMaxLimit }) => {
  const paymentOptionsInitial = [
    { id: 1, name: 'cash', label: I18n.get('cash', 'efectivo') },
    { id: 2, name: 'debit', label: I18n.get('debit', 'débito') },
    { id: 3, name: 'credit', label: I18n.get('credit', 'crédito') },
    { id: 4, name: 'transfer', label: I18n.get('transfer', 'transferencia') },
  ];

  const [paymentOptions, setPaymentOptions] = useState(paymentOptionsInitial);
  const [paymentOptionsSelected, setPaymentOptionsSelected] = useState([
    { id: 1, name: 'cash', label: I18n.get('cash', 'efectivo') },
    { id: 2, name: 'debit', label: I18n.get('debit', 'débito') },
  ]);

  const [rowCount, setRowCount] = useState(2);

  const sellers = useSelector(activeSellers);
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const banks = useSelector(activeBanks);
  const country = useSelector(countrySelector);
  const company = useSelector(companySelector);
  const { isActive } = useElectronicPayrollStatus();
  const documentType = get(values, 'numeration.documentType', '');
  const client = useSelector(clientSelector);
  const [uses, setUses] = useState([]);

  const deviceType = useDeviceType();

  const handleOptionChange = (selectedOption, rowIndex) => {
    const updatedOptionsSelected = [...paymentOptionsSelected];
    const previousOption = updatedOptionsSelected[rowIndex];
    form.change(
      selectedOption.name,
      form.getState().values[previousOption.name]
    );
    form.change(previousOption.name, null);

    updatedOptionsSelected[rowIndex] = selectedOption;
    setPaymentOptionsSelected(updatedOptionsSelected);
  };

  useEffect(() => {
    const remainingOptions = paymentOptionsInitial.filter(
      (option) =>
        !paymentOptionsSelected.some(
          (selected) => selected.name === option.name
        )
    );
    setPaymentOptions(remainingOptions);

    if (rowCount > paymentOptionsSelected.length) {
      const firstAvailableOption = remainingOptions[0];
      if (firstAvailableOption) {
        setPaymentOptionsSelected((prevOptionsSelected) => {
          const updatedOptionsSelected = [...prevOptionsSelected];
          updatedOptionsSelected[rowCount - 1] = firstAvailableOption;
          return updatedOptionsSelected;
        });
      }
    }
  }, [rowCount, paymentOptionsSelected]);

  const handleDeleteRow = (indexToDelete) => {
    setRowCount((prevRowCount) => prevRowCount - 1);
    const optionToDelete = paymentOptionsSelected[indexToDelete];
    form.change(optionToDelete.name, null);
    setPaymentOptionsSelected((prevOptionsSelected) =>
      prevOptionsSelected.filter((_, index) => index !== indexToDelete)
    );
  };

  useEffect(() => {
    const loadCfdiUses = async () => {
      if (country !== COUNTRIES.MEXICO) return;
      const response = await configAPI.get('/cfdi-uses', {
        application_version: country,
      });
      setUses(get(response, 'data', []));
    };
    loadCfdiUses();
  }, [client]);

  const isFeMexActive = useMemo(() => {
    return isMexicoFeAvailable(country, isActive);
  }, [country, company, isActive]);

  const isUseDisabled = useMemo(() => {
    if (!client) return true;
    if (
      (client && client?.identification === MEXICO_DEFAULT_RFC) ||
      client?.identification === MEXICO_DEFAULT_OTHER_RFC
    )
      return true;
    return false;
  }, [client]);

  const useOptions = useMemo(() => {
    const options = [];
    if (country === COUNTRIES.MEXICO) {
      if (client) {
        forEach(client?.regimeObject, (value) => {
          if (allRegimes[value]) {
            forEach(uses, (use) => {
              const splitRegimes = use?.regimes?.split('|');
              if (splitRegimes.includes(`${allRegimes[value].code}`)) {
                options.push({
                  key: use.key,
                  value: use.value,
                });
              }
            });
          }
        });
      } else {
        options.push(DEFAULT_CFDI_USE);
      }
    }

    return options;
  }, [uses, client]);
  return (
    <>
      <div
        className={`${deviceType === 'mobile' && 'modal__invoice-combined-table-container'}`}
      >
        <table
          className={`modal__invoice-combined-table  ${
            deviceType === 'mobile' ? 'mobile' : ''
          }`}
        >
          <thead>
            <tr>
              <th>
                <Typography
                  text={capitalize(I18n.get('invoiceMethod', 'método de pago'))}
                  type='label-2'
                />
              </th>
              <th>
                <Typography
                  text={capitalize(I18n.get('paymentValue', 'valor del pago'))}
                  type='label-2'
                />
              </th>
              <th>
                <Typography
                  text={capitalize(I18n.get('bank', 'banco'))}
                  type='label-2'
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: rowCount }).map((_, index) => {
              const paymentOption =
                paymentOptionsSelected[index % paymentOptionsSelected.length];
              const availableOptions = paymentOptions.filter(
                (option) =>
                  !paymentOptionsSelected.some(
                    (selected) => selected.name === option.name
                  )
              );
              const isLastRow = index === rowCount - 1;
              return (
                <tr key={index}>
                  <td>
                    <div className={`${index === 0 ? 'mt-3' : ''}`}>
                      <Field
                        name={`${paymentOption.name}Option`}
                        defaultValue={paymentOption}
                        options={availableOptions}
                        required
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.id}
                        menuPosition='absolute'
                        onChange={(selectedOption) => {
                          handleOptionChange(selectedOption, index);
                        }}
                      >
                        {(props) => (
                          <>
                            {renderSelect({
                              ...props,
                              fontSize:
                                deviceType === 'mobile' ? '14px' : '16px',
                              height: '48px',
                              borderRadius: '12px',
                              borderColor: '#CBD5E1',
                              fontColor: '#334155',
                            })}
                          </>
                        )}
                      </Field>
                    </div>
                  </td>
                  <td>
                    <Field
                      name={paymentOption.name}
                      className={`col-md-12 ${index === 0 ? 'mt-3' : ''}`}
                      type='number'
                      data-testid={`invoice-combined-${paymentOption.name}-input`}
                      placeholder={fmt.prefix}
                    >
                      {(props) => (
                        <>
                          {renderField({
                            ...props,
                            fontSize: deviceType === 'mobile' ? '14px' : '16px',
                            height: '48px',
                            fontColor: '#334155',
                          })}
                        </>
                      )}
                    </Field>
                  </td>

                  <td>
                    <div
                      className={`col-sm-12 d-flex ${index === 0 ? 'mt-3' : ''}`}
                    >
                      <Field
                        name={`${paymentOption.name}Bank`}
                        className={`col-sm-${
                          isLastRow && index > 1 ? '10' : '12'
                        }`}
                        // value={banks.find((bank) => bank.id === 2)}
                        options={
                          !can('index', 'bank-accounts') ||
                          !can('view', 'bank-accounts')
                            ? []
                            : banks
                        }
                        selectInfo={
                          !can('index', 'bank-accounts') ||
                          !can('view', 'bank-accounts')
                            ? I18n.get(
                                'userNotAllowed.bank-accounts.view',
                                'no tienes permisos para ver la información de las cuentas de bancos'
                              )
                            : null
                        }
                        hideNoOptionMessage={
                          !can('index', 'bank-accounts') ||
                          !can('view', 'bank-accounts')
                        }
                        disabled={paymentOption.name === 'cash'}
                        required
                        getOptionLabel={(option) =>
                          option.name || 'Efectivo POS - Terminal '
                        }
                        getOptionValue={(option) => option.id}
                        menuPosition='absolute'
                      >
                        {(props) => (
                          <>
                            {renderSelect({
                              ...props,
                              fontSize:
                                deviceType === 'mobile' ? '14px' : '16px',
                              borderRadius: '12px',
                              height: '48px',
                              borderColor: '#CBD5E1',
                              fontColor: '#334155',
                            })}
                          </>
                        )}
                      </Field>
                      {isLastRow && index > 1 && (
                        <Button
                          onlyIcon='trash'
                          variant='reversed'
                          onClick={() => handleDeleteRow(index)}
                          size='large'
                        />
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className='modal__invoice-combined-btn-add'>
        <Button
          type='button'
          leftIcon='plus'
          text={capitalize(I18n.get('addPaymentForm', 'agregar método'))}
          size='large'
          emphasis='subtle'
          onClick={() => setRowCount(rowCount + 1)}
          disabled={rowCount === 4}
        />
      </div>

      {country === COUNTRIES.MEXICO &&
        isFeMexActive &&
        documentType === 'invoice' && (
          <div className='d-flex justify-content-center mt-4'>
            <Field
              name='use'
              className='col-sm-12 pl-2 pr-0'
              options={useOptions}
              disabled={isUseDisabled}
              required
              label={
                <Typography
                  text={capitalize(I18n.get('use', 'Uso'))}
                  type='label-2'
                  variant='secondary'
                />
              }
              defaultValue={useOptions.length > 1 ? null : useOptions[0]}
              getOptionLabel={(option) => option.value}
              getOptionValue={(option) => option.key}
            >
              {(props) => (
                <>
                  {renderSelect({
                    ...props,
                    fontSize: '16px',
                    borderRadius: '12px',
                    height: '48px',
                    fontColor: '#334155',
                  })}
                </>
              )}
            </Field>
          </div>
        )}
      <div className='d-flex justify-content-center mt-4'>
        <Field
          name='seller'
          className='col-sm-12 pl-2 pr-0'
          options={sellers}
          label={
            <Typography
              text={capitalize(I18n.get('seller', 'vendedor'))}
              type='label-2'
              variant='secondary'
            />
          }
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          addOptionText={
            can('add', 'sellers')
              ? capitalize(I18n.get('newSeller', 'Nuevo vendedor'))
              : null
          }
          addOptionAction={() =>
            dispatch(
              checkFeatureLimit(
                'sellers',
                can('add', 'sellers')
                  ? () => dispatch(openModal({ modal: 'seller' }))
                  : null
              )
            )
          }
          menuPosition='absolute'
        >
          {(props) => (
            <>
              {renderSelect({
                ...props,
                fontSize: '16px',
                borderRadius: '12px',
                height: '48px',
                fontColor: '#334155',
                borderColor: '#CBD5E1',
              })}
            </>
          )}
        </Field>
      </div>
      <div className='col-sm-12 pl-2 pr-0'>
        <Field
          name='anotation'
          className={`modal__invoice-anotation-field ${
            anotationMaxLimit ? 'has-error' : ''
          }`}
          label={
            <Typography
              type='label-2'
              text={capitalize(I18n.get('observations', 'observaciones'))}
              variant='secondary'
            />
          }
          placeholder={I18n.get('addYourObservation', 'Ingresa tu observación')}
          type='text'
        >
          {(props) =>
            renderField({
              ...props,
              fontSize: '16px',
              height: '48px',
              borderRadius: '12px',
              borderColor: '#CBD5E1',
              fontColor: '#334155',
              paddingLeft: '10px',
            })
          }
        </Field>
      </div>
    </>
  );
};

export default NewCombined;
