import Typography from "../../common/Typography";

const payInvoiceColumns =() => {
    return [
            {
                Header:'Número',
                accesor:'number',
                Cell: ({row}) => {
                    const {number} = row.original;
                    return(
                    <Typography type='body-3-regular' text={number}/>
                    )
                }
            },
            {
                Header:'Total',
                accesor:'total',
                alignment:'right',
                Cell: ({row}) => {
                    const {total} = row.original;
                    return(
                        <Typography type='body-3-regular' text={total}/>
                    )
                }
            },
            {
                Header:'Por Cobrar',
                accesor:'amount',
                alignment:'right',
                Cell: ({row}) => {
                    const {amount} = row.original;
                    return(
                        <Typography type='body-3-regular' text={amount}/>
                    )
                }
            },
            {
                Header:'Fecha de la factura',
                accesor:'creation',
                alignment:'center',
                Cell: ({row}) => {
                    const {creation} = row.original;
                    return(
                        <Typography type='body-3-regular' text={creation}/>
                    )
                }
            },
    ];
};

export default payInvoiceColumns;