import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';

import { renderNewSendEmail } from '../fields/V0/Fields';

const SendInvoiceEmail = ({ invalid, submitting }) => (
  <Field
    name='email'
    component={renderNewSendEmail}
    placeholder={I18n.get('clientEmailPlaceholder', 'Correo')}
    type='email'
    disabled={invalid || submitting}
    submitting={submitting}
  />
);

export default SendInvoiceEmail;
