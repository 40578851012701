import { useHeader } from '../../header/context/header.context';
import { Tooltip, Typography } from '@alegradev/smile-ui-react';

function MenuOption({
  children,
  header,
  id,
  headerClassName,
  extraClassName,
  tooltip,
  eventName,
  newFeature,
}) {
  const { activeMenu, setActiveMenu } = useHeader();

  return (
    <li className='h-100 py-2 new-menu d-flex align-items-center'>
      <div
        className={` ${headerClassName ? headerClassName : 'new-menu-item'}`}
        onClick={() => setActiveMenu(activeMenu === id ? '' : id, eventName)}
      >
        {!!newFeature ? (
          <Tooltip
            overlay={newFeature}
            placement='bottom'
            visible={true}
            defaultVisible={!!newFeature}
          >
            {header}
          </Tooltip>
        ) : (
          <Tooltip
            overlay={tooltip}
            placement='bottom'
            visible={!!tooltip && activeMenu !== id && !newFeature}
          >
            {header}
          </Tooltip>
        )}
      </div>

      {activeMenu === id && (
        <>
          <div className={`menu-options ${extraClassName}`}>{children}</div>
          <div
            className='menu-options-overlay'
            onClick={() => setActiveMenu('')}
          />
        </>
      )}
    </li>
  );
}

export default MenuOption;
