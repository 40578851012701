import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, capitalize } from 'lodash';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { Field } from 'react-final-form';

import * as queries from '../../../../graphql/queries';
import * as mutations from '../../../../graphql/mutations';
import { handleError } from '../../../../utils/errors';
import {
  APIGraphqlSelector,
  station as stationSelector,
} from '../../../../selectors/app';
import { renderField, renderSelect } from '../../fields/V0/Fields';
import { delay, replaceAndParse } from '../../../../utils';
import { useFormat } from '../../../../hooks/useFormat';
import {
  currency as currencySelector,
  itemsCount as itemsCountSelector,
  numeration as numerationSelector,
} from '../../../../selectors/activeInvoice';
import { sendGTMEvent } from '../../../../reducers/company';

import NequiExt from '../../../svg/paymentMethods/NequiExt';

import { Button, Icon, Typography } from '@alegradev/smile-ui-react';
import Nequi from '../../../svg/paymentMethods/Nequi';
import { checkFeatureLimit } from '../../../../reducers/membership';
import { hasPermissionTo } from '../../../../selectors/auth';
import { activeSellers } from '../../../../selectors/sellers';

const API_CALL_DELAY = 2000;
const GENERATE_QR_DELAY = 2000;

const NewNequiQR = ({ values, form, total, anotationMaxLimit }) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [checking, setChecking] = useState(false);
  const [checkingError, setCheckingError] = useState();
  const [success, setSuccess] = useState(false);
  const [clientEmail, setClientEmail] = useState(null);
  const [code, setCode] = useState();
  const [qrId, setQRId] = useState();

  const station = useSelector(stationSelector);
  const numeration = useSelector(numerationSelector);
  const currency = useSelector(currencySelector);
  const itemsCount = useSelector(itemsCountSelector);
  const APIGraphql = useSelector(APIGraphqlSelector);
  const can = useSelector(hasPermissionTo);
  const sellers = useSelector(activeSellers);

  const { fmt, decimal } = useFormat();

  // const getQRPromise = APIGraphql(graphqlOperation())
  const submit = (values) => {
    setClientEmail(get(values, 'email'));
  };

  useEffect(() => {
    ref.current = true;
    return () => (ref.current = false);
  }, []);

  const checkConditionCallback = (responseData) => {
    const stopConditions = ['APPROVED', 'DECLINED', 'ERROR'];
    const { state } = responseData;

    if (state === 'ERROR') {
      setCheckingError(
        capitalize(
          I18n.get('externalPaymentError', 'falló la verificación del pago')
        )
      );
      return true;
    }

    if (state === 'DECLINED') {
      setCheckingError(
        capitalize(
          I18n.get('externalPaymentDeclined', 'el pago ha sido rechazado')
        )
      );
      return true;
    }

    return stopConditions.includes(state);
  };

  const getProvider = useCallback(() => {
    switch (values.method) {
      case 'nequiPush':
        return 'wompi';

      default:
        return values.method;
    }
  }, [values.method]);

  const getPaymentInfo = useCallback(() => {
    switch (values.method) {
      case 'nequiPush':
        return {
          type: 'BANCOLOMBIA_QR',
        };

      default:
        return null;
    }
  }, [values.method]);

  const generateQRCode = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      let generatePayment = {};
      if (!qrId)
        generatePayment = await APIGraphql(
          graphqlOperation(mutations.createProviderPayment, {
            payment: {
              provider: getProvider(),
              terminal_id: get(station, 'id'),
              amount: total * 100,
              customer_email: clientEmail,
              payment_method: getPaymentInfo(),
            },
          })
        );

      await delay(GENERATE_QR_DELAY);

      if (!!get(generatePayment, 'data.createProviderPayment.id')) {
        setQRId(get(generatePayment, 'data.createProviderPayment.id'));
      }

      const getQRCode = await APIGraphql(
        graphqlOperation(queries.getProviderPayment, {
          payment: {
            id: qrId ?? get(generatePayment, 'data.createProviderPayment.id'),
            provider: getProvider(),
          },
        })
      );

      if (!get(getQRCode, 'data.getProviderPayment.code'))
        throw new Error('Error al obtener el codigo QR, intente otra vez');

      if (!!get(getQRCode, 'data.getProviderPayment.code')) {
        setQRId(get(getQRCode, 'data.getProviderPayment.id'));
        setCode(get(getQRCode, 'data.getProviderPayment.code'));
      }

      if (ref.current) {
        setLoading(false);
        setError(null);
      }
    } catch (error) {
      if (ref.current) {
        setLoading(false);
        setError(
          handleError(
            error,
            capitalize(
              I18n.get(
                'getQrCodeError',
                'ocurrió un problema al crear el pago, intente de nuevo'
              )
            )
          )
        );
      }
    }
    //eslint-disable-next-line
  }, [station, total, clientEmail, getProvider, getPaymentInfo]);

  useEffect(() => {
    if (!clientEmail) {
      return;
    }

    generateQRCode();
  }, [generateQRCode, clientEmail]);

  const checkQRLongPolling = useCallback(
    async (qrId) => {
      setChecking(true);
      setCheckingError(null);
      let response = {};
      try {
        response = await APIGraphql(
          graphqlOperation(queries.getProviderPayment, {
            payment: {
              id: qrId,
              provider: getProvider(),
            },
          })
        );

        if (checkConditionCallback(response.data.getProviderPayment)) {
          if (get(response, 'data.getProviderPayment.state') === 'APPROVED') {
            form.change('transfer', total);
            form.change('qrId', qrId);
            setSuccess(true);
          }

          if (ref.current) {
            setChecking(false);
            setCheckingError(null);
          }
          return;
        }
      } catch (error) {
        if (ref.current) {
          setChecking(false);
          setCheckingError(
            handleError(
              error,
              capitalize(
                I18n.get(
                  'checkQrCodeError',
                  'no se pudo verificar el estado del pago, intente de nuevo'
                )
              )
            )
          );
        }
        return;
      } finally {
        if (
          !checkConditionCallback(response.data.getProviderPayment) &&
          ref.current
        ) {
          setTimeout(() => checkQRLongPolling(qrId), API_CALL_DELAY);
        }
      }
      //eslint-disable-next-line
    },
    [getProvider, total, form?.values?.transfer]
  );

  useEffect(() => {
    if (!code || !qrId) return;
    checkQRLongPolling(qrId);
  }, [checkQRLongPolling, qrId, code]);

  return (
    <>
      {success && (
        <div className='w-100 d-flex flex-column justify-content-center align-items-center'>
          <NequiExt />
          <div
            className='svg-icon-sucess-background justify-content-center align-items-center mb-4'
            style={{ width: '5.6rem', height: '5.6rem' }}
          >
            <Icon icon='check' size='extraLarge' />
          </div>
          <p
            className='h2 px-3 py-2 inter-regular'
            style={{ color: '#16A34A' }}
          >
            {I18n.get('', '¡Pago exitoso!')}
          </p>
          <p className='h4 py-2'>
            {replaceAndParse(I18n.get('youReceived', 'Recibiste {}'), [
              total.toFormat(decimal, fmt),
            ])}
          </p>
        </div>
      )}
      {!clientEmail ? (
        <>
          <div className='w-100 d-flex flex-column justify-content-center align-items-center'>
            <Field
              name='email'
              className='w-100 pb-3'
              type='text'
              label={
                <Typography
                  type='label-2'
                  text={capitalize(
                    I18n.get('clientEmail', 'Correo del cliente')
                  )}
                  variant='secondary'
                />
              }
              required
            >
              {(props) => (
                <>
                  {renderField({
                    ...props,
                    fontSize: '16px',
                    height: '48px',
                    fontColor: '#334155',
                    paddingLeft: '10px',
                  })}
                </>
              )}
            </Field>
            <Button
              type='button'
              disabled={!get(values, 'email')}
              onClick={() => {
                submit(values);
              }}
              loading={loading || checking}
              text={I18n.get('generateQR', 'Generar código QR')}
              full
            />
          </div>
          <p className='h5 px-3 py-2 text-muted'>
            {I18n.get('paymentNotVerified', 'Pago sin verificar')}
          </p>
        </>
      ) : (
        <div className='w-100 d-flex flex-column justify-content-center align-items-center'>
          <div className='w-100'>
            <div className='d-flex align-items-center mb-4'>
              <Nequi height={26} />
              <Typography
                type='body-3-regular'
                text={I18n.get('scanTheCode', 'Escanea este código con tu app')}
                variant='tertiary'
              />
            </div>
          </div>

          <div className='form__invoice-qr-zone position-relative mb-2'>
            {!!code && (
              <img src={`data:image/svg+xml;base64,${code}`} alt='QR Code' />
            )}

            {(!!error || !!loading || !!checking || !!success) && (
              <div className='position-absolute bg-white p-2 rounded-circle'>
                {!success && loading && (
                  <Icon
                    icon='loader-2'
                    animated
                    extraClass=' icon-primary icon x2'
                  />
                )}
                {!!error && (
                  <button
                    type='button'
                    className='btn button-transparent btn-sm btn-primary rounded-circle p-1'
                    onClick={() => generateQRCode()}
                  >
                    <Icon icon='rotate' extraClass='icon-white icon x2' />
                  </button>
                )}
                {success && <Icon icon='circle-check' />}
              </div>
            )}
          </div>

          {!!error && (
            <p className='text-danger h5 text-capitalize-first text-center mt-2'>
              {error}
            </p>
          )}

          {!!checkingError && (
            <div className='w-100 d-flex flex-column justify-content-center align-items-center mt-3'>
              <button
                type='button'
                className={checking ? 'd-none' : 'btn-action'}
                onClick={() => {
                  dispatch(
                    sendGTMEvent('payment-verification-retried', {
                      isElectronicInvoice: get(
                        numeration,
                        'isElectronic',
                        false
                      ),
                      customerPaymentMethod: 'wompi-nequi',
                      currency: get(currency, 'code', 'COP'),
                      invoiceTotal: parseFloat(total),
                      totalItems: itemsCount,
                    })
                  );
                  checkQRLongPolling(qrId);
                }}
              >
                <div>
                  <Icon icon='repeat' extraClass='mr-2' />
                  {I18n.get('retry', 'Reintentar')}
                </div>
              </button>
              <p className='h5 px-3 py-2 text-danger text-capitalize-first '>
                {checkingError}
              </p>
            </div>
          )}

          {loading && (
            <p className='text-primary h5 text-capitalize-first mt-2'>
              {I18n.get('loadingQRPayment', 'generando código para pago...')}
            </p>
          )}

          {checking && (
            <p className='text-primary h5 text-capitalize-first mt-2'>
              {I18n.get('checkingQRPayment', 'verificando estado de pago...')}
            </p>
          )}
        </div>
      )}
      <div className='d-flex justify-content-center mt-4'>
        <Field
          name='seller'
          className='col-sm-12 pl-2 pr-0'
          options={sellers}
          label={
            <Typography
              text={capitalize(I18n.get('seller', 'vendedor'))}
              type='label-2'
              variant='secondary'
            />
          }
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          addOptionText={
            can('add', 'sellers')
              ? capitalize(I18n.get('newSeller', 'Nuevo vendedor'))
              : null
          }
          addOptionAction={() =>
            dispatch(
              checkFeatureLimit(
                'sellers',
                can('add', 'sellers')
                  ? () => dispatch(openModal({ modal: 'seller' }))
                  : null
              )
            )
          }
          menuPosition='absolute'
        >
          {(props) => (
            <>
              {renderSelect({
                ...props,
                fontSize: '16px',
                borderRadius: '12px',
                height: '48px',
                fontColor: '#334155',
                borderColor: '#CBD5E1',
              })}
            </>
          )}
        </Field>
      </div>
      <div className='col-sm-12 pl-2 pr-0'>
        <Field
          name='anotation'
          className={`modal__invoice-anotation-field ${
            anotationMaxLimit ? 'has-error' : ''
          }`}
          label={
            <Typography
              type='label-2'
              text={capitalize(I18n.get('observations', 'observaciones'))}
              variant='secondary'
            />
          }
          placeholder={I18n.get('addYourObservation', 'Ingresa tu observación')}
          type='text'
        >
          {(props) =>
            renderField({
              ...props,
              fontSize: '16px',
              height: '48px',
              borderRadius: '12px',
              borderColor: '#CBD5E1',
              fontColor: '#334155',
              paddingLeft: '10px',
            })
          }
        </Field>
      </div>
    </>
  );
};

export default NewNequiQR;
