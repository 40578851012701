import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash';

import { formError, handleError } from '../../../utils/errors';
import { closeModal } from '../../../reducers/modals';
import { checkStationValues } from '../../../reducers/app';
import { getShiftPayments } from '../../../reducers/shifts';
import { fetchPayments, createPayment } from '../../../reducers/payments';
import { hasPermissionTo } from '../../../selectors/auth';
import {
  shiftsEnabled as shiftsEnabledSelector,
  localSettings,
  isOnlyInvoicingPlan,
} from '../../../selectors/company';
import {
  defaultInCategory,
  defaultOutCategory,
  getCategoryById,
} from '../../../selectors/categories';
import {
  availableCash,
  isOpen as shiftOpenSelector,
} from '../../../selectors/shifts';
import Body from '../../forms/payments/Payments';
import { validate, transform } from '../../forms/payments/utils';
import Modal from '../../common/Modal';
import Notification from '../common/Notification';
import Bottom from '../common/Bottom';
import { sendNewGTMEvent } from '../../../reducers/company';
import { endAction, startAction } from '../../../reducers/monitoring';
import { calculateActionTimeSelector } from '../../../selectors/monitoring';
import useSendGTMEvent from '../../../hooks/useSendGtmEvent/hook';

const Payments = () => {
  const isOpen = useSelector((state) =>
    get(state, 'modals.payments.isOpen', false)
  );
  useSendGTMEvent(isOpen, 'pos-cash-flow-started');
  const creationOrigin = useSelector((state) =>
    get(state, 'modals.payments.params.creationOrigin')
  );
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const shiftsEnabled = useSelector(shiftsEnabledSelector);
  const shiftOpen = useSelector(shiftOpenSelector);
  const shiftCash = useSelector(availableCash);
  const posSettings = useSelector(localSettings);
  const onlyInvoicing = useSelector(isOnlyInvoicingPlan);
  const defaultIncome = useSelector(
    getCategoryById(posSettings.idCategoryDefaultIncome)
  );
  const defaultIncomeOnlyInvoicing = useSelector(defaultInCategory);
  const defaultExpense = useSelector(
    getCategoryById(posSettings.idCategoryDefaultExpense)
  );
  const defaultExpenseOnlyInvoicing = useSelector(defaultOutCategory);
  const calculateActionTime = useSelector(calculateActionTimeSelector);
  const inCategory = !onlyInvoicing
    ? defaultIncome
    : defaultIncomeOnlyInvoicing;
  const outCategory = !onlyInvoicing
    ? defaultExpense
    : defaultExpenseOnlyInvoicing;

  const submit = async (values) => {
    try {
      dispatch(startAction({action: 'createCashFlow'}));
      const canContinue = await dispatch(
        checkStationValues({ type: 'payment', values })
      );
      if (!canContinue) return;

      const transformedValues = transform(values);

      await dispatch(createPayment({ ...transformedValues, creationOrigin }));
      Promise.all([
        dispatch(closeModal({ modal: 'payments' })),
        dispatch(fetchPayments()),
        dispatch(getShiftPayments()),
      ]);

      dispatch(endAction({action: 'createCashFlow'}));
      dispatch(
        sendNewGTMEvent('pos-cash-flow-created', {
          error: 'no error',
          responseTime: calculateActionTime("createCashFlow"),
          type: get(transformedValues, 'type', ''),
          journal: get(values, 'accountingAccount.name', ''),
        })
      );
    } catch (error) {
      const parseError = handleError(error);
      dispatch(
        sendNewGTMEvent('pos-cash-flow-created', {
          error: parseError
        })
      );
      return formError(
        error,
        I18n.get(
          'createPaymentError',
          'hubo un error a la hora de crear el pago'
        )
      );
    }
  };

  const changeType = (type, form) => {
    form.change('accountingAccount', type === 'in' ? inCategory : outCategory);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => dispatch(closeModal({ modal: 'payments' }))}
      title={I18n.get('inOutCash', 'ingresar / retirar efectivo')}
      className='modal__payments'
    >
      <Form
        onSubmit={submit}
        validate={(values) =>
          validate(values, { shiftCash, shiftsEnabled, shiftOpen })
        }
        initialValues={{
          type: can('in', 'payments') && can('out', 'payments') ? 'out' : can('in', 'payments') ? 'in' : 'out',
          accountingAccount: outCategory,
        }}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, values, form, submitting, invalid, submitError }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className='d-flex flex-column p-3'>
              <Body
                values={values}
                onChangeType={(type) => changeType(type, form)}
              />
            </div>

            {/* <Notification
              type='warning'
              isOpen={
                !can('add', 'payments') ||
                (values.type === 'in' && !can('in', 'payments')) ||
                (values.type === 'out' && !can('out', 'payments'))
              }
              text={I18n.get(
                'userNotAllowed.payments.add',
                'no tienes permisos para la operación deseada'
              )}
            /> */}

            <Notification isOpen={!!submitError} text={submitError} />

            <Bottom
              submitting={submitting}
              disabled={
                invalid ||
                (values.type === 'in' && !can('in', 'payments')) ||
                (values.type === 'out' && !can('out', 'payments'))
              }
              onClose={() => dispatch(closeModal({ modal: 'payments' }))}
            />
          </form>
        )}
      </Form>
    </Modal>
  );
};

export default Payments;
