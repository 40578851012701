import React, { useEffect, lazy, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { GlobalHotKeys, configure } from 'react-hotkeys';
import { useHistory, useLocation } from 'react-router-dom';

import { keyMap } from '../utils/platform';
import { setSearchProductType } from '../reducers/app';
import { openModal } from '../reducers/modals';
import { openSideModal } from '../reducers/sideModals';
import { hasPermissionTo } from '../selectors/auth';
import {
  country as countrySelector,
  shiftsEnabled,
  isOnlyInvoicingPlan,
  companySelector,
} from '../selectors/company';
import { itemsCount } from '../selectors/activeInvoice';
import { globalState as globalStateSelector } from '../selectors/app';
import store from 'store2';
import { COUNTRIES } from '../utils/enums/countries';
import { get } from 'lodash';
import { sendNewGTMEvent } from '../reducers/company';

// Lazy loading components
const Home = lazy(() => import('./home/Home'));
const Invoices = lazy(() => import('./invoices/Invoices'));
const Shifts = lazy(() => import('./shifts/Shifts'));
const Items = lazy(() => import('./items/Items'));
const Payments = lazy(() => import('./payments/Payments'));
const Refunds = lazy(() => import('./refunds/Refunds'));
const Statistics = lazy(() => import('./statistics/Statistics'));
const Inventory = lazy(() => import('./inventory/Inventory'));
const Settings = lazy(() => import('./settings/Settings'));
const Membership = lazy(() => import('./membership/Membership'));
const Authentication = lazy(() => import('./desktop/Authentication'));
const NewInvoices = lazy(() => import('./invoices/NewInvoices'));
const PaymentsReceived = lazy(() => import('./paymentsReceived/main'));
const SalesInvoiceDetail = lazy(() => import('./invoices/SalesInvoiceDetail'));
const PaymentReceivedAllDetail = lazy(() => import('./paymentsReceived/PaymentReceivedDetail'));
const ElectronicDocuments = lazy(() => import('../pages/electronicDocuments/ElectronicDocuments'));
const ContactsRouter = lazy(() => import('../pages/contacts/router'));
const GlobalInvoicesRouter = lazy(() => import('../pages/globalInvoices/router'));

const Main = () => {
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  let history = useHistory();
  let location = useLocation();
  const isShiftsEnabled = useSelector(shiftsEnabled);
  const onlyInvoicingPlan = useSelector(isOnlyInvoicingPlan);
  const activeInvoiceItems = useSelector(itemsCount);
  const country = useSelector(countrySelector);
  const globalState = useSelector(globalStateSelector);
  const company = useSelector(companySelector);

  useEffect(() => {
    store.set('globalState', globalState);
  }, [globalState]);

  configure({ ignoreTags: [] });

  const handlers = {
    GOTO_HOME: (event) => {
      event.preventDefault();
      if (location.pathname !== '/') history.push('/');
    },
    GOTO_PAYMENTS: (event) => {
      event.preventDefault();
      if (location.pathname !== '/payments') history.push('/payments');
    },
    GOTO_REFUNDS: (event) => {
      event.preventDefault();
      if (location.pathname !== '/refunds') history.push('/refunds');
    },
    GOTO_SHIFTS: (event) => {
      event.preventDefault();
      if (location.pathname !== '/shifts' && isShiftsEnabled)
        history.push('/shifts');
    },
    OPEN_SHORTCUT_HELP: (event) => {
      event.preventDefault();
      dispatch(openModal({ modal: 'shortcuts' }));
    },
    OPEN_NEW_ITEM: (event) => {
      event.preventDefault();
      if (location.pathname === '/') dispatch(openModal({ modal: 'item' }));
    },
    OPEN_NEW_CLIENT: (event) => {
      event.preventDefault();
      if (location.pathname === '/')
        dispatch(openSideModal({ sideModal: 'contact' }));
    },
    OPEN_PAYMENT_MODAL: (event) => {
      event.preventDefault();
      if (location.pathname === '/' && !!activeInvoiceItems)
        dispatch(openModal({ modal: 'invoice' }));
    },
    FOCUS_NORMAL_SEARCH: (event) => {
      event.preventDefault();
      if (location.pathname === '/') dispatch(setSearchProductType('text'));
    },
    FOCUS_BARCODE_SEARCH: (event) => {
      event.preventDefault();
      if (location.pathname === '/') dispatch(setSearchProductType('barcode'));
    },
  };

  useEffect(() => {
    switch (location.pathname) {
      case '/invoices':
        dispatch(sendNewGTMEvent('pos-invoices-visited'));
        break;
      case '/refunds':
        dispatch(sendNewGTMEvent('pos-refunds-visited'));
        break;
      case '/settings/station':
        dispatch(sendNewGTMEvent('pos-current-station-visited'));
        break;
      case '/settings/stations':
        dispatch(sendNewGTMEvent('pos-stations-visited'));
        break;
      case '/settings':
        dispatch(sendNewGTMEvent('pos-settings-visited'));
        break;
      case '/settings/sellers':
        dispatch(sendNewGTMEvent('pos-sellers-visited'));
        break;
      case '/payments':
        dispatch(sendNewGTMEvent('pos-cash-flow-visited'));
        break;
    }
  }, [location]);

  return (
    <div className='main main-new-color'>
      {!onlyInvoicingPlan && (
        <GlobalHotKeys
          keyMap={keyMap(country)}
          handlers={handlers}
          allowChanges
        />
      )}

      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route path='/' exact component={Home} />
          {!onlyInvoicingPlan && <Route path='/invoices' component={Invoices} />}
          {!onlyInvoicingPlan && get(company, 'id') === '97812' && <Route path='/invoices-detail/:id' component={SalesInvoiceDetail}/>}
          {!onlyInvoicingPlan && get(company, 'id') === '97812' && <Route path='/payments-received/:id' component={PaymentReceivedAllDetail} />}
          {!onlyInvoicingPlan && get(company, 'id') === '97812' && <Route path='/payments-received' component={PaymentsReceived} />}
          <Route path='/global-invoices' component={GlobalInvoicesRouter} />
          <Route path='/contacts' component={ContactsRouter} />
          {!!isShiftsEnabled &&
            !onlyInvoicingPlan && (
              <Route path='/shifts' component={Shifts} />
            )}
          {!onlyInvoicingPlan && <Route path='/items' component={Items} />}
          {!onlyInvoicingPlan && <Route path='/payments' component={Payments} />}
          {!onlyInvoicingPlan && <Route path='/refunds' component={Refunds} />}
          {!onlyInvoicingPlan && (
            <Route path='/statistics' component={Statistics} />
          )}
          {country === COUNTRIES.COLOMBIA && <Route path='/electronic-documents' component={ElectronicDocuments} />}
          <Route path='/plan' component={Membership} />
          <Route path='/inventory' component={Inventory} />
          <Route path='/settings' component={Settings} />
          <Route path='/desktop' component={Authentication} />
          <GlobalInvoicesRouter />
          <Redirect to='/' />
        </Switch>
      </Suspense>
    </div>
  );
};

export default Main;
