import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { capitalize, get, lowerCase, replace } from 'lodash';
import { BigNumber } from 'bignumber.js';

import { closeModal, openModal } from '../../../reducers/modals';
import {
  companySelector,
  country as countrySelector,
  decimalPrecision,
  localSettings as localSettingsSelector,
} from '../../../selectors/company';
import { getMainCurrency } from '../../../selectors/currencies';
import Success from '../../svg/NewSuccess';
import Modal from '../../common/Modal';
import BodyInvoiceSaved from './BodyInvoiceSaved';
import BottomInvoiceSaved from './BottomInvoiceSaved';
import { calculateItemsValues, replaceAndParse } from '../../../utils';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import { useDeviceType } from '../../../hooks/useDeviceType';
import InvoiceSaving from './InvoiceSaving';
import useOfflineStatusAvailable from '../../../hooks/useOfflineStatusAvailable/hook';
import useConnectionStatus from '../../../hooks/useConnectionStatus/hook';
import { useApiAvailable } from '../../../hooks/useApiAvailable';
import InvoiceOfflineSaved from './InvoiceOfflineSaved';
import { Typography } from '@alegradev/smile-ui-react';
import NewBodyInvoiceSaved from './NewBodyInvoiceSaved';

const NewInvoiceSaved = () => {
  const { isVersionAvailable } = useOfflineStatusAvailable();
  const isOnline = useConnectionStatus();
  const isApiAvailable = useApiAvailable();

  const dispatch = useDispatch();
  const [header, setHeader] = useState(false);
  const invoiceCreated = useSelector((state) =>
    get(state, 'print.invoice', null)
  );

  const isOpen = useSelector((state) =>
    get(state, 'modals.newInvoiceSaved.isOpen', false)
  );
  const decimal = useSelector(decimalPrecision);
  const mainCurrency = useSelector(getMainCurrency);
  const country = useSelector(countrySelector);
  const localSettings = useSelector(localSettingsSelector);
  const company = useSelector(companySelector);
  const items = get(invoiceCreated, 'items', []);
  const prefix = !!get(invoiceCreated, 'currency.symbol')
    ? get(invoiceCreated, 'currency.symbol')
    : !!get(mainCurrency, 'symbol')
      ? get(mainCurrency, 'symbol')
      : '';

  const fmt = {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  };

  const getTitle = () => {
    let title = I18n.get('invoiceSaved', 'factura guardada');

    if (
      country === 'panama' &&
      !!get(invoiceCreated, 'numberTemplate.isElectronic')
    )
      title = I18n.get(
        'electronicInvoiceSaved',
        'Factura electrónica guardada'
      );

    if (
      country === 'colombia' &&
      (get(invoiceCreated, 'numberTemplate.prefix') === 'EPOS' ||
        !!get(invoiceCreated, 'numberTemplate.linkedDevices', null))
    )
      title = I18n.get('sellSaved', 'Venta guardada');

    if (
      country === 'colombia' &&
      get(invoiceCreated, 'numberTemplate.documentType') === 'saleTicket' &&
      get(invoiceCreated, 'numberTemplate.isElectronic')
    )
      title = I18n.get(
        'electronicPOSDocumentSaved',
        'Documento POS electrónico guardado'
      );

    if (
      country === 'peru' &&
      get(invoiceCreated, 'numberTemplate.documentType') === 'saleTicket'
    )
      title = I18n.get('saleTicketSaved', 'Boleta guardada');

    if (
      country === 'costaRica' &&
      get(invoiceCreated, 'numberTemplate.documentType') === 'saleTicket'
    )
      title = I18n.get('saleTicketSaved', 'Tiquete guardado');

    if (
      country === 'spain' &&
      get(invoiceCreated, 'numberTemplate.documentType') === 'saleTicket'
    ) {
      title = I18n.get('invoiceModalTitle.saleTicketSaved', 'Ticket guardado');
    }
    return title;
  };

  const getInvoiceMessage = () => {
    let documentType = I18n.get('invoice', 'factura guardada');

    if (
      country === 'panama' &&
      !!get(invoiceCreated, 'numberTemplate.isElectronic')
    )
      documentType = I18n.get('electronicInvoice', 'Factura electrónica');

    if (
      country === 'colombia' &&
      (get(invoiceCreated, 'numberTemplate.prefix') === 'EPOS' ||
        !!get(invoiceCreated, 'numberTemplate.linkedDevices', null))
    )
      documentType = I18n.get('sale', 'Venta');

    if (
      country === 'colombia' &&
      get(invoiceCreated, 'numberTemplate.documentType') === 'saleTicket' &&
      get(invoiceCreated, 'numberTemplate.isElectronic')
    )
      documentType = I18n.get(
        'electronicPOSDocument',
        'Documento POS electrónico'
      );

    if (
      country === 'peru' &&
      get(invoiceCreated, 'numberTemplate.documentType') === 'saleTicket'
    )
      documentType = I18n.get('saleTicket', 'Boleta');

    if (
      country === 'costaRica' &&
      get(invoiceCreated, 'numberTemplate.documentType') === 'saleTicket'
    )
      documentType = I18n.get('saleTicket', 'Tiquete');

    if (
      country === 'spain' &&
      get(invoiceCreated, 'numberTemplate.documentType') === 'saleTicket'
    ) {
      documentType = I18n.get('saleTicket', 'Ticket');
    }

    return replace(
      I18n.get(
        'invoiceCreatedSuccessfullyMessage',
        'Tu {} se guardó con éxito.'
      ),
      '{}',
      documentType
    );
  };

  const handleClose = () => {
    const cookieName = `reminder5UVT`;
    const cookieValue = Cookies.get(cookieName);
    const cookieDate = !!cookieValue ? cookieValue : null;

    const showReminder5UVT =
      get(localSettings, 'showReminder5UVT', false) &&
      (!cookieDate ? true : dayjs().isAfter(parseInt(cookieDate)));

    dispatch(closeModal({ modal: 'newInvoiceSaved' }));
    if (items.length === 0) return;

    const { discSubtotal } = calculateItemsValues({
      items,
      decimal,
      country,
      company,
    });
    if (
      country === 'colombia' &&
      showReminder5UVT &&
      !get(localSettings, 'automationFEEnabled', true) &&
      get(invoiceCreated, 'numberTemplate.documentType', null) ===
        'saleTicket' &&
      discSubtotal >= 212060
    ) {
      Cookies.set(cookieName, dayjs().add(1, 'day').startOf('day').valueOf());
      dispatch(openModal({ modal: 'reminder5UVT' }));
    }
  };

  useEffect(() => {
    if (!!invoiceCreated && !!invoiceCreated.timestamp) {
      setHeader(false);
    } else {
      setHeader(true);
    }
  }, [invoiceCreated]);

  if (isVersionAvailable && (!isOnline || !isApiAvailable)) {
    return (
      <InvoiceOfflineSaved
        isOpen={isOpen}
        isOnline={isOnline}
        handleClose={handleClose}
        invoiceCreated={invoiceCreated}
      />
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      title={getTitle()}
      className='modal__invoice'
      includeHeader={false}
    >
      {!!invoiceCreated && !!invoiceCreated.timestamp ? (
        <InvoiceSaving invoiceCreated={invoiceCreated} />
      ) : (
        <div>
          <div className='p-3'>
            <Typography text={getTitle()} type='heading-4' />
          </div>
          <div className='d-flex flex-column p-3 justify-content-center align-items-center'>
            <div className='p-4 d-flex flex-column justify-content-center align-items-center'>
              <Success />
              <div className='modal__invoice-success-title'>
                <Typography
                  text={I18n.get('invoiceCreatedSuccessfully', '¡Muy bien!')}
                  type='heading-4'
                />
              </div>
              <div className='modal__invoice-success-message'>
                <Typography text={getInvoiceMessage()} type='body-2-regular' />
              </div>
            </div>
            {!!invoiceCreated && (
              <div className='d-flex align-items-center gap-3 modal__invoice-success-total'>
                <div>
                  <Typography
                    text={I18n.get('total', 'total')}
                    type='heading-4'
                  />
                </div>
                <div>
                  <Typography
                    text={new BigNumber(invoiceCreated.total).toFormat(
                      decimal,
                      fmt
                    )}
                    type='heading-4'
                  />
                </div>
              </div>
            )}

            <NewBodyInvoiceSaved />
          </div>
        </div>
      )}
      <BottomInvoiceSaved handleClose={handleClose} />
    </Modal>
  );
};

export default NewInvoiceSaved;
