import { get, has, isArray, isNull, isUndefined, startsWith, isNumber, isEmpty } from 'lodash'
import { COUNTRIES } from './enums/countries'

export const invoiceTransformer = invoice => {
  let additionalCharges = null;

  if (!isEmpty(get(invoice, 'additionalCharges'), [])) {
    additionalCharges = [];

    get(invoice, "additionalCharges", []).forEach(charge => {
      if (Number(get(charge, "amount")) > 0) {
        additionalCharges.push({
          "id": get(charge, "id"),
          "percentage": get(charge, "percetage"),
          "amount": get(charge, "amount")
        })
      }
    })
  }

  let result = {
    idStation: get(invoice, 'idStation', null),
    date: get(invoice, 'date', null),
    dueDate: get(invoice, 'dueDate', null),
    status: get(invoice, 'status', null),
    timestamp: get(invoice, 'timestamp', null),
    termsConditions: get(invoice, 'termsConditions', null),
    items: invoice.items.map(item => ({
      id: get(item, 'id', null),
      description: get(item, 'description', null),
      discount: !!get(item, 'discount', null) ? JSON.stringify(get(item, 'discount', null)) : null,
      reference: !!get(item, 'reference', null) ? JSON.stringify(get(item, 'reference', null)) : null,
      price: get(item, 'price', null),
      tax: !!get(item, 'tax', null)
        ? isArray(get(item, 'tax'))
          ? item.tax.map(t => ({ id: t.id })) :
          [{ id: get(item, 'tax.id') }]
        : [],
      quantity: has(item, 'quantity') && isNumber(Number(item.quantity)) ? Number(item.quantity).toFixed(4) : null,
    })),
    payments: invoice.payments.map(payment => ({
      date: get(payment, 'date', null),
      amount: get(payment, 'amount', null),
      account: !!get(payment, 'account', null) ? { id: get(payment, 'account', null) } : null,
      paymentMethod: get(payment, 'paymentMethod', null),
      numberTemplate: get(payment, 'numberTemplate', null),
      currency: !!get(payment, 'currency.exchangeRate')
        ? { code: get(payment, 'currency.code'), exchangeRate: get(payment, 'currency.exchangeRate') } : null,
      idPaymentMethodLocal: get(invoice, 'idPaymentMethodLocal', null),
    })),
    numberTemplate: !!get(invoice, 'numberTemplate.id', null)
      ? { id: get(invoice, 'numberTemplate.id', null) } : null,
    total: get(invoice, 'total', null),
    subtotal: get(invoice, 'subtotal', null),
    totalReceived: get(invoice, 'totalReceived', null),
    cashReturned: get(invoice, 'cashReturned', null),
    type: get(invoice, 'type', null),
    anotation: get(invoice, 'anotation', null),
    client: get(invoice, 'client.id', null),
    costCenter: !!get(invoice, 'costCenter', null)
      ? get(invoice, 'costCenter', null) : null,
    priceList: get(invoice, 'priceList.id', 0),
    warehouse: !!get(invoice, 'warehouse', null)
      ? get(invoice, 'warehouse', null) : null,
    seller: !!get(invoice, 'seller.id', null) ? { id: get(invoice, 'seller.id', null) } : null,
    currency: {
      code: !!get(invoice, 'currency.exchangeRate', null) ? get(invoice, 'currency.code', null) : null,
      exchangeRate: get(invoice, 'currency.exchangeRate', null) ? get(invoice, 'currency.exchangeRate') : null,
    },
    externalPayment: {
      id: get(invoice, 'externalPayment.id', null),
      provider: ["nequiPush", "bancolombiaQR"].includes(get(invoice, 'externalPayment.provider', null)) ? 'wompi' : get(invoice, 'externalPayment.provider', null)
    },
    deliveryTerm: get(invoice, 'deliveryTerm', null),
    stamp: {
      generateStamp: get(invoice, 'stamp.generateStamp', false),
    },
    operationType: get(invoice, 'operationType', null),
    saleCondition: get(invoice, 'saleCondition', null),
    saleConcept: get(invoice, 'saleConcept', null),
    startDateService: get(invoice, 'startDateService', null),
    endDateService: get(invoice, 'endDateService', null),
    paymentForm: !!get(invoice, 'paymentForm', null) ? JSON.stringify(get(invoice, 'paymentForm', null)) : null,
    paymentMethod: get(invoice, 'paymentMethod', null),
    paymentType: get(invoice, 'paymentType', null),
    incomeType: get(invoice, 'incomeType', null),
    saleType: get(invoice, 'saleType', null),
    originApp: get(invoice, 'originApp', null),
    station: {
      id: get(invoice, 'station.id', null),
      name: get(invoice, 'station.name', null),
      address: get(invoice, 'station.address', null),
      city: get(invoice, 'station.city', null),
      phone: get(invoice, 'station.phone', null),
      email: get(invoice, 'station.email', null),
    },
    additionalCharges,
    provisionalTicket: get(invoice, 'provisionalTicket', null),
  }

  if (get(invoice, 'country') === COUNTRIES.MEXICO && get(invoice, 'numberTemplate.documentType') === 'invoice') {
    result = {
      ...result,
      cfdiUse: get(invoice, 'cfdiUse', null),
      regimeClient: get(invoice, 'regimeClient', null),
      stamp: {
        ...result.stamp,
        version: get(invoice, 'stamp.version', null),
      }
    }
  }

  if (!result.paymentType)
    delete result.paymentType

  if (!result.additionalCharges || !result.additionalCharges.length)
    delete result.additionalCharges

  return result;
}

export const refundTransformer = refund => ({
  idStation: get(refund, 'idStation', null),
  anotation: get(refund, 'anotation', null),
  note: get(refund, 'note', null),
  cause: get(refund, 'cause', null),
  client: get(refund, 'client.id', null),
  costCenter: get(refund, 'costCenter', null),
  currency: {
    code: get(refund, 'currency.code', null),
    exchangeRate: get(refund, 'currency.exchangeRate', null),
  },
  economicActivity: get(refund, 'economicActivity', null),
  date: get(refund, 'date', null),
  dueDate: get(refund, 'dueDate', null),
  additionalCharges: refund.additionalCharges.map(charge => ({...charge})),
  items: refund.items.map(item => ({
    id: get(item, 'id', null),
    description: get(item, 'description', null),
    discount: !!get(item, 'discount', null) ? JSON.stringify(get(item, 'discount', null)) : null,
    reference: !!get(item, 'reference', null) ? JSON.stringify(get(item, 'reference', null)) : null,
    price: get(item, 'price', null),
    tax: !!get(item, 'tax', null)
      ? isArray(get(item, 'tax'))
        ? item.tax.map(t => ({ id: t.id })) :
        [{ id: get(item, 'tax.id') }]
      : [],
    quantity: get(item, 'quantity', null),
  })),
  numberTemplate: !!get(refund, 'numberTemplate.id', null)
    ? { id: get(refund, 'numberTemplate.id', null) } : null,
  priceList: get(refund, 'priceList.id', 0),
  saleCondition: get(refund, 'saleCondition', null),
  saleConcept: get(refund, 'saleConcept', null),
  startDateService: get(refund, 'startDateService', null),
  endDateService: get(refund, 'endDateService', null),
  termsConditions: get(refund, 'termsConditions', null),
  type: get(refund, 'type', null),
  warehouse: get(refund, 'warehouse', null),
  paymentMethod: get(refund, 'paymentMethod', null),
  observations: get(refund, 'observations', null),
  invoices: refund.invoices.map(invoice => ({
    id: get(invoice, 'id', null),
    amount: get(invoice, 'amount', 0),
  })),
  refunds: refund.refunds.map(refund => ({
    date: get(refund, 'date', null),
    account: get(refund, 'account', null),
    amount: get(refund, 'amount', 0),
    observations: get(refund, 'observations', null),
  })),
  subtotal: get(refund, 'subtotal', 0),
  stamp: {
    generateStamp: get(refund, 'stamp.generateStamp', false),
  },
})

export const paymentTransformer = payment => ({
  idStation: get(payment, 'idStation', null),
  client: get(payment, 'client.id', null),
  control: get(payment, 'control', null),
  costCenter: get(payment, 'costCenter', null),
  numberTemplate: get(payment, 'numberTemplate', null),
  paymentMethod: get(payment, 'paymentMethod', null),
  date: get(payment, 'date', null),
  type: get(payment, 'type', null),
  observations: get(payment, 'observations', null),
  categories: payment.categories.map(cat => ({
    id: get(cat, 'id', null),
    price: get(cat, 'price', 0),
    quantity: get(cat, 'quantity', 1),
    observations: get(cat, 'observations', null),
  })),
})

export const stationTransformer = station => {
  let result = {
    id: get(station, 'id', null),
    name: get(station, 'name', null),
    idInvoiceNumberTemplate: !!get(station, 'idInvoiceNumberTemplate', null)
      ? get(station, 'idInvoiceNumberTemplate', null) : null,
    idNumberTemplate: !!get(station, 'idNumberTemplate', null)
      ? get(station, 'idNumberTemplate', null) : null,
    idFENumberTemplate: !!get(station, 'idFENumberTemplate', null)
      ? get(station, 'idFENumberTemplate', null) : null,
    idCashReceiptNumberTemplate: !!get(station, 'idCashReceiptNumberTemplate', null)
      ? get(station, 'idCashReceiptNumberTemplate', null) : null,
    idRefundNumberTemplate: !!get(station, 'idRefundNumberTemplate', null)
      ? get(station, 'idRefundNumberTemplate', null) : null,
    idWarehouse: !!get(station, 'idWarehouse', null)
      ? get(station, 'idWarehouse', null) : null,
    idPriceList: !!get(station, 'idPriceList', null)
      ? get(station, 'idPriceList', null) : null,
    idCredit: !!get(station, 'idCredit', null)
      ? get(station, 'idCredit', null) : null,
    idDebit: !!get(station, 'idDebit', null)
      ? get(station, 'idDebit', null) : null,
    idTransfer: !!get(station, 'idTransfer', null)
      ? get(station, 'idTransfer', null) : null,
    baseBank: !!get(station, 'baseBank', null)
      ? get(station, 'baseBank', null) : null,
    closeBank: !!get(station, 'closeBank', null)
      ? get(station, 'closeBank', null) : null,
    idCostCenter: !!get(station, 'idCostCenter', null)
      ? get(station, 'idCostCenter', null) : null,
    seller: !!get(station, 'seller', null)
      ? get(station, 'seller', null) : null,
    userIds: get(station, 'userIds', null),
    pendingInvoicesEnabled: get(station, 'pendingInvoicesEnabled', null),
    pendingInvoicesPrefix: get(station, 'pendingInvoicesPrefix', null),
    isActive: get(station, 'isActive', null),
    address: get(station, 'address', null),
    combinedAddress: get(station, 'combinedAddress', null),
    phone: get(station, 'phone', null),
    email: get(station, 'email', null),
    tradeName: get(station, 'tradeName', null),
  }

  if (!result.id)
    delete result.id

  return result
}

export const sellerTransformer = seller => {
  let result = {
    id: get(seller, 'id', null),
    name: get(seller, 'name', null),
    identification: get(seller, 'identification', null),
    observations: get(seller, 'observations', null),
  }

  if (!result.id)
    delete result.id

  return result
}

export const resolutionTransformer = resolution => {
  let result = {
    id: get(resolution, 'id', null),
    name: get(resolution, 'name', null),
    isDefault: get(resolution, 'isDefault', null),
    documentType: get(resolution, 'documentType', null),
    autoincrement: get(resolution, 'autoincrement', null),
    prefix: get(resolution, 'prefix', null),
    nextInvoiceNumber: get(resolution, 'nextInvoiceNumber', null),
    invoiceText: get(resolution, 'invoiceText', null),
    status: get(resolution, 'status', 'active'),
  }

  if (!result.id)
    delete result.id

  return result
}

export const costCenterTransformer = costCenter => {
  let result = {
    id: get(costCenter, 'id', null),
    name: get(costCenter, 'name', null),
    code: get(costCenter, 'code', null),
    description: get(costCenter, 'description', null),
  }

  if (!result.id)
    delete result.id

  return result
}

export const warehouseTransformer = warehouse => {
  let result = {
    id: get(warehouse, 'id', null),
    name: get(warehouse, 'name', null),
    address: get(warehouse, 'address', null),
    observations: get(warehouse, 'observations', null),
    costCenter: !!get(warehouse, 'costCenter.id', null) ? { id: get(warehouse, 'costCenter.id', null) } : null,
  }

  if (!result.id)
    delete result.id

  return result
}

export const clientTransformer = client => {
  const address = get(client, 'address', null);

  let newValues = {
    name: JSON.stringify(get(client, 'name', '')),
    nameObject: JSON.stringify(get(client, 'nameObject', null)),
    accounting: JSON.stringify(get(client, 'accounting', {})),
    address: !!address ? JSON.stringify({ ...address, description: !!address.description ? address.description : null }) : null,
    email: get(client, 'email', null),
    fax: get(client, 'fax', null),
    identification: JSON.stringify(get(client, 'identification', null)),
    identificationObject: get(client, 'identificationObject', null),
    kindOfPerson: get(client, 'kindOfPerson', null),
    mobile: get(client, 'mobile', null),
    phonePrimary: get(client, 'phonePrimary', null),
    phoneSecondary: get(client, 'phoneSecondary', null),
    regime: get(client, 'regime', null),
    regimeObject: get(client, 'regimeObject', null),
    observations: get(client, 'observations', null),
    priceList: get(client, 'priceList', null),
    seller: get(client, 'seller', null),
    ignoreRepeated: get(client, 'ignoreRepeated', false),
    // settings: JSON.stringify(get(client, 'settings', null)),
    statementAttached: get(client, 'statementAttached', null),
    term: get(client, 'term', null),
    type: get(client, 'type.0', null),
    fiscalResponsabilities: get(client, 'fiscalResponsabilities', null),
    ivaCondition: get(client, 'ivaCondition', null),
    thirdType: get(client, 'thirdType', null) || get(client, 'thirdsType', null),
    creditLimit: get(client, 'creditLimit', null),
  }

  Object.keys(newValues).map(key => {
    if (isNull(newValues[key]) || isUndefined(newValues[key]))
      delete newValues[key];
    return null;
  })

  return newValues;
}

export const itemTransformer = item => {

  const buildInventory = () => {
    const hasInitialQuantity = !!get(item, 'inventory.initialQuantity', null);
    const hasWarehouses = !!get(item, 'inventory.warehouses.length', null);
    const isService = get(item, 'type') === 'service';
    const isVariantParent = get(item, 'type') === 'variantParent';
    const isKit = get(item, 'type') === 'kit';

    if (!hasInitialQuantity && !hasWarehouses && !isService && !isVariantParent && !isKit) {
      return null;
    }

    let inventory = get(item, 'inventory');

    if (isVariantParent) {
      if (inventory && inventory.hasOwnProperty('initialQuantity')) {
        delete inventory.initialQuantity;
      }
    }

    return inventory;
  };


  let newValues = {
    id: get(item, 'id', null),
    name: get(item, 'name'),
    image: get(item, 'image', null),
    price: !!get(item, 'id', null) ? get(item, 'price', []) : JSON.stringify(get(item, 'price', [])),
    description: get(item, 'description', null),
    reference: !!get(item, 'reference', null)
      ? !!get(item, 'id', null) ? get(item, 'reference') : JSON.stringify(get(item, 'reference')) : null,
    tax: !!get(item, 'tax') && !!get(item, 'tax.length')
      ? get(item, 'tax').map(t => ({ id: t })) : !!get(item, 'id', null) ? [] : null,
    type: get(item, 'type'),
    category: !!get(item, 'category') ? { id: get(item, 'category') } : null,
    productKey: !!get(item, 'productKey.key', null) ? get(item, 'productKey.key') : get(item, 'productKey'),
    itemCategory: !!get(item, 'itemCategory') ? { id: get(item, 'itemCategory') } : null,
    tariffHeading: get(item, 'tariffHeading'),
    customFields: get(item, 'customFields'),
    inventory: buildInventory(item),
    brand: get(item, 'brand'),
    model: get(item, 'model'),
    subitems: !!get(item, 'subitems')
      ? get(item, 'subitems').map(item => ({
        item: { id: get(item, 'item.id') },
        quantity: get(item, 'quantity')
      }))
      : null,
    kitWarehouse: !!get(item, 'kitWarehouse') ? { id: get(item, 'kitWarehouse') } : null,
    accounting: !!get(item, 'accounting', null)
      ? !!get(item, 'id', null) ? get(item, 'accounting') : JSON.stringify(get(item, 'accounting')) : null,
    variantAttributes: !!get(item, 'variantAttributes', null)
      ? !!get(item, 'id', null) ? get(item, 'variantAttributes') : JSON.stringify(get(item, 'variantAttributes')) : null,
    itemVariants: !!get(item, 'itemVariants', null)
      ? !!get(item, 'id', null) ? get(item, 'itemVariants').map(variant => ({
        id: get(variant, 'id'),
        inventory: get(variant, 'inventory', {}),
        status: !!get(variant, 'status.value', null) ? get(variant, 'status.value') : get(variant, 'status'),
        variantAttributes: get(variant, 'variantAttributes', []),
        customFields: get(variant, 'customFields', []),
      })) : JSON.stringify(get(item, 'itemVariants').map(variant => ({
        inventory: get(variant, 'inventory', {}),
        status: !!get(variant, 'status.value', null) ? get(variant, 'status.value') : get(variant, 'status'),
        variantAttributes: get(variant, 'variantAttributes', []),
        customFields: get(variant, 'customFields', []),
      }))) : null,
    unit: get(item, 'unit', null),
    taxCondition: get(item, 'taxCondition', null),
  }

  Object.keys(newValues).map(key => {
    if (isNull(newValues[key]) || isUndefined(newValues[key]))
      delete newValues[key];
    return null;
  })

  return newValues;
}

export const companyTransformer = company => {
  let newValues = {
    logo: !!get(company, 'logo', null) && startsWith(get(company, 'logo'), 'data:image/png;base64')
      ? get(company, 'logo') : null,
    name: !!get(company, 'name', '') ? JSON.stringify(get(company, 'name')) : null,
    identification: !!get(company, 'identification', '') ? JSON.stringify(get(company, 'identification')) : null,
    identificationObject: !!get(company, 'identificationObject', '') ? JSON.stringify(get(company, 'identificationObject')) : null,
    kindOfPerson: get(company, 'kindOfPerson', null),
    economicActivity: get(company, 'economicActivity', null),
    conditionIibb: get(company, 'conditionIibb', null),
    numberIibb: get(company, 'numberIibb', null),
    activityStartDate: get(company, 'activityStartDate', null),
    regime: get(company, 'regime', null),
    ivaCondition: get(company, 'ivaCondition', null),
    email: get(company, 'email', null),
    phone: get(company, 'phone', null),
    website: get(company, 'website', null),
    decimalPrecision: get(company, 'decimalPrecision', null),
    profile: get(company, 'profile', null),
    countryCode: get(company, 'countryCode', null),
    currency: get(company, 'currency', null),
    sector: get(company, 'sector', null),
    multicurrency: get(company, 'multicurrency', null),
    seenTutorialWizard: get(company, 'seenTutorialWizard', null),
    address: !!get(company, 'address', '') ? JSON.stringify(get(company, 'address')) : null,
    settings: get(company, 'settings', null),
    localSettings: get(company, 'localSettings', null),
    legalRepresentative: get(company, 'legalRepresentative', null),
    mandateSignatureUrl: get(company, 'mandateSignatureUrl', null),
    tradeName: get(company, 'tradeName', null) ? get(company, 'tradeName', null).replace(/['"]+/g, "'") : null,
  }

  Object.keys(newValues).map(key => {
    if (isNull(newValues[key]) || isUndefined(newValues[key]))
      delete newValues[key];
    return null;
  })

  return newValues;
}

export const customFieldTransformer = customField => {
  let result = {
    id: get(customField, 'id', null),
    name: get(customField, 'name', null),
    type: get(customField, 'type', null),
    defaultValue: get(customField, 'defaultValue', null),
    settings: get(customField, 'settings', null),
    status: get(customField, 'status', null),
    description: get(customField, 'description', null),
    value: get(customField, 'value', null),
    resourceType: get(customField, 'resourceType', null),
    options: get(customField, 'options', null),
  }

  if (!result.id)
    delete result.id;

  if (!result.options)
    delete result.options;

  return result;
}

export const itemCategoryTransformer = itemCategory => {
  let result = {
    id: get(itemCategory, 'id', null),
    name: get(itemCategory, 'name', null),
    description: get(itemCategory, 'description', null),
    image: get(itemCategory, 'image', null),
    status: get(itemCategory, 'status', null),
  }

  if (!result.id)
    delete result.id

  return result;
}

export const priceListTransformer = priceList => {
  let result = {
    id: get(priceList, 'id', null),
    name: get(priceList, 'name', null),
    type: get(priceList, 'type', null),
    percentage: get(priceList, 'percentage', null) ? parseFloat(get(priceList, 'percentage')) : undefined,
    status: get(priceList, 'status', undefined),
  }

  if (!result.id)
    delete result.id

  return result;
}

export const variantTransformer = variant => {
  let result = {
    id: get(variant, 'id', null),
    name: get(variant, 'name', null),
    options: get(variant, 'options', null),
    status: get(variant, 'status', null),
  }

  if (!result.id)
    delete result.id

  return result;
}