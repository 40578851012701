import { Table } from "@alegradev/smile-ui-react";
import { useMemo } from "react";
import payInvoiceColumns from "./columns";


const PayInvoiceTable = () => {
    const columns = useMemo(() => payInvoiceColumns(), [])
    const value ={
        number: 'YRH3',
        total:"$105,000.00",
        amount:"$50,000.00",
        creation:"12/07/2022"
    }

    return (
        <div className=" bg-white">
            <Table 
               selectable={true}
               columns={columns}
               data={[value]}
               hideToolbar={true}
               bulkActions={()=>{}}
               hideSearchBar={true} 
               hidePagination={true}
            />
        </div>
    )

}

export default PayInvoiceTable;