import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';

import { NewOtherMethods, OtherMethods } from '../../forms/invoice/Invoice';
import { useTestingCompanies } from '../../../hooks/useShowWompiIntegrationModal/hook';
import { idCompanySelector } from '../../../selectors/company';
import NewModalMethods from '../../forms/invoice/NewModalMethods';

const NewInvoiceMethods = ({ form, total }) => {
  return (
    <>
      <div>
        <div>
          <NewModalMethods form={form} total={total} />
        </div>
      </div>
    </>
  );
};

export default NewInvoiceMethods;
