import React, { useEffect, useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize, forEach, get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux'

import { checkFeatureLimit } from '../../../reducers/membership'
import { openModal } from '../../../reducers/modals'
import { hasPermissionTo } from '../../../selectors/auth'
import { country as countrySelector, companySelector } from '../../../selectors/company'
import { activeSellers } from '../../../selectors/sellers'
import Seller from '../../modals/seller/Seller'
import { renderField, renderSelect } from '../fields/V0/Fields';
import { COUNTRIES } from '../../../utils/enums/countries';
import useElectronicPayrollStatus from '../../../hooks/useElectronicPayrollStatus/hook';
import configAPI from '../../../reducers/configAPI';
import {
  client as clientSelector,
} from '../../../selectors/activeInvoice';
import { MEXICO_DEFAULT_OTHER_RFC, MEXICO_DEFAULT_RFC } from '../../countriesData/mexico/rfc';
import { allRegimes } from '../../countriesData/mexico/regimes';
import { DEFAULT_CFDI_USE } from '../../countriesData/mexico/cfdiUses';
import { isMexicoFeAvailable } from '../../../utils/deploys/mexicoFeDeployGroups';
import { Icon, Tooltip, Typography } from '@alegradev/smile-ui-react';

const AdvancedData = ({ form, values }) => {
  const dispatch = useDispatch()
  const can = useSelector(hasPermissionTo)
  const country = useSelector(countrySelector)
  const company = useSelector(companySelector)
  const sellers = useSelector(activeSellers)
  const { isActive } = useElectronicPayrollStatus()
  const documentType = get(values, 'numeration.documentType', '');
  const client = useSelector(clientSelector);
  const anotationMaxLimit = country === 'republicaDominicana' && get(values, 'anotation.length', 0) > 250;
  const [uses, setUses] = useState([])

  useEffect(() => {
    const loadCfdiUses = async () => {
      if (country !== COUNTRIES.MEXICO) return;
      const response = await configAPI.get('/cfdi-uses', {
        application_version: country,
      });
      setUses(get(response, 'data', []));
    };
    loadCfdiUses();
  }, [client]);

  const isUseDisabled = useMemo(() => {
    if (!client) return true;
    if (
      (client && client?.identification === MEXICO_DEFAULT_RFC) ||
      client?.identification === MEXICO_DEFAULT_OTHER_RFC
    )
      return true;
    return false;
  }, [client]);

  const isFeMexActive = useMemo(() => {
    return isMexicoFeAvailable(country, isActive);
  }, [country, company, isActive])

  const isAnnotationRequired = useMemo(() => {
    return country === COUNTRIES.SPAIN && get(values, 'numeration.isElectronic', false);
  }, [country, values]);

  const useOptions = useMemo(() => {
    const options = [];
    if (country === COUNTRIES.MEXICO) {
      if (client) {
        forEach(client?.regimeObject, (value) => {
          if (allRegimes[value]) {
            forEach(uses, (use) => {
              const splitRegimes = use?.regimes?.split('|');
              if (splitRegimes.includes(`${allRegimes[value].code}`)) {
                options.push({
                  key: use.key,
                  value: use.value,
                });
              }
            });
          }
        });
      } else {
        options.push(DEFAULT_CFDI_USE);
      }
    }

    return options;
  }, [uses, client]);

  return (
    <>
      <div className="form-body h-100 p-4 overflow-hidden">
        <div className="form-body__fields form-row justify-content-start">
          {country === 'peru' && documentType === 'invoice' && (
            <Field
              name="paymentMethod"
              className="col-md-12"
              component={renderSelect}
              options={[
                { value: 'CASH', label: capitalize(I18n.get("paymentForm.Cash", "Contado")) }
              ]}
              label={capitalize(I18n.get("paymentForm", "forma de pago"))}
              // helpTooltip={(
              //   <>
              //     <p className="text-capitalize-first">
              //       {I18n.get('paymentMethodHelp', 'En Alegra POS puedes crear facturas de contado, conoce como generar tus facturas a crédito en Alegra Contabilidad')}
              //       {' '}
              //       <a target="_blank" rel="noreferrer" href="https://ayuda.alegra.com/es/facturaci%C3%B3n-electr%C3%B3nica-%C3%A1gil-en-el-punto-de-venta-pos-per%C3%BA?hs_preview=uIRguPyp-30104023725">
              //         {I18n.get('here', 'aquí')}
              //       </a>
              //     </p>
              //   </>
              // )}
              menuPosition="absolute"
            />
          )}

          {country === COUNTRIES.MEXICO && isFeMexActive && documentType === 'invoice' && (
            <Field
              name="use"
              className="col-md-12"
              component={renderSelect}
              options={useOptions}
              disabled={isUseDisabled}
              required
              label={capitalize(I18n.get('use', 'Uso'))}
              defaultValue={useOptions.length > 1 ? null : useOptions[0]}
              getOptionLabel={option => option.value}
              getOptionValue={option => option.key}
            />
          )}

          <Field
            name="seller"
            className="col-md-12"
            component={renderSelect}
            options={sellers}
            label={capitalize(I18n.get('seller', 'vendedor'))}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            addOptionText={can('add', 'sellers')
              ? capitalize(I18n.get('newSeller', 'Nuevo vendedor')) : null}
            addOptionAction={() => dispatch(checkFeatureLimit('sellers', can('add', 'sellers') ? () => dispatch(openModal({ modal: 'seller' })) : null))}
            menuPosition="absolute"
          />

          <div className="anotation_label col-md-12">
            <div className="d-flex justify-content-between">
              <p className="m-0 d-flex">
                {isAnnotationRequired ? I18n.get('operationDescription', 'Descripción de la operación') : capitalize(I18n.get('annotations', 'observaciones'))}
                {(isAnnotationRequired) && (
                  <>
                    <span className='d-flex ml-1 text-primary'>
                      *
                      <Tooltip
                        overlay={
                          <div className='d-flex'>
                            <Typography text='Agrega una descripción general de tu venta, puedes aprender'
                              variant='inverse'
                              type='caption-bold'
                            />
                            <a href='www.google.com' target="_blank" rel="noopener noreferrer">
                              <Typography
                                text="cómo definir una por defecto."
                                type='caption-bold'
                                variant='success'
                              />
                            </a>
                          </div>
                        }
                      >
                        <Icon
                          icon='help'
                          type="primary"
                          size='small'
                          extraClass='ml-2'
                        />
                      </Tooltip>
                    </span>
                  </>
                )}
              </p>
              {(country === 'republicaDominicana') && (
                <p className={`m-0 ${get(values, 'anotation.length', 0) > 250 ? 'text-danger' : ''}`}>
                  {get(values, 'anotation.length', 0)}/250
                </p>
              )}
            </div>
          </div>
          <Field
            name="anotation"
            className={`col-md-12 ${anotationMaxLimit ? 'has-error' : ''}`}
            component={renderField}
            type="textarea"
          />
        </div>
      </div>

      <Seller onCreated={seller => form.change('seller', seller)} />
    </>
  )
}

export default AdvancedData;