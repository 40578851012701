import React from 'react'
import { I18n } from '@aws-amplify/core';

const Bottom = ({ onConfirm }) => {
  return (
    <div className="modal__bottom h-100">
      <button
        type="button"
        className="w-100 btn btn-submit text-btn-normal text-capitalize-first"
        onClick={() => onConfirm()}
      >
        {I18n.get("", "Seguir explorando")}
      </button>
    </div>
  )
}

export default Bottom;