import { useDispatch, useSelector } from "react-redux";
import { station as stationSelector, stationInvoiceNumeration, APIGraphqlSelector } from "../../selectors/app";
import { useTicketBaiInformation } from "../useTicketBaiElegible";
import { stations as stationsSelector } from "../../selectors/station";
import { getElectronicNumerations } from "../../selectors/numerations";
import { useEffect } from "react";
import { stationTransformer } from "../../utils/transformers";
import * as mutations from '../../graphql/mutations';
import { updateStation } from "../../reducers/app";
import { graphqlOperation } from "aws-amplify";


const useElectronicNumerationAutomaticChange = () => {
  const dispatch = useDispatch();
  const currentNumeration = useSelector(stationInvoiceNumeration);
  const electronicNumerations = useSelector(getElectronicNumerations);
  const stations = useSelector(stationsSelector);
  const currentStation = useSelector(stationSelector);
  const { isTicketBaiEnabled } = useTicketBaiInformation();
  const APIGraphql = useSelector(APIGraphqlSelector);

  const simplifiedInvoiceNumeration = electronicNumerations.find(numeration => numeration.documentType === 'saleTicket');

  useEffect(() => {
    if (!currentStation)
      return;

    if (!currentNumeration && isTicketBaiEnabled && simplifiedInvoiceNumeration) {
      (async () => {
        for (const s of stations) {
          try {
            const response = await APIGraphql(graphqlOperation(mutations.updateStation, {
              station: stationTransformer({ ...s, idNumberTemplate: parseInt(simplifiedInvoiceNumeration?.id) })
            }))

            const station = get(response, 'data.updateStation', null);
            if (currentStation.id === station.id) {
              dispatch(updateStation(station));
            }
          } catch (error) {
            console.error('Error updating station numeration:', error);
          }
        }
      })();
    }
  }, [currentNumeration, isTicketBaiEnabled, simplifiedInvoiceNumeration, stations, currentStation, dispatch]);


  return { currentNumeration };
};

export default useElectronicNumerationAutomaticChange;