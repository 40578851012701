import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux'

import { country as countrySelector } from '../../../../selectors/company'
import { renderCheckbox } from '../../fields/V0/Fields';
import { hasPermissionTo } from '../../../../selectors/auth';

const RenderSaleTickets = ({ values }) => {
  const can = useSelector(hasPermissionTo);
  const country = useSelector(countrySelector)

  return country === 'colombia' ? (
    <>
      <Field
        name="settings.showSaleTickets"
        className="col-12"
        component={renderCheckbox}
        label={I18n.get('saleTickets', 'documentos equivalentes')}
        disabled={!can('edit', 'company')}
        help={
          <>
            {I18n.get('saleTicketsFieldHelp', 'Crea documentos equivalentes P.O.S')}{' '}
            <a className="text-capitalize-first btn-link" href="https://ayuda.alegra.com/es/genera-tus-documentos-en-tu-cuenta-de-alegra-colombia" target="_blank" rel="noreferrer">
              {capitalize(I18n.get('seeMore', 'ver más'))}
            </a>
          </>
        }
        switchLabel=""
      />
    </>
  ) : null
}

export default RenderSaleTickets;