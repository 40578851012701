import React, { useState } from 'react';
import { useDetectOS } from '../../../../hooks/useDetectOS';
import { Button } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import posDesktopAPI from '../../../../reducers/posDesktopAPI';

const DownloadAppButton = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const os = useDetectOS();

  const handleDownload = async () => {
    setLoading(true);
    setError(null);

    if (!os) {
      setError('Unsupported operating system');
      setLoading(false);
      return;
    }

    try {
      const response = await posDesktopAPI.get(`/get-presigned-url?os=${os}`);

      const data = response?.data;

      const { url } = data;
      window.location.href = url;
    } catch (error) {
      setError('Error fetching presigned URL');
      console.error('Error fetching presigned URL:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button
        text={I18n.get('downloadApp', 'Descargar App')}
        onClick={handleDownload}
        loading={loading}
        type='button'
      />

      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default DownloadAppButton;
