import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { get} from 'lodash'

import { closeModal, openModal } from '../../../reducers/modals';
import Modal from '../../common/Modal';
import Bottom from './Bottom';
import { replaceAndParse } from '../../../utils';
import { renderBasicCheckbox } from '../../forms/fields/V1/Fields';
import { isAdmin as isAdminSelector} from '../../../selectors/auth';
import { companySelector } from '../../../selectors/company';
import { graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations'
import { setUser } from '../../../reducers/auth';
import { APIGraphqlSelector, stationFENumberTemplate } from '../../../selectors/app';
import { getColombiaUVT } from '../../../utils/enums/colombiaUVT';

import { Icon } from '@alegradev/smile-ui-react';

const Reminder5UVT = () => {
  const dispatch = useDispatch();
  // const isOpen = useSelector(state => get(state, 'modals.reminder5UVT.isOpen', false));
  const isOpen = false;
  const isAdmin = useSelector(isAdminSelector);
  const company = useSelector(companySelector);
  const colombiaUVT = getColombiaUVT();
  const feNumeration = useSelector(stationFENumberTemplate);
  const hasFEactive = get(company, 'settings.electronicInvoicing', false);
  const [isChecked, setIsChecked] = useState(false);
  const APIGraphql = useSelector(APIGraphqlSelector);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const onChangeShowReminder = async (value) => {
    try {
      const response = await APIGraphql(graphqlOperation(mutations.updateUser, {
        user: {
          company: {
            localSettings: {
              showReminder5UVT: value
            }
          }
        }
      }))
      if (!!get(response, 'data.updateUser.id', null))
        dispatch(setUser(get(response, 'data.updateUser')))
      else 
        throw new Error("error");
    } catch (error) {
      console.log(error);
    }
  }
  
  const handleClose = () => {
    if (isChecked)
      onChangeShowReminder(false);
    dispatch(closeModal({ modal: 'reminder5UVT' }));
  }

  const handleConfirm = () => {
    if (isChecked)
      onChangeShowReminder(false);

    dispatch(closeModal({ modal: 'reminder5UVT' }));
    if (isAdmin) {
      if (hasFEactive && !!feNumeration) {
        dispatch(openModal({modal: 'activate5UVT'}));
      }
      else {
        window.open("https://ayuda.alegra.com/es/configura-sistema-pos-para-ventas-superiores-5-uvt-colombia", "_blank"); 
      }
    }
    else {
      window.open("https://ayuda.alegra.com/es/configura-sistema-pos-para-ventas-superiores-5-uvt-colombia", "_blank"); 
    } 
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="modal__activate-5uvt"
      bordelessHeader={true}
    >
      <div className='d-flex justify-content-center'>
        <div className="d-flex flex-column p-3 justify-content-center align-items-center mb-4 w-70">
          <Icon icon='alert-triangle' extraClass="medium-icon"/>
          <div className="d-flex align-items-center mt-4 mb-4">
            <p className="h3 font-weight-bold text-center">
              {I18n.get('reminder5UVT.title', '¡Ten en cuenta la nueva regulación!')}
            </p>
          </div>

          <div className="d-flex align-items-center">
            <p className="h4 text-subtitle text-center">
              {replaceAndParse(I18n.get('reminder5UVT.description', `Desde el 1 de abril del 2023 las ventas que superen $${colombiaUVT?.UVT_FORMATED} antes de IVA <span class='font-weight-bold'>deben ir en factura electrónica.</span>`), [])}
            </p>
          </div>

          {isAdmin &&
            <div className='d-flex mt-3'>
              <a href='https://ayuda.alegra.com/es/configura-sistema-pos-para-ventas-superiores-5-uvt-colombia' rel="noreferrer" target="_blank" className='h4 mb-0 mr-2 font-weight-bold' style={{lineHeight: 1.5}}>{I18n.get('reminder5UVT.more', 'Saber más de la regulación')}</a> 
              <Icon icon='arrow-right' extraClass="icon-primary2" width={20} />
            </div>
          }

          <div className="d-flex align-items-center mt-5">
            {renderBasicCheckbox({
              input: {
                checked: isChecked,
                onChange: handleCheckboxChange
              },
              label: I18n.get('reminder5UVT.checkbox', 'No volver a mostrar este mensaje')
            })}
          </div>
        </div>
      </div>
      <Bottom 
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
    </Modal>
  )
}

export default Reminder5UVT;