import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Field } from 'react-final-form';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux';

import { hasPermissionTo } from '../../../selectors/auth';
import { isOnlyInvoicingPlan } from '../../../selectors/company';
import { activeBanks } from '../../../selectors/banks';
import { renderSelect } from '../fields/V0/Fields';
import { Tooltip } from '@alegradev/smile-ui-react';

const Shift = () => {
  const can = useSelector(hasPermissionTo);
  const banks = useSelector(activeBanks);
  const onlyInvoicing = useSelector(isOnlyInvoicingPlan);

  return (
    <div className='form-body__fields form-row justify-content-start'>
      <div className='col-md-6'>
        <Tooltip
          width='full'
          visible={!can('edit', 'pos-station')}
          overlay={I18n.get(
            'userNotAllowed.station.edit',
            'Te hace falta el permiso para editar terminales'
          )}
        >
          <Field
            name='baseBank'
            className='col-md-12 p-0'
            component={renderSelect}
            disabled={onlyInvoicing || !can('edit', 'pos-station')}
            onlyInvoicingTooltip={onlyInvoicing}
            label={capitalize(I18n.get('baseBank', 'banco de apertura'))}
            options={
              !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
                ? []
                : banks
            }
            selectInfo={
              !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
                ? I18n.get(
                    'userNotAllowed.bank-accounts.view',
                    'no tienes permisos para ver la información de las cuentas de bancos'
                  )
                : null
            }
            hideNoOptionMessage={
              !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
            }
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            menuPosition='absolute'
            required
          />
        </Tooltip>
      </div>

      <div className='col-md-6'>
        <Tooltip
          width='full'
          visible={!can('edit', 'pos-station')}
          overlay={I18n.get(
            'userNotAllowed.station.edit',
            'Te hace falta el permiso para editar terminales'
          )}
        >
          <Field
            name='closeBank'
            className='col-md-12 p-0'
            component={renderSelect}
            disabled={onlyInvoicing || !can('edit', 'pos-station')}
            onlyInvoicingTooltip={onlyInvoicing}
            label={capitalize(I18n.get('closeBank', 'banco de cierre'))}
            options={
              !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
                ? []
                : banks
            }
            selectInfo={
              !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
                ? I18n.get(
                    'userNotAllowed.bank-accounts.view',
                    'no tienes permisos para ver la información de las cuentas de bancos'
                  )
                : null
            }
            hideNoOptionMessage={
              !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
            }
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            menuPosition='absolute'
            required
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default Shift;
