import React from "react";

import { Grid } from "@alegradev/smile-ui-react";
import { Field } from "react-final-form";
import { renderField } from "../../forms/fields/V2";

import spainAddresses from "../../../components/countriesData/spain/municipalityProvince.json"
import { I18n } from "aws-amplify";
import { capitalize } from "lodash";

const spainAddressSimplified = (props) => {

  return (
    <>
      <Field
        name="address.combined"
        className="col-12"
        component={renderField}
        fieldType='select'
        options={spainAddresses.map((item) => ({
          text: item.value,
          value: item.value
        }))}
        placeholder={I18n.get('select', 'Seleccionar')}
        label={[
          capitalize(I18n.get('city', 'Municipio')),
          capitalize(I18n.get('province', 'Provincia'))
        ].join(' / ')}
        search
        required
      />

      <Field
        name='address.address'
        className='col-12'
        component={renderField}
        type='text'
        label={capitalize(I18n.get('address', 'Dirección'))}
        required
      />
    </>
  )
}

const FormBody = props => {
  return (
    <>
      <div className="w-100 d-flex flex-column px-2 pt-2 pb-0">
        <div className={`section-collapse form-body w-100 p-3 overflow-hidden`}>
          {spainAddressSimplified(props)}
        </div>
      </div>
    </>
  );
};

export default FormBody;