import { createSlice } from '@reduxjs/toolkit';
import { I18n } from '@aws-amplify/core';
import { get, set, has, isNumber, isEmpty } from 'lodash';
import { BigNumber } from 'bignumber.js';
import dayjs from 'dayjs'

import {
  country as countrySelector,
  timezone as tzSelector,
  decimalPrecision,
  electronicInvoicing as companyElectronicInvoicing,
  isActiveNoIvaDay,
} from '../selectors/company'
import {
  station as stationSelector,
  electronicInvoicing,
  stationInvoiceNumeration
} from '../selectors/app';
import { getMainCurrency } from '../selectors/currencies';
import { isCashReceiptNumerationActive } from '../selectors/numerations';
import { total as totalSelector, discSubtotal as subtotalSelector } from '../selectors/editInvoice';
import { getIVA0Tax } from '../selectors/taxes';
import { put, updateNumerations } from '../database/invoicesDB'
import { toast, replaceAndParse } from '../utils'
import { handleError } from '../utils/errors'
import {
  syncOffline,
  calculatePayments,
  calcultateSaleConcept,
  checkClient,
  validateInvoice,
  prepareNumeration,
} from './activeInvoice'
import { updateInvoice, updateOfflineInvoices } from './invoices'
import { setPrint } from './print'
import { COUNTRIES } from '../utils/enums/countries';

const initialState = {
  items: [],
  originalItems: [],
  itemsMap: {},
  client: null,
  priceList: null,
  currency: null,
}

const findIndex = (items = [], id) => items
  .findIndex(item => get(item, 'id') === id && !get(item, 'modified'));

const updateItemPrice = (item, priceList, currency) => {
  let price = get(item, 'originalPrice', 0)

  if (!get(item, 'priceModified') && !!priceList) {
    const itemPriceList = !!get(item, 'priceLists.length')
      ? get(item, 'priceLists').find(list => +get(list, 'idPriceList') === +get(priceList, 'id'))
      : null
    if (!!itemPriceList)
      price = get(itemPriceList, 'price');
  }

  if (!!currency) {
    const exchangeRate = !!get(currency, 'exchangeRate') ? +get(currency, 'exchangeRate') : 1;
    price = new BigNumber(price).dividedBy(new BigNumber(exchangeRate)).decimalPlaces(4).toNumber()
  }

  return { ...item, price }
}

const changeItemNoIvaField = (item, active, quantity) => {
  return (_disaptch, getState) => {
    const taxIVA0 = getIVA0Tax(getState())

    let hasIVATaxes = false
    let itemTax = null
    if (!!get(item, 'tax') && !!get(item, 'tax.length')) {
      hasIVATaxes = !!get(item, 'tax').filter(t => get(t, 'type') === 'IVA')

      itemTax = get(item, 'tax').map(t => get(t, 'type') === 'IVA'
        ? ({ ...t, noIVA: active })
        : t)

      if (hasIVATaxes && active && !!taxIVA0) {
        itemTax = itemTax.filter(t => get(t, 'type') !== 'IVA')
        itemTax.push(taxIVA0)
      }
    }

    return {
      ...item,
      quantity,
      tax: !!get(item, 'tax') && !!get(item, 'tax.length')
        ? itemTax
        : get(item, 'tax')
    }
  }
}

const changeItemsNoIVADay = () => {
  return (dispatch, getState) => {
    let items = get(getState(), 'editInvoice.originalItems')

    let noIVACategories = {}
    let restItems = []
    let itemsMap = {}

    let finalItems = items.map((item, index) => {
      itemsMap[index] = index

      if (!!get(item, 'hasNoIvaDays')) {
        if (!!get(item, 'noIvaDaysCategory')) {
          const itemQuantity = +get(item, 'quantity', 0)
          const totalNoIvaQuantity = noIVACategories[get(item, 'noIvaDaysCategory')] || 0

          const noIvaQuantity = Math.min(itemQuantity, Math.max(3 - totalNoIvaQuantity, 0))
          const ivaQuantity = itemQuantity - noIvaQuantity

          noIVACategories[get(item, 'noIvaDaysCategory')] = totalNoIvaQuantity + itemQuantity

          if (noIvaQuantity > 0) {
            if (ivaQuantity > 0) {
              itemsMap[items.length + restItems.length] = index
              restItems.push(dispatch(changeItemNoIvaField(item, false, ivaQuantity)))
            }

            return dispatch(changeItemNoIvaField(item, true, noIvaQuantity))
          }

          return dispatch(changeItemNoIvaField(item, false, itemQuantity))
        }

        return dispatch(changeItemNoIvaField(item, true, get(item, 'quantity')))
      }

      return item
    })

    finalItems = finalItems.concat(restItems)

    dispatch(setItemsMap(itemsMap))
    dispatch(setItems(finalItems))
  }
}

const showNoIvaDaysInfo = () => {
  toast.warning({
    subtitle: (
      <p className="text-left h3 text-capitalize-first">
        {I18n.get('noIVADayItemsLimitWarning', 'Ya este cliente adquirió las 3 unidades de esta categoría que se encuentran exentas y en la próxima se le cobrará el IVA')}
      </p>
    ),
  })
}

const showNoIvaDaysInfoInEdit = () => {
  toast.warning({
    subtitle: (
      <p className="text-left h3 text-capitalize-first">
        {I18n.get('noIVADayItemsLimitWarningInEdit1', 'Te recomendamos cobrar este producto con IVA, porque ya seleccionaste las')}
        {' '}
        <b>{I18n.get('noIVADayItemsLimitWarningInEdit2', '3 unidades exentas')}</b>
        {' '}
        {I18n.get('noIVADayItemsLimitWarningInEdit3', 'que pertenecen a esta categoría')}
      </p>
    ),
  })
}

export const setClient = (client) => {
  return async (dispatch, getState) => {
    const isOldClientIdNIT = get(getState(), 'editInvoice.client.identificationObject.type') === 'NIT'
    const isNewClientIdNIT = get(client, 'identificationObject.type') === 'NIT'
    const items = get(getState(), 'editInvoice.items')
    const originalItems = get(getState(), 'editInvoice.originalItems')
    await dispatch(_setClient(client))

    const isIVADay = isActiveNoIvaDay(getState())

    if (isIVADay) {
      if (isOldClientIdNIT !== isNewClientIdNIT) {
        if (isOldClientIdNIT) {
          dispatch(changeItemsNoIVADay())

          const newItems = get(getState(), 'editInvoice.items')
          const newOriginalItems = get(getState(), 'editInvoice.originalItems')

          if (get(originalItems, 'length') === get(newOriginalItems, 'length')
            && get(items, 'length') !== get(newItems, 'length'))
            showNoIvaDaysInfo()
        }
        else
          dispatch(setItems(originalItems))
      }
    }
  }
}

export const setNumeration = (numeration) => {
  return async (dispatch, getState) => {
    await dispatch(_setNumeration(numeration))
  }
}

export const singleItemReachedMinQuantityFromValues = (item, subitemQuantity, kitId, quantity) => {
  return async (_, getState) => {
    const warehouseInventory = get(get(item, 'inventory.warehouses', [])
      .filter(w => Number(w.id) === get(getState(), 'app.station.idWarehouse', null)),
      '0', {});


      const currentQuantity = get(getState(), 'editInvoice.items', [])
        .filter((i) => i.id === get(item, 'id', null))
        .reduce((acc, curr) => (acc += Number(get(curr, 'quantity', 0))), 0);

    const currentFormQuantity = !!quantity ? quantity : get(item, 'quantity', 0);

    const currentQuantityInKits = (fromKit) => {
      const kits = get(getState(), 'editInvoice.items', []).map(i => {
        if (get(i, 'type') === 'kit' && get(i, 'id') !== (fromKit ? kitId : null)) {
          return { quantity: get(i, 'quantity'), kitQuantity: get(get(i, 'subitems', []).filter(j => get(j, 'item.id') === get(item, 'id')), '0.quantity', 0) }
        }
        return null;
      })
      return (kits.reduce((acc, curr) => acc += (get(curr, 'quantity', 0) * get(curr, 'kitQuantity', 0)), 0))
    }

    const availableQuantity = get(warehouseInventory, 'availableQuantity', null);
    const negativeSale = get(item, 'inventory.negativeSale', true);

    if (subitemQuantity)
      return (!negativeSale && availableQuantity !== null && (availableQuantity - ((+currentFormQuantity * +subitemQuantity) + currentQuantityInKits(true) + currentQuantity)) < 0)

    return (!negativeSale && availableQuantity !== null && availableQuantity - (currentQuantityInKits() + +currentFormQuantity) < 0);
  }
}

const singleItemReachedMinQuantity = (item, quantity) => {
  return async (_, getState) => {
    const warehouseInventory = get(get(item, 'inventory.warehouses', [])
      .filter(w => Number(w.id) === get(getState(), 'app.station.idWarehouse', null)),
      '0', {});

    const currentQuantity = get(get(getState(), 'editInvoice.items', [])
      .filter(i => i.id === (get(item, 'id', null))),
      '0.quantity', 0);

    const currentQuantityInKits = () => {
      const kits = get(getState(), 'editInvoice.items', []).map(i => {
        if (get(i, 'type') === 'kit')
          return { quantity: get(i, 'quantity'), kitQuantity: get(get(i, 'subitems', []).filter(j => get(j, 'item.id') === get(item, 'id')), '0.quantity') }
        return null;
      })
      return (kits.reduce((acc, curr) => acc += (get(curr, 'quantity', 0) * get(curr, 'kitQuantity', 0)), 0))
    }

    const availableQuantity = get(warehouseInventory, 'availableQuantity', null);
    const negativeSale = get(item, 'inventory.negativeSale', true);

    if (quantity)
      return (!negativeSale && availableQuantity !== null && (availableQuantity - ((+currentQuantity + currentQuantityInKits() + +quantity)) < 0))

    return (!negativeSale && availableQuantity !== null && availableQuantity - (+currentQuantity + 1 + currentQuantityInKits()) < 0);
  }
}

const currentQuantityInKits = (item, kitId) => {
  return async (_, getState) => {
    const currentNoKitQuantity = get(get(getState(), 'editInvoice.items', [])
      .filter(i => i.id === (get(item, 'id', null))),
      '0.quantity', 0);
    const kits = get(getState(), 'editInvoice.items', []).map(i => {
      if (get(i, 'type') === 'kit' && get(i, 'id') !== (kitId ? kitId : null))
        return { quantity: get(i, 'quantity'), kitQuantity: get(get(i, 'subitems', []).filter(j => get(j, 'item.id') === get(item, 'id')), '0.quantity') }
      return null;
    })
    return (kits.reduce((acc, curr) => acc += (get(curr, 'quantity', 0) * get(curr, 'kitQuantity', 0)), 0)) + +currentNoKitQuantity
  }
}

export const itemReachedMinQuantity = (item, comparator) => {
  return async (dispatch, _) => {
    comparator = comparator || singleItemReachedMinQuantity;

    if (get(item, 'type', 'product') !== 'kit')
      return await dispatch(comparator(item));
    else {
      const subitems = get(item, 'subitems', []);
      const subitemsAvailability = await Promise.all(subitems.map(async (i) => {
        return await dispatch(comparator(i?.item, i?.quantity, item?.id, item?.quantity));
      }));
      return !isEmpty(subitemsAvailability.filter(i => i === true))
    }
  }
}

export const addItem = (item) => {
  return async (dispatch, getState) => {
    if (await dispatch(itemReachedMinQuantity(item))) {
      switch (get(item, 'type', 'product')) {
        case 'product':
          toast.warning({
            title: I18n.get('itemLimitWarningTitle', 'Ya vendiste todas las unidades. 🏁'),
            subtitle: I18n.get('itemLimitWarningSubtitle', 'Revisa si tienes una compra pendiente por registrar o edita este producto y activa la opción de ventas en negativo'),
          })
          break
        case 'kit':
          toast.warning({
            title: I18n.get('itemLimitWarningTitle.kit', 'Revisa los productos del combo 🔍'),
            subtitle: replaceAndParse(I18n.get('itemLimitWarningSubtitle.kit', 'El Combo "{}" no se puede vender porque tiene productos sin unidades disponibles.'), [`<span class="font-weight-bold">${get(item, 'name', '')}</span>`]),
          })
          break;
        default:
          toast.warning({
            title: I18n.get('itemLimitWarningTitle', 'Ya vendiste todas las unidades. 🏁'),
            subtitle: I18n.get('itemLimitWarningSubtitle', 'Revisa si tienes una compra pendiente por registrar o edita este producto y activa la opción de ventas en negativo'),
          })
          break
      }
    }
    else {
      await dispatch(_addItem(item))
      const isClientIdNIT = get(getState(), 'editInvoice.client.identificationObject.type') === 'NIT'
      const isIVADay = isActiveNoIvaDay(getState())
      const items = get(getState(), 'editInvoice.items')
      const originalItems = get(getState(), 'editInvoice.originalItems')

      if (isIVADay && !isClientIdNIT) {
        dispatch(changeItemsNoIVADay())

        const newItems = get(getState(), 'editInvoice.items')
        const newOriginalItems = get(getState(), 'editInvoice.originalItems')

        if (get(originalItems, 'length') === get(newOriginalItems, 'length')
          && get(items, 'length') !== get(newItems, 'length'))
          showNoIvaDaysInfo()
      }
    }
  }
}

export const getKitMaximumQuantity = (item) => {
  return async (dispatch, _) => {
    const subitems = get(item, 'subitems');
    const noNegativeSaleSubitems = subitems.filter(i => get(i, 'item.inventory.negativeSale') === false);
    const currentQuantity = await Promise.all(noNegativeSaleSubitems.map(async (i) => {
      return await dispatch(currentQuantityInKits(i.item, get(item, 'id')))
    }));
    const maxQuantity = Math.floor(Math.min(...noNegativeSaleSubitems.map((i, index) => {
      return (+get(i, 'item.inventory.availableQuantity') - currentQuantity[index]) / +get(i, 'quantity', 1)
    })));
    return maxQuantity >= 0 ? maxQuantity : 0;
  }
}

export const increaseItem = (index) => {
  return async (dispatch, getState) => {
    const item = get(getState(), `editInvoice.items.${index}`, {});

    if (await dispatch(itemReachedMinQuantity(item))) {
      switch (get(item, 'type', 'product')) {
        case 'product':
          toast.warning({
            title: I18n.get('itemLimitWarningTitle', 'Ya vendiste todas las unidades. 🏁'),
            subtitle: I18n.get('itemLimitWarningSubtitle', 'Revisa si tienes una compra pendiente por registrar o edita este producto y activa la opción de ventas en negativo'),
          })
          break
        case 'kit':
          toast.warning({
            title: replaceAndParse(I18n.get('itemLimitWarningTitle.kitAvailable', '¡Puedes vender máximo {}! ✋'), [`${get(item, 'quantity')} ${get(item, 'quantity') > 1 ? I18n.get('kits', 'combosT') : I18n.get('kit', 'combo')}`]),
            subtitle: replaceAndParse(I18n.get('itemLimitWarningSubtitle.kitAvailable', 'Ten en cuenta que tu Combo "{}" contiene productos con ese número de unidades disponibles.'), [`<span class="font-weight-bold">${get(item, 'name', '')}</span>`]),
          })
          break;
        default:
          toast.warning({
            title: I18n.get('itemLimitWarningTitle', 'Ya vendiste todas las unidades. 🏁'),
            subtitle: I18n.get('itemLimitWarningSubtitle', 'Revisa si tienes una compra pendiente por registrar o edita este producto y activa la opción de ventas en negativo'),
          })
          break
      }
    }
    else {
      await dispatch(_increaseItem(index))
      const isClientIdNIT = get(getState(), 'editInvoice.client.identificationObject.type') === 'NIT'
      const isIVADay = isActiveNoIvaDay(getState())
      const items = get(getState(), 'editInvoice.items')
      const originalItems = get(getState(), 'editInvoice.originalItems')

      if (isIVADay && !isClientIdNIT) {
        dispatch(changeItemsNoIVADay())

        const newItems = get(getState(), 'editInvoice.items')
        const newOriginalItems = get(getState(), 'editInvoice.originalItems')

        if (get(originalItems, 'length') === get(newOriginalItems, 'length')
          && get(items, 'length') !== get(newItems, 'length'))
          showNoIvaDaysInfo()
      }
    }
  }
}

export const decreaseItem = (index) => {
  return async (dispatch, getState) => {
    await dispatch(_decreaseItem(index))
    const isClientIdNIT = get(getState(), 'editInvoice.client.identificationObject.type') === 'NIT'
    const isIVADay = isActiveNoIvaDay(getState())

    if (isIVADay && !isClientIdNIT)
      dispatch(changeItemsNoIVADay())
  }
}

export const removeItem = (index) => {
  return async (dispatch, getState) => {
    await dispatch(_removeItem(index))
    const isClientIdNIT = get(getState(), 'editInvoice.client.identificationObject.type') === 'NIT'
    const isIVADay = isActiveNoIvaDay(getState())

    if (isIVADay && !isClientIdNIT)
      dispatch(changeItemsNoIVADay())
  }
}

export const updateItem = (params) => {
  return async (dispatch, getState) => {
    await dispatch(_updateItem(params))
    const isClientIdNIT = get(getState(), 'editInvoice.client.identificationObject.type') === 'NIT'
    const isIVADay = isActiveNoIvaDay(getState())
    const items = get(getState(), 'editInvoice.items')
    const originalItems = get(getState(), 'editInvoice.originalItems')

    if (isIVADay && !isClientIdNIT) {
      const item = get(getState(), `editInvoice.items.${params.index}`)
      if (!!get(item, 'hasNoIvaDays') && get(getState(), `editInvoice.itemsMap.${params.index}`) !== params.index
        && (!get(item, 'tax.length') || (get(item, 'tax.length') === 1 && +get(item, 'tax.0.percentage') === 0)))
        showNoIvaDaysInfoInEdit()

      dispatch(changeItemsNoIVADay())

      const newItems = get(getState(), 'editInvoice.items')
      const newOriginalItems = get(getState(), 'editInvoice.originalItems')

      if (get(originalItems, 'length') === get(newOriginalItems, 'length')
        && get(items, 'length') !== get(newItems, 'length'))
        showNoIvaDaysInfo()
    }
  }
}

export const updateItemByEvent = item => {
  return (dispatch, getState) => {
    const items = get(getState(), 'editInvoice.items')
    const priceList = get(getState(), 'editInvoice.priceList')
    const currency = get(getState(), 'editInvoice.currency')

    const index = findIndex(items, item.id);

    if (index >= 0)
      dispatch(updateItem({
        index, values: updateItemPrice({
          ...item,
          originalPrice: get(item, 'price.0.price', 0),
          price: get(item, 'price.0.price', 0),
          priceLists: get(item, 'price'),
        }, priceList, currency)
      }))
  }
}

export const removeItemByEvent = id => {
  return (dispatch, getState) => {
    const items = get(getState(), 'editInvoice.items')

    const index = findIndex(items, id);

    if (index >= 0)
      dispatch(removeItem(index))
  }
}

export const updateClientByEvent = client => {
  return (dispatch, getState) => {
    const currentClientId = get(getState(), 'editInvoice.client.id')

    if (get(client, 'id') === currentClientId)
      dispatch(setClient(client))
  }
}

export const removeClientByEvent = id => {
  return (dispatch, getState) => {
    const currentClientId = get(getState(), 'editInvoice.client.id')

    if (id === currentClientId)
      dispatch(setClient(null))
  }
}

export const updateNumerationByEvent = numeration => {
  return (dispatch, getState) => {
    const currentNumerationId = get(getState(), 'activeInvoice.numeration.id')

    if (get(numeration, 'id') === currentNumerationId) {
      dispatch(setNumeration(numeration))
    }
  }
}

export const removeNumerationByEvent = id => {
  return (dispatch, getState) => {
    const currentNumerationId = get(getState(), 'activeInvoice.numeration.id')

    if (id === currentNumerationId)
      dispatch(setNumeration(null))
  }
}

const appSlice = createSlice({
  name: 'editInvoice',
  initialState,
  reducers: {
    setInvoice: (_state, action) => {
      return action.payload
    },
    _setClient: (state, action) => {
      state.client = action.payload;
    },
    _setNumeration: (state, action) => {
      return {
        ...state,
        numeration: action.payload,
      }
    },
    setSettings: (state, action) => {
      return {
        ...state,
        ...action.payload,
        items: state.items.map(item => updateItemPrice(item, action.payload.priceList, action.payload.currency)),
        originalItems: state.originalItems.map(item => updateItemPrice(item, action.payload.priceList, action.payload.currency)),
      }
    },
    setItems: (state, action) => {
      state.items = action.payload
    },
    setItemsMap: (state, action) => {
      state.itemsMap = action.payload
    },
    _addItem: (state, action) => {
      const index = findIndex(state.items, action.payload.id);
      const itemsMap = get(state, 'itemsMap')

      const quantity = index >= 0 ? get(state, `items.${index}.quantity`, 0) : 0;
      const originalQuantity = index >= 0 ? get(state, `originalItems.${get(itemsMap, `${index}`, 0)}.quantity`, 0) : 0;

      if (index < 0) {
        state.items.push(updateItemPrice({
          ...action.payload,
          originalPrice: get(action, 'payload.price.0.price', 0),
          price: get(action, 'payload.price.0.price', 0),
          priceLists: get(action, 'payload.price'),
          quantity: quantity + 1
        }, state.priceList, state.currency));

        state.originalItems.push(updateItemPrice({
          ...action.payload,
          originalPrice: get(action, 'payload.price.0.price', 0),
          price: get(action, 'payload.price.0.price', 0),
          priceLists: get(action, 'payload.price'),
          quantity: quantity + 1
        }, state.priceList, state.currency));
      } else {
        set(state, `items.${index}.quantity`, quantity + 1)
        set(state, `originalItems.${get(itemsMap, `${index}`, 0)}.quantity`, originalQuantity + 1)
      }
    },
    _removeItem: (state, action) => {
      const deletedItem = state.items.splice(action.payload, 1)[0];
      const deletedQuantity = get(deletedItem, 'quantity')
      const itemsMap = get(state, 'itemsMap')

      const originalItem = get(state, `originalItems.${get(itemsMap, `${action.payload}`, 0)}`)
      const originalQuantity = get(originalItem, 'quantity', 0)

      if (get(originalItem, 'quantity') === deletedQuantity)
        state.originalItems.splice(get(itemsMap, `${action.payload}`, 0), 1)
      else
        set(state, `originalItems.${get(itemsMap, `${action.payload}`, 0)}.quantity`, originalQuantity - deletedQuantity)
    },
    _increaseItem: (state, action) => {
      const itemsMap = get(state, 'itemsMap')

      const quantity = +get(state, `items.${action.payload}.quantity`);
      const originalQuantity = +get(state, `originalItems.${get(itemsMap, `${action.payload}`, 0)}.quantity`);

      set(state, `items.${action.payload}.quantity`, quantity + 1)
      set(state, `originalItems.${get(itemsMap, `${action.payload}`, 0)}.quantity`, originalQuantity + 1)
    },
    _decreaseItem: (state, action) => {
      const itemsMap = get(state, 'itemsMap')

      const quantity = +get(state, `items.${action.payload}.quantity`);
      const originalQuantity = +get(state, `originalItems.${get(itemsMap, `${action.payload}`, 0)}.quantity`);

      if (quantity > 1) {
        set(state, `items.${action.payload}.quantity`, quantity - 1)
        set(state, `originalItems.${get(itemsMap, `${action.payload}`, 0)}.quantity`, originalQuantity - 1)
      }
    },
    _updateItem: (state, action) => {
      const itemsMap = get(state, 'itemsMap')
      const index = action.payload.index
      const originalIndex = get(itemsMap, `${index}`, 0)

      if (has(action, 'payload.values.quantity') && isNumber(action.payload.values.quantity))
        action.payload.values.quantity = action.payload.values.quantity.toFixed(2);

      set(state, `originalItems.${originalIndex}`, {
        ...get(state, `originalItems.${originalIndex}`),
        ...action.payload.values,
        quantity: has(action, 'payload.values.quantity')
          ? +get(state, `originalItems.${originalIndex}.quantity`) - +get(state, `items.${index}.quantity`) + +get(action, 'payload.values.quantity')
          : +get(state, `originalItems.${originalIndex}.quantity`)
      })

      set(state, `items.${index}`, {
        ...get(state, `items.${index}`),
        ...action.payload.values
      })
    },
    clear: (state) => {
      return {
        ...state,
        ...initialState
      }
    },
    reset: () => {
      return initialState
    }
  },
});

const { actions, reducer } = appSlice;

export const {
  setInvoice,
  _setClient,
  _setNumeration,
  setSettings,
  setItems,
  setItemsMap,
  _addItem,
  _removeItem,
  _increaseItem,
  _decreaseItem,
  _updateItem,
  clear,
  reset
} = actions;

export default reducer;

const prepareItemsNoIvaDay = () => {
  return (_dispatch, getState) => {
    const isClientIdNIT = get(getState(), 'editInvoice.client.identificationObject.type') === 'NIT'
    const isIVADay = isActiveNoIvaDay(getState())
    const items = get(getState(), 'editInvoice.items')
    const taxIVA0 = getIVA0Tax(getState())

    if (isIVADay && !isClientIdNIT) {
      if (!taxIVA0) {
        toast.error({
          subtitle: (
            <p className="text-left h4 text-capitalize-first">
              {I18n.get('noTaxIva0Erro', 'Asegúrate de tener creado el impuesto IVA del 0% para la venta de productos que están cubiertos en tu día sin IVA.')}
              {' '}
              <a href="https://bit.ly/3BorNji" target="_blank" rel="noreferrer">
                {I18n.get('seeMore', 'Ver más')}
              </a>
            </p>
          )
        })
        // eslint-disable-next-line
        throw ''
      }

      return items.map(item => {
        let hasIVATaxes = false
        let itemTax = null
        if (!!get(item, 'tax') && !!get(item, 'tax.length')) {
          hasIVATaxes = !!get(item, 'tax').filter(t => !!t.noIVA)
          const hasIVA0 = !!get(item, 'tax').filter(t => get(t, "type") === "IVA" && +get(t, "percentage") === 0)

          itemTax = get(item, 'tax').filter(t => !t.noIVA)

          if (hasIVATaxes && get(itemTax, 'length') !== get(item, 'tax.length') && !hasIVA0)
            itemTax.push(taxIVA0)
        }

        return {
          ...item,
          tax: !!get(item, 'tax') && !!get(item, 'tax.length')
            ? itemTax
            : get(item, 'tax')
        }
      })
    }

    return items
  }
}

const prepareInvoice = ({ payments, seller, anotation, banks, externalPayment, idPaymentMethodLocal }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const total = totalSelector(state);
    const subtotal = subtotalSelector(state);
    const station = stationSelector(state)
    const country = countrySelector(state)
    const mainCurrency = getMainCurrency(state)
    const isCompanyElectronic = companyElectronicInvoicing(state)
    const today = dayjs(new Date(), tzSelector(state))
    const decimal = decimalPrecision(state)
    let currency = get(state, 'editInvoice.currency')
    const numberTemplate = !!get(state, 'editInvoice.numeration')
      ? get(state, 'editInvoice.numeration') : stationInvoiceNumeration(state)
    const isElectronic = electronicInvoicing(numberTemplate)(state)
    const activeCashReceipt = isCashReceiptNumerationActive(state)
    const cashReceiptNumberTemplate = !!activeCashReceipt
      ? { id: get(station, 'idCashReceiptNumberTemplate') } : null

    if (get(currency, 'code', '') === get(mainCurrency, 'code', ''))
      currency = null;

    let invoice = {
      ...state.editInvoice,
      numberTemplate: await prepareNumeration(numberTemplate, get(state, 'editInvoice.timestamp')),
      externalPaymentId: externalPayment.id,
      externalPaymentProvider: externalPayment.provider,
      anotation,
      seller,
      currency,
      costCenter: get(station, 'idCostCenter', get(state.editInvoice, 'costCenter', null)),
      ...calculatePayments({ payments, banks, today, total, subtotal, country, decimal, cashReceiptNumberTemplate, currency }),
      items: dispatch(prepareItemsNoIvaDay()),
    }

    if (country !== 'colombia') {
      delete invoice.type
      delete invoice.operationType
      delete invoice.paymentTerm
      delete invoice.deliveryTerm
    }

    if (country === COUNTRIES.ARGENTINA) {
      invoice = {
        ...invoice,
        idPaymentMethodLocal,
      };
    } else if (country === COUNTRIES.PANAMA) {
      invoice = {
        ...invoice,
        idPaymentMethodLocal,
      };
    } else if (country === COUNTRIES.INTERNATIONAL) {
      invoice = {
        ...invoice,
        idPaymentMethodLocal,
      };
    } else if (country === COUNTRIES.SPAIN) {
      invoice = {
        ...invoice,
        idPaymentMethodLocal,
        paymentMethod:
          get(payments, 'cash', 0) > 0
            ? 'cash'
            : get(payments, 'credit', 0) > 0
              ? 'credit-card'
              : get(payments, 'debit', 0) > 0
                ? 'debit-card'
                : get(payments, 'transfer', 0) > 0
                  ? 'transfer'
                  : 'cash',
      };
    } else {
      invoice = {
        ...invoice,
        idPaymentMethodLocal,
      };
    }

    if (country === 'costaRica') {
      invoice = {
        ...invoice,
        saleCondition: Object.values(payments).reduce((a, b) => a + b) >= invoice.total ? '01' : '02',
        type: 'NATIONAL',
        stamp: {
          generateStamp: !!isElectronic && !!numberTemplate ? !!numberTemplate.isElectronic : false
        }
      }
    } else if (country === 'peru') {
      invoice = {
        ...invoice,
        operationType: 'INTERNAL_SALE',
        paymentMethod: 'CASH',
        idPaymentMethodLocal,
        stamp: {
          generateStamp: !!isElectronic && !!numberTemplate ? !!numberTemplate.isElectronic : false
        }
      }
    } else if (country === 'colombia') {
      if (isElectronic) {
        invoice = {
          ...invoice,
          paymentForm: invoice.totalReceived > 0 ? 'CASH' : 'CREDIT',
          stamp: {
            generateStamp: !!numberTemplate ? !!numberTemplate.isElectronic : false
          },
          dueDate: !!get(invoice, 'paymentTerm.days')
            ? today.add(+get(invoice, 'paymentTerm.days'), 'day').format('YYYY-MM-DD') : invoice.dueDate
        }
      }

      if (get(numberTemplate, 'documentType') === 'saleTicket') {
        invoice = {
          ...invoice,
          paymentForm: invoice.totalReceived > 0 ? 'CASH' : 'CREDIT',
        }
      }
    } else if (country === 'argentina' && isCompanyElectronic) {
      let saleCondition = 'CASH'
      switch (invoice.paymentMethod) {
        case 'debit-card':
          saleCondition = 'DEBIT_CARD'
          break;
        case 'credit-card':
          saleCondition = 'CREDIT_CARD'
          break;
        case 'transfer':
          saleCondition = 'TRANSFER'
          break;
        default:
          break;
      }
      let saleConcept = calcultateSaleConcept(invoice);
      const dateService = saleConcept === 'PRODUCTS_SERVICES' || saleConcept === 'SERVICES';
      invoice = {
        ...invoice,
        saleCondition,
        saleConcept,
        startDateService: dateService ? today.format('YYYY-MM-DD') : null,
        endDateService: dateService ? today.format('YYYY-MM-DD') : null,
        stamp: {
          generateStamp: !!numberTemplate ? !!numberTemplate.isElectronic : false
        },
      }
    } else if (country === 'panama') {
      if (isElectronic) {

        let saleCondition = 'CASH'
        switch (invoice.paymentMethod) {
          case 'debit-card':
            saleCondition = 'DEBIT_CARD'
            break;
          case 'credit-card':
            saleCondition = 'CREDIT_CARD'
            break;
          default:
            break;
        }
        invoice = {
          ...invoice,
          paymentForm: invoice.totalReceived > 0 ? 'CASH' : 'CREDIT',
          stamp: {
            generateStamp: true,
          },
          saleCondition,
          type: 'INTERNAL_OPERATION',
          operationType: 'SALE',
          saleType: 'ORDER',
          originApp: 'POS'
        }
      }
    }
    return invoice;
  }
}

export const changeInvoice = props => {
  return async (dispatch, getState) => {

    let invoice = await dispatch(prepareInvoice(props));

    invoice = await dispatch(checkClient(invoice))
    dispatch(validateInvoice(invoice))

    try {
      await put(invoice, () => dispatch(syncOffline()))
      dispatch(updateInvoice({
        id: invoice.id,
        changes: { ...invoice }
      }))
      dispatch(setPrint({ type: 'invoice', value: invoice }))

      if (get(invoice, 'numberTemplate.id') !== get(getState(), 'editInvoice.numberTemplate.id')) {
        await updateNumerations()
        dispatch(updateOfflineInvoices())
      }
    } catch (error) {
      throw handleError(error)
    }
  }
}