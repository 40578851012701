import React from 'react'
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux'

import { printType, preInvoiceToPrint, invoiceToPrint, shiftToPrint, refundToPrint, paymentToPrint } from '../../selectors/print'
import PrintInvoice from './invoice/Print'
import PrintShift from './shift/Print'
import PrintRefund from './refund';
import PrintPayment from './payment/Print';

const PrintChild = (props) => {
  const type = useSelector(printType)
  const preInvoice = useSelector(preInvoiceToPrint)
  const invoice = useSelector(invoiceToPrint)
  const shift = useSelector(shiftToPrint)
  const refund = useSelector(refundToPrint)
  const payment = useSelector(paymentToPrint)

  if (!!props.preview)
    return (<PrintInvoice {...props} />)

  switch (type) {
    case 'preInvoice':
      return (<PrintInvoice invoice={preInvoice} pendingInvoice />)

    case 'invoice':
      return (<PrintInvoice invoice={invoice} />)

    case 'shift':
      return (<PrintShift shift={shift} />)

    case 'refund':
      return (<PrintRefund refund={refund} />)
    
    case 'payment':
      return (<PrintPayment payment={payment} />)

    case 'provisionalInvoice':
      return (<PrintInvoice invoice={invoice} provisional />)

    default:
      return null
  }
}

const Print = (props) => {
  const print = document.getElementById('print');
  return createPortal(PrintChild(props), print)
}

export default Print