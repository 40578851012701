import { I18n } from '@aws-amplify/core';
import { get } from 'lodash';

export const validate = (values, { cashReceiptActive, refundActive }) => {
  let errors = {};
  
  if (!get(values, 'name', null))
    errors.name = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
  
  if (!get(values, 'numberTemplate.id', null))
    errors.numberTemplate = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
  
  if (refundActive) {
    if (!get(values, 'refundNumberTemplate.id', null))
      errors.refundNumberTemplate = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
  }

  if (cashReceiptActive) {
    if (!get(values, 'receiptNumberTemplate.id', null))
      errors.receiptNumberTemplate = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
  }
  
  if (!get(values, 'warehouse.id', null))
    errors.warehouse = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
  
  if (!get(values, 'debit.id', null))
    errors.debit = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')

  if (!get(values, 'credit.id', null))
    errors.credit = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')

  if (!get(values, 'transfer.id', null))
    errors.transfer = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')

  if (!get(values, 'baseBank.id', null))
    errors.baseBank = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')

  if (!get(values, 'closeBank.id', null))
    errors.closeBank = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')

  return errors;
}

export const transform = values => {
  return {
    name: get(values, 'name', null),
    idNumberTemplate: get(values, 'numberTemplate.id', null),
    idInvoiceNumberTemplate: get(values, 'invoiceNumberTemplate.id', null),
    idCashReceiptNumberTemplate: get(values, 'receiptNumberTemplate.id', null),
    idRefundNumberTemplate: get(values, 'refundNumberTemplate.id', null),
    idFENumberTemplate: get(values, 'feNumberTemplate.id', null),
    idWarehouse: get(values, 'warehouse.id', null),
    idCredit: get(values, 'credit.id', null),
    idDebit: get(values, 'debit.id', null),
    idTransfer: get(values, 'transfer.id', null),
    baseBank: get(values, 'baseBank.id', null),
    closeBank: get(values, 'closeBank.id', null),
    idCostCenter: get(values, 'costCenter.id', null),
    seller: get(values, 'seller.id', null),
    pendingInvoicesEnabled: get(values, 'pendingInvoicesEnabled', null),
    pendingInvoicesPrefix: get(values, 'pendingInvoicesPrefix', null),
    userIds: get(values, 'users', []).map(user => user.idGlobal).filter(id => id !== -1),
    idPriceList: get(values, 'priceList.id', null),
    address: get(values, 'address.address', null),
    combinedAddress: get(values, 'address.combined.value', null),
    phone: get(values, 'phone', null),
    email: get(values, 'email', null),
    tradeName: get(values, 'tradeName', null),
  }
}