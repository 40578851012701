import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../common/Modal';
import { capitalize, get, isEqual, isNull } from 'lodash';
import { closeModal } from '../../../reducers/modals';
import { Field, Form } from 'react-final-form';
import { renderField } from '../../forms/fields/V0/Fields';
import { I18n } from 'aws-amplify';
import { useEffect, useRef, useState } from 'react';
import UploadArrow from '../../svg/UploadArrow';
import QRLogo from '../../svg/QRLogo';
import parse from 'html-react-parser';
import Bottom from '../common/Bottom';
import { useDeviceType } from '../../../hooks/useDeviceType';
import { createPaymentMethodMutation } from '../../settings/PaymentMethods/services/mutations';
import { APIGraphqlSelector } from '../../../selectors/app';
import { validate } from '../../settings/PaymentMethods/utils';
import { Icon, Typography } from '@alegradev/smile-ui-react';
import { allPaymentMethods } from '../../../selectors/paymentMethods';
import Notification from '../common/Notification';

const PaymentMethod = (values) => {
  const deviceType = useDeviceType();
  const [src, setSrc] = useState('');
  const [srcQR, setSrcQR] = useState('');
  const imageQRInputRef = useRef();
  const imageInputRef = useRef();
  const [imageError, setImageError] = useState(false);
  const [previousImage, setPreviousImage] = useState('');
  const [previousImageQR, setPreviousImageQR] = useState('');
  const paymentMethods = useSelector(allPaymentMethods);
  const [error, setError] = useState(false);

  const dispatch = useDispatch();
  const isOpen = useSelector((state) =>
    get(state, 'modals.paymentMethod.isOpen', false)
  );
  const APIGraphql = useSelector(APIGraphqlSelector);
  const {
    mutate: mutateCreation,
    isLoading,
    isSuccess,
  } = createPaymentMethodMutation();

  useEffect(() => {
    if (isOpen) {
      if (!isNull(get(values, 'imageSrc', ''))) {
        setSrc(values.imageSrc);
      } else {
        setSrc('');
      }
      if (!isNull(get(values, 'imageQR', ''))) {
        setSrcQR(values.imageQR);
      } else {
        setSrcQR('');
      }
    } else {
      if (imageInputRef.current) {
        imageInputRef.current.value = '';
      }
      if (imageQRInputRef.current) {
        imageQRInputRef.current.value = '';
      }
    }
  }, [isOpen, values]);

  const handleClose = () => {
    setError(false);
    setSrc('');
    setPreviousImage('');
    setSrcQR('');
    setPreviousImageQR('');
    imageInputRef.current.value = '';
    imageQRInputRef.current.value = '';
    dispatch(closeModal({ modal: 'paymentMethod' }));
  };

  const handleSubmit = (values) => {
    mutateCreation({
      values: values,
      api: APIGraphql,
    });
  };

  const getModalTitle = () => {
    return (
      <div className='d-flex flex-column mr-5'>
        <Typography
          type='heading-4'
          text={I18n.get('newPaymentMethodTitle', 'Nuevo método de pago')}
        />
        <Typography
          type='body-3-regular'
          text={I18n.get(
            'newPaymentMethodSubtitle',
            'Incluye una cuenta bancaria u otro método de pago que podrán usar tus clientes'
          )}
          variant='tertiary'
        />
      </div>
    );
  };

  return (
    <Modal
      title={getModalTitle()}
      isOpen={isOpen}
      onRequestClose={handleClose}
      className='m-4'
    >
      <div className='add-payment-method__main_container add-payment-method'>
        <Form
          onSubmit={handleSubmit}
          initialValues={{ imageSrc: '', imageQR: '' }}
          validate={(values) => validate(values, paymentMethods)}
          render={({ handleSubmit, form, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              <div
                className={
                  deviceType === 'mobile'
                    ? 'col-sm-12 form-body  add-payment-method__form-container-mobile'
                    : 'form-body add-payment-method__form-container'
                }
              >
                <div
                  className={
                    deviceType === 'mobile' ? 'col-sm-12 ' : 'col-md-4'
                  }
                >
                  <Field name='image'>
                    {(props) => (
                      <div
                        className={
                          deviceType !== 'mobile'
                            ? 'add-payment-method__image_container'
                            : 'add-payment-method__image_container-mobile'
                        }
                      >
                        {!!src ? (
                          <img
                            onClick={() => imageInputRef.current.click()}
                            src={src}
                            alt={I18n.get(
                              'paymentMethodImage',
                              'imagen de método de pago'
                            )}
                            className='w-100 h-100 pointer'
                          />
                        ) : (
                          <div
                            onClick={() => imageInputRef.current.click()}
                            className='d-flex w-100 h-100 flex-column justify-content-center align-items-center text-center px-2 gap-3 pointer'
                          >
                            <Icon
                              icon={imageError ? 'photo-off' : 'photo'}
                              size='large'
                              color='#94A3B8'
                            />
                            <Typography
                              type='body-3-regular'
                              text={
                                imageError
                                  ? I18n.get(
                                      'paymentMethod.imageError',
                                      'El formato de tu archivo debe ser .jpg, .jpeg o .png'
                                    )
                                  : I18n.get(
                                      'paymentMethodUploadImage',
                                      'Sube una imagen para que identifiques este método'
                                    )
                              }
                              variant={imageError ? 'error' : 'tertiary'}
                            />
                          </div>
                        )}
                        <input
                          {...props.input}
                          type='file'
                          accept='.jpeg, .jpg, .png'
                          onChange={(e) => {
                            let file = e.target.files[0];

                            if (file === undefined) {
                              e.target.files = previousImage;
                            }
                            if (file) {
                              const allowedExtensions =
                                /(\.jpeg|\.jpg|\.png)$/i;

                              if (!allowedExtensions.exec(file?.name)) {
                                setImageError(true);
                                e.target.value = '';
                                return;
                              }
                              setPreviousImage(e.target.files);
                              const reader = new FileReader();
                              reader.readAsDataURL(file);
                              reader.onload = (ev) => {
                                setSrc(ev.target.result);
                                form.change('imageSrc', ev.target.result);
                              };
                              props.input.onChange(e);
                            }
                          }}
                          ref={imageInputRef}
                          className='w-100 h-100 d-none'
                        />
                      </div>
                    )}
                  </Field>
                </div>
                <div
                  className={
                    deviceType === 'mobile' ? 'col-sm-12 p-1' : 'w-100'
                  }
                >
                  <Field
                    name='name'
                    className='col-12'
                    component={renderField}
                    placeholder={I18n.get(
                      'paymentMethodNamePlaceholder',
                      'Agrega un nombre para identificar el método'
                    )}
                    type='text'
                    label={
                      <Typography
                        type='body-3-regular'
                        text={capitalize(
                          I18n.get(
                            'paymentMethodName',
                            'Nombre del método de pago'
                          )
                        )}
                        variant='primary'
                      />
                    }
                    required
                  />
                  <Field
                    name='instructions'
                    className='col-12'
                    component={renderField}
                    type='textarea'
                    placeholder={I18n.get(
                      'instructions.paymentMethod.placeholder'
                    )}
                    label={
                      <Typography
                        type='body-3-regular'
                        text={capitalize(
                          I18n.get(
                            'paymentInstructions',
                            'Indicaciones de pago'
                          )
                        )}
                        variant='primary'
                      />
                    }
                    required
                  />
                </div>
              </div>
              {deviceType !== 'mobile' && <hr className='mr-5 ml-4 mt-3' />}
              {deviceType === 'mobile' && (
                <div className='col-sm-12 d-flex justify-content-start add-payment-method__QR-text ml-5'>
                  <Typography
                    type='body-3-bold'
                    text={I18n.get(
                      'QRPaymentMethodTitle',
                      'Adjunta un código QR'
                    )}
                  />
                </div>
              )}
              <div
                className={
                  deviceType === 'mobile'
                    ? 'add-payment-method__QR-container-mobile'
                    : 'd-flex add-payment-method__QR-container mb-3'
                }
              >
                <div className='col-md-8 d-flex flex-column justify-content-start add-payment-method__QR-text'>
                  {deviceType !== 'mobile' && (
                    <Typography
                      type='body-3-bold'
                      text={I18n.get(
                        'QRPaymentMethodTitle',
                        'Adjunta un código QR'
                      )}
                    />
                  )}
                  <Typography
                    type='body-3-regular'
                    text={I18n.get(
                      'QRPaymentMethodDesciption',
                      'Tus clientes podrán escanearlo en tu pantalla para pagarte en el punto de venta'
                    )}
                  />
                </div>
                <div className='col-md-4 d-flex justify-content-start'>
                  <Field name='imageQRInput'>
                    {(props) => (
                      <div
                        className={
                          deviceType === 'mobile'
                            ? 'add-payment-method__image_QR-mobile'
                            : 'add-payment-method__image_QR'
                        }
                      >
                        {!!srcQR ? (
                          <img
                            onClick={() => imageQRInputRef.current.click()}
                            src={srcQR}
                            alt={I18n.get(
                              'paymentMethodImage',
                              'imagen de método de pago'
                            )}
                            className='w-100 h-100 pointer'
                          />
                        ) : (
                          <div
                            onClick={() => imageQRInputRef.current.click()}
                            className='d-flex w-100 h-100 flex-column justify-content-center align-items-center pointer'
                          >
                            <QRLogo className='icon' />
                          </div>
                        )}

                        <input
                          {...props.input}
                          type='file'
                          accept='.jpeg, .jpg, .png'
                          onChange={(e) => {
                            let file = e.target.files[0];

                            if (file === undefined) {
                              e.target.files = previousImageQR;
                            }
                            if (file) {
                              const reader = new FileReader();
                              reader.readAsDataURL(file);
                              setPreviousImageQR(e.target.files);
                              reader.onload = (ev) => {
                                setSrcQR(ev.target.result);
                                form.change('imageQR', ev.target.result);
                              };
                              props.input.onChange(e);
                            }
                          }}
                          ref={imageQRInputRef}
                          className='w-100 h-100 d-none'
                        />
                      </div>
                    )}
                  </Field>
                </div>
              </div>

              <Notification
                isOpen={error}
                type='error'
                text={'Ya existe un método de pago con este nombre'}
              />

              <div>
                <Bottom
                  submitting={isLoading}
                  disabled={
                    get(values, 'name', '') === '' ||
                    get(values, 'instructions', '') === ''
                  }
                  tooltipVisible={
                    get(values, 'name', '') === '' ||
                    get(values, 'instructions', '') === ''
                  }
                  tooltipText='Para guardar este método personalizado debes completar el </br> nombre y las indicaciones de pago.'
                  onClose={() => {
                    form.reset();
                    handleClose();
                  }}
                  buttonsCenter={deviceType === 'mobile' ? true : false}
                  hideRequired={deviceType === 'mobile' ? true : false}
                  requiredFieldsText={parse(
                    I18n.get(
                      'fieldsMarkedRequired',
                      "Los campos marcados con <span class='text-primary'>*</span> son obligatorios"
                    )
                  )}
                  submitText={I18n.get(
                    'savePaymenthMethod',
                    'Guardar método de pago'
                  )}
                />
              </div>
            </form>
          )}
        />
      </div>
    </Modal>
  );
};

export default PaymentMethod;
