import { I18n } from '@aws-amplify/core';
import { get, capitalize, isNull, isUndefined, replace } from 'lodash';
import dayjs from 'dayjs'
import { COUNTRIES, COUNTRIES_NAMES } from '../../../utils/enums/countries';

export const validatePhoneByCountry = ({
  countryKey,
  phoneNumber,
  values = {},
}) => {
  switch (countryKey) {
    case 'COL':
    case 'MEX':
    case 'DOM':
    case 'ARG':
      if (!/^\d{10}$/.test(phoneNumber)) {
        return replace(
          I18n.get('onbPhoneMessageValidationError', ''),
          '{}',
          10
        );
      }
      break;
    case 'CRI':
      if (!/^\d{8}$/.test(phoneNumber)) {
        return replace(I18n.get('onbPhoneMessageValidationError', ''), '{}', 8);
      }
      break;
    case 'PAN':
      if (!/^\d{7}$/.test(phoneNumber) && !/^\d{8}$/.test(phoneNumber)) {
        return replace(
          I18n.get('onbPhoneMessageValidationError', ''),
          '{}',
          '7 u 8'
        );
      }
      break;
    case 'ESP':
    case 'PER':
      if (!/^\d{9}$/.test(phoneNumber)) {
        return replace(I18n.get('onbPhoneMessageValidationError', ''), '{}', 9);
      }
      break;
    default:
      if (values?.phone) {
        if (values?.phone.split('.')[1] === '') {
          return capitalize(
            I18n.get('enterAPhone', 'Ingresa un número de teléfono')
          );
        }
      }
      break;
  }
};
export const validateOnboarding = (values, country) => {
  let errors = {};

  if (!get(values, 'name', ''))
    errors.name = capitalize(I18n.get('enterAName', 'Ingresa un nombre'));

  if (!get(values, 'sector.key', ''))
    errors.sector = capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));

  if (!get(values, 'phone', '') && !['argentina', 'costaRica'].includes(country)) {
    errors.phone = capitalize(I18n.get('enterAPhone', 'Ingresa un número de teléfono'));
  }
  if (
    get(values, 'phone', '') &&
    !['argentina', 'costaRica'].includes(country)
  ) {
    const countryKey = get(values, 'phoneCountry.key', '');
    const phoneNumber = get(values, 'phone', '').split('.')[1];

    const validationError = validatePhoneByCountry({ countryKey, phoneNumber, values });

    if (validationError) {
      errors.phone = validationError;
    }
  }

  if (country === 'argentina') {
    if (!get(values, 'ivaCondition.key', ''))
      errors.ivaCondition = capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));
  }

  if (country === 'mexico' || country === 'republicaDominicana'
    || country === 'peru' || country === 'chile'
    || country === 'costaRica' || country === 'colombia') {
    if (!get(values, 'regime.value', ''))
      errors.regime = capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));
  }

  if (country === COUNTRIES.SPAIN) {
    if (!get(values, 'province.key', '')) {
      errors.province = capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));
    }
  }

  return errors;
}

const formatPhoneNumber = (values) => {
  const countryKey = get(values, 'phoneCountry.key', '');
  if (!get(values, 'phone')) {
    return null;
  }

  const parts = values.phone.split('.');
  if (!parts[1]) {
    return null;
  }

  const countryCode = parts[0];
  const phoneNumber = parts[1];

  if (countryKey === 'DOM') {
    return `${countryCode}${phoneNumber.substring(0, 3)}-${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6)}`;
  }

  if (countryKey === 'PAN') {
    if (phoneNumber.length === 7) {
      return `${countryCode}-${phoneNumber.substring(0, 3)}-${phoneNumber.substring(3)}`;
    } else if (phoneNumber.length === 8) {
      return `${countryCode}-${phoneNumber.substring(0, 4)}-${phoneNumber.substring(4)}`;
    }
  }

  return values.phone.split('.').join('');
}

export const transformOnboarding = (values, country) => {
  let transformedValues = {
    name: get(values, 'name'),
    phone: formatPhoneNumber(values),
    sector: get(values, 'sector.value'),
  }

  if (country === 'argentina') {
    transformedValues = {
      ...transformedValues,
      ivaCondition: get(values, 'ivaCondition.key'),
    }
  }

  if (country === COUNTRIES.SPAIN) {
    transformedValues = {
      ...transformedValues,
      address: {
        province: get(values, 'province.value'),
      },
    };
  }

  if (country === 'mexico' || country === 'republicaDominicana'
    || country === 'peru' || country === 'chile'
    || country === 'costaRica' || country === 'colombia') {
    transformedValues = {
      ...transformedValues,
      regime: get(values, 'regime.value'),
    }
  }

  return transformedValues
}

const commomErrors = (values) => {
  let errors = {};

  const name = get(values, 'firstName', '');
  const email = get(values, 'email', '');
  const decimalPrecision = get(values, 'decimalPrecision.key', '');

  if (!name)
    errors.firstName = I18n.get('enterAName', 'Ingresa un nombre');

  if (!!email && !/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(email))
    errors.email = I18n.get('enterAValidEmail', 'Ingresa un correo válido');

  if (!name)
    errors.firstName = I18n.get('enterAName', 'Ingresa un nombre');

  if (!decimalPrecision)
    errors.decimalPrecision = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio');

  return errors;
}

const colombiaErrors = (values, { isElectronic }) => {
  let errors = {};

  const identificationType = get(values, 'identification.type.key', '');
  const identificationNumber = get(values, 'identification.number', '');
  const lastName = get(values, 'lastName', '');
  const kindOfPerson = get(values, 'kindOfPerson.key', '');
  const completeName = (identificationType === 'NIT' && kindOfPerson === 'PERSON_ENTITY')
    || (!!isElectronic && identificationType !== 'NIT');

  if (!identificationType)
    errors.identification = { ...errors.identification, type: I18n.get('selectTheIdentificationType', 'seleccione el tipo de identificación') };

  if (!identificationNumber && identificationType !== 'CC')
    errors.identification = { ...errors.identification, number: I18n.get('enterAnID', 'Ingresa una identificación') };

  if (identificationType !== 'FOREIGN_NIT' && kindOfPerson === 'PERSON_ENTITY' && !lastName)
    errors.lastName = I18n.get('requiredField', 'Este campo es obligatorio');

  if (identificationType === 'NIT' && kindOfPerson === 'PERSON_ENTITY' && !identificationNumber)
    errors.identification = { ...errors.identification, number: I18n.get('enterAnID', 'Ingresa una identificación') };

  if (!lastName && completeName)
    errors.lastName = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio');

  if (isElectronic) {
    const foreignId = identificationType === 'DIE' || identificationType === 'PP' || identificationType === 'TE' || identificationType === 'FOREIGN_NIT';
    const showCountry = !!identificationType && foreignId;

    const country = get(values, 'address.country.key', '');
    const combinedAddress = get(values, 'address.combined.key', '');
    const address = get(values, 'address.address', '');
    const regime = get(values, 'regime.key', '');

    if (!kindOfPerson && identificationType === 'NIT')
      errors.kindOfPerson = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio');
    if (!regime && identificationType === 'NIT')
      errors.regime = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio');

    if (!country && showCountry)
      errors.address = { ...errors.address, country: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio') };
    if (!combinedAddress && country === 'COL')
      errors.address = { ...errors.address, combined: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio') };
    if (!address)
      errors.address = { ...errors.address, address: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio') };
  }

  return errors;
}

const costaRicaErrors = (values, { isElectronic }) => {
  let errors = {};

  const regime = get(values, 'regime.key', '');
  const combined = get(values, 'address.combined.key', '');
  const address = get(values, 'address.address', '');
  if (!regime)
    errors.regime = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio');

  const identificationType = get(values, 'identification.type.key', '');
  const identificationNumber = get(values, 'identification.number', '');

  if (!identificationType)
    errors.identification = { ...errors.identification, type: I18n.get('selectTheIdentificationType', 'seleccione el tipo de identificación') };

  if (identificationType === 'CF' && !!identificationNumber && identificationNumber.length < 9)
    errors.identification = { ...errors.identification, number: I18n.get('theMinimumSizeForThisFieldIs', 'El tamaño minimo para este campo es de') + ' 9.' };
  if (identificationType === 'CF' && !!identificationNumber && identificationNumber.length > 9)
    errors.identification = { ...errors.identification, number: I18n.get('theMaximumSizeForThisFieldIs', 'El tamaño maximo para este campo es de') + ' 9.' };

  if (identificationType === 'CJ' && !/^[^-]+$/.test(identificationNumber))
    errors.identification = { ...errors.identification, number: I18n.get('theLegalIDMustBeWithoutHyphens', 'La cédula jurídica debe estar sin guiones.') };
  if (identificationType === 'CJ' && !!identificationNumber && identificationNumber.length < 10)
    errors.identification = { ...errors.identification, number: I18n.get('theMinimumSizeForThisFieldIs', 'El tamaño minimo para este campo es de') + ' 10.' };
  if (identificationType === 'CJ' && !!identificationNumber && identificationNumber.length > 10)
    errors.identification = { ...errors.identification, number: I18n.get('theMaximumSizeForThisFieldIs', 'El tamaño maximo para este campo es de') + ' 10.' };

  if (identificationType === 'DIMEX' && !/^[1-9]+[0-9]*$/.test(identificationNumber))
    errors.identification = { ...errors.identification, number: I18n.get('theDIMEXIsANumericalValue', 'El DIMEX es un valor numérico, sin ceros al inicio y sin guiones.') };
  if (identificationType === 'DIMEX' && !!identificationNumber && identificationNumber.length < 11)
    errors.identification = { ...errors.identification, number: I18n.get('theMinimumSizeForThisFieldIs', 'El tamaño minimo para este campo es de') + ' 11.' };
  if (identificationType === 'DIMEX' && !!identificationNumber && identificationNumber.length > 12)
    errors.identification = { ...errors.identification, number: I18n.get('theMaximumSizeForThisFieldIs', 'El tamaño maximo para este campo es de') + ' 12.' };

  if (identificationType === 'NITE' && !/^[^-]+$/.test(identificationNumber))
    errors.identification = { ...errors.identification, number: I18n.get('theNITEMustBeWithoutHyphens', 'El NITE debe estar sin guiones.') };
  if (identificationType === 'NITE' && !!identificationNumber && identificationNumber.length < 10)
    errors.identification = { ...errors.identification, number: I18n.get('theMinimumSizeForThisFieldIs', 'El tamaño minimo para este campo es de') + ' 10.' };
  if (identificationType === 'NITE' && !!identificationNumber && identificationNumber.length > 10)
    errors.identification = { ...errors.identification, number: I18n.get('theMaximumSizeForThisFieldIs', 'El tamaño maximo para este campo es de') + ' 10.' };

  if (identificationType === 'PE' && !!identificationNumber && identificationNumber.length > 20)
    errors.identification = { ...errors.identification, number: I18n.get('theMaximumSizeForThisFieldIs', 'El tamaño maximo para este campo es de') + ' 20.' };

  if (!identificationNumber)
    errors.identification = { ...errors.identification, number: I18n.get('enterAnID', 'Ingresa una identificación') };


  if (!combined && isElectronic)
    errors.address = { ...errors.address, combined: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio') };

  if (!address && isElectronic)
    errors.address = { ...errors.address, address: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio') };

  return errors;
}

const argentinaErrors = (values, { isElectronic }) => {
  let errors = {};

  const identificationType = get(values, 'identification.type.key', '');
  const identificationNumber = get(values, 'identification.number', '');
  const ivaCondition = get(values, 'ivaCondition.key', '');
  const conditionIibb = get(values, 'conditionIibb.key', '');
  const numberIibb = get(values, 'numberIibb', '');
  const activityStartDate = get(values, 'activityStartDate', '');

  if (!identificationType)
    errors.identification = { ...errors.identification, type: I18n.get('selectTheIdentificationType', 'seleccione el tipo de identificación') };

  if (identificationType === 'CUIT' && !/^[0-9]+[0-9\\-]+[0-9]+$/.test(identificationNumber))
    errors.identification = { ...errors.identification, number: I18n.get('CUITIdentificationIsANumericalValue', 'El CUIT debe ser un valor numérico, puede tener guiones') };

  if (!identificationNumber)
    errors.identification = { ...errors.identification, number: I18n.get('enterAnID', 'Ingresa una identificación') };

  if (!ivaCondition)
    errors.ivaCondition = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio');

  if (!conditionIibb)
    errors.conditionIibb = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio');

  if (!numberIibb)
    errors.numberIibb = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio');

  if (!activityStartDate)
    errors.activityStartDate = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio');

  if (isElectronic) {
    const province = get(values, 'address.province.value', '');
    const city = get(values, 'address.city.value', '');
    const address = get(values, 'address.address', '');

    if (!province)
      errors.address = { ...errors.address, province: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio') };
    if (!city)
      errors.address = { ...errors.address, city: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio') };
    if (!address)
      errors.address = { ...errors.address, address: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio') };
  }

  return errors;
}

const peruErrors = (values) => {
  let errors = {};

  const identificationNumber = get(values, 'identification', '');

  if (!!identificationNumber && !!identificationNumber && identificationNumber.length < 11)
    errors.identification = I18n.get('theMinimumSizeForThisFieldIs', 'El tamaño minimo para este campo es de') + ' 11.';
  if (!!identificationNumber && !!identificationNumber && identificationNumber.length > 11)
    errors.identification = I18n.get('theMaximumSizeForThisFieldIs', 'El tamaño maximo para este campo es de') + ' 11.';

  if (!identificationNumber)
    errors.identification = I18n.get('enterAnID', 'Ingresa una identificación');

  return errors;
}

const mexicoErrors = (values, { isElectronic }) => {
  let errors = {};

  const regime = get(values, 'regime.key', '');
  const zipCode = get(values, 'address.zipCode', null);

  if (!regime)
    errors.regime = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio');

  const identificationNumber = get(values, 'identification', '');

  if (isElectronic && !zipCode)
    errors.zipCode = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio');

  if (!identificationNumber)
    errors.identification = I18n.get('enterAnID', 'Ingresa una identificación');

  return errors;
}

const republicaDominicanaErrors = (values) => {
  let errors = {};

  const identificationNumber = get(values, 'identification', '');

  if (!identificationNumber)
    errors.identification = I18n.get('enterAnID', 'Ingresa una identificación');

  return errors;
}

const panamaErrors = (values, { isElectronic }) => {
  let errors = {};

  if (isElectronic) {
    const address = get(values, 'address.address', '');
    const combined = get(values, 'address.combined', '');
    const geographicCoordinates = get(values, 'address.geographicCoordinates', '');

    if (!address)
      errors.address = { ...errors.address, address: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio') };
    if (!combined)
      errors.address = { ...errors.address, combined: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio') };
    if (!geographicCoordinates)
      errors.address = { ...errors.address, geographicCoordinates: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio') };

  }

  return errors;
}

const spainErrors = (values, { isElectronic }) => {
  let errors = {};

  const identification = get(values, 'identification', '');
  const name = get(values, 'name', '');
  const address = get(values, 'address.address', '');
  const province = get(values, 'address.province', '');
  const zipCode = get(values, 'address.zipCode', '');

  if (!identification)
    errors.identification = I18n.get('enterAnID', 'Ingresa una identificación');
  if (!name)
    errors.name = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio');
  if (!address)
    errors.address = { ...errors.address, address: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio') };
  if (!province)
    errors.address = { ...errors.address, province: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio') };
  if (!zipCode)
    errors.address = { ...errors.address, zipCode: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio') };

  return errors;
};

const countryErrors = (values, props) => {
  switch (props.country) {
    case 'colombia':
      return colombiaErrors(values, props);
    case 'argentina':
      return argentinaErrors(values, props);
    case 'costaRica':
      return costaRicaErrors(values, props);
    case 'peru':
      return peruErrors(values, props);
    case 'mexico':
      return mexicoErrors(values, props);
    case 'republicaDominicana':
      return republicaDominicanaErrors(values, props);
    case 'panama':
      return panamaErrors(values, props);
    case 'spain':
      return spainErrors(values, props);
    default:
      return {};
  }
}

export const validate = (values, props) => {
  let errors = {
    ...commomErrors(values, props),
    ...countryErrors(values, props),
  };

  return errors;
}

const colombiaValues = (values, newValues, { isElectronic }) => {
  const identificationType = get(values, 'identification.type.key', null);
  const identificationNumber = get(values, 'identification.number', null);
  const dv = get(values, 'identification.dv', null);

  const kindOfPerson = identificationType === 'NIT'
    ? get(values, 'kindOfPerson.key', null)
    : isElectronic ? 'PERSON_ENTITY' : null;

  const countryKey = get(values, 'address.country.key', null);
  const countryValue = get(values, 'address.country.value', null);
  const combinedAddress = get(values, 'address.combined.value', null);
  const zipCode = get(values, 'address.zipCode', null);
  const address = get(values, 'address.address', null);

  const foreignId = identificationType === 'DIE' || identificationType === 'PP' || identificationType === 'TE' || identificationType === 'FOREIGN_NIT';
  const showCountry = !!identificationType && foreignId;
  const nationalityKindOfPerson = identificationType === 'NIT' && kindOfPerson === 'PERSON_ENTITY' ? { nationalityKindOfPerson: get(values, 'nationalityKindOfPerson.key') } : {};

  let newAddress = {
    country: showCountry ? countryValue : 'Colombia',
    zipCode: zipCode,
    address: address,
  }
  if (!!combinedAddress && (!foreignId || countryKey === 'COL')) {
    newAddress = {
      ...newAddress,
      city: get(values, 'address.combined.city', null),
      department: get(values, 'address.combined.department', null),
    }
  }

  delete newValues.identification;
  return {
    ...newValues,
    name: kindOfPerson === 'LEGAL_ENTITY'
      ? get(values, 'name', '')
      : kindOfPerson === 'PERSON_ENTITY' || identificationType === 'NIT'
        ? {
          firstName: get(values, 'firstName', ''),
          secondName: get(values, 'secondName', ''),
          lastName: get(values, 'lastName', ''),
        } : get(values, 'firstName', ''),


    identificationObject: {
      type: identificationType,
      number: identificationNumber,
      dv: dv,
      ...nationalityKindOfPerson,
    },
    kindOfPerson,
    address: newAddress,
    settings: {
      showSaleTickets: get(values, 'settings.showSaleTickets', null),
    },
    // name: get(values, 'name', null) ? get(values, 'name', null).replace(/['"]+/g, "'") : null,
    tradeName: get(values, 'tradeName', null) ? get(values, 'tradeName', null).replace(/['"]+/g, "'") : null,
  }
}

const costaRicaValues = (values, newValues) => {
  const identificationType = get(values, 'identification.type.key', null);
  const identificationNumber = get(values, 'identification.number', null);

  const foreignId = identificationType === 'PE';

  const combinedAddress = get(values, 'address.combined.value', null);
  const neighborhood = get(values, 'address.neighborhood.value', null);
  const address = get(values, 'address.address', null);

  let newAddress = { address };
  if (!!combinedAddress && !foreignId) {
    newAddress = {
      ...newAddress,
      district: get(values, 'address.combined.district', null),
      city: get(values, 'address.combined.city', null),
      department: get(values, 'address.combined.department', null),
      neighborhood,
    }
  }

  delete newValues.identification;
  return {
    ...newValues,
    identificationObject: {
      type: identificationType,
      number: identificationNumber
    },
    address: newAddress,
    economicActivity: get(values, 'economicActivity.key', null),
  }
}

const argentinaValues = (values, newValues) => {
  const identificationType = get(values, 'identification.type.key', null);
  const identificationNumber = get(values, 'identification.number', null);

  delete newValues.identification;
  return {
    ...newValues,
    identificationObject: {
      type: identificationType,
      number: identificationNumber
    },
    ivaCondition: get(values, 'ivaCondition.key', null),
    conditionIibb: get(values, 'conditionIibb.key', null),
    numberIibb: get(values, 'numberIibb', null),
    activityStartDate: !!get(values, 'activityStartDate', null)
      ? dayjs(get(values, 'activityStartDate')).format('YYYY-MM-DD')
      : null,
    address: {
      province: get(values, 'address.province.value', null),
      city: get(values, 'address.city.value', null),
      postalCode: get(values, 'address.postalCode', null),
      address: get(values, 'address.address', null),
    },
  }
}

const peruValues = (values, newValues) => {
  return {
    ...newValues,
    identification: get(values, 'identification.number', null),
    address: {
      country: COUNTRIES_NAMES.PERU,
      district: get(values, 'address.district', null),
      state: get(values, 'address.state', null),
      city: get(values, 'address.city', null),
      ubigeoCode: get(values, 'address.ubigeoCode', null),
      urbanization: get(values, 'address.urbanization', null),
      address: get(values, 'address.address', null),
    },
  }
}

const mexicoValues = (values, newValues) => {
  return {
    ...newValues,
    address: {
      country: 'México',
      street: get(values, 'address.street', null),
      locality: get(values, 'address.locality', null),
      exteriorNumber: get(values, 'address.exteriorNumber', null),
      interiorNumber: get(values, 'address.interiorNumber', null),
      zipCode: get(values, 'address.zipCode', null),
      state: get(values, 'address.state', null),
      municipality: get(values, 'address.municipality', null),
      colony: get(values, 'address.colony', null),
    },
  }
}

const republicaDominicanaValues = (values, newValues) => {
  const combinedAddress = get(values, 'address.combined.value', null);

  let newAddress = { description: get(values, 'address.description', null) };
  if (!!combinedAddress) {
    newAddress = {
      ...newAddress,
      municipality: get(values, 'address.combined.municipality', null),
      province: get(values, 'address.combined.province', null),
    }
  }

  return {
    ...newValues,
    address: newAddress,
  }
}

const panamaValues = (values, newValues) => {
  delete newValues.identification;

  const kindOfPerson = get(values, 'kindOfPerson.key', 'LEGAL_ENTITY');

  return {
    ...newValues,
    tradeName: get(values, 'tradeName', null),
    kindOfPerson: get(values, 'kindOfPerson.key', null),
    name: kindOfPerson === 'LEGAL_ENTITY'
      ? get(newValues, 'name', '')
      : {
        firstName: get(values, 'firstName', ''),
        secondName: get(values, 'secondName', ''),
        lastName: get(values, 'lastName', ''),
      },
    address: {
      address: get(values, 'address.address', null),
      province: get(values, 'address.combined.provinceValue', null),
      district: get(values, 'address.combined.parentValue', null),
      township: get(values, 'address.combined.value', null),
      geographicCoordinates: get(values, 'address.geographicCoordinates', null)
    },
  }
}

const spainValues = (values, newValues) => {
  return {
    ...newValues,
    localSettings: {
      ...newValues.localSettings,
      tradeName: get(values, 'tradeName', null)
        ? get(values, 'tradeName', null).replace(/['"]+/g, "'")
        : null,
    },
    address: {
      ...get(values, 'address', {}),
      province: get(values, 'address.province.value', null),
      city: get(values, 'address.city.municipality', null),
    },
  };
}

const defaultValues = values => ({
  logo: get(values, 'logo', null),
  name: get(values, 'firstName', null),
  identification: get(values, 'identification', null),
  address: get(values, 'address', null),
  phone: get(values, 'phone', null),
  website: get(values, 'website', null),
  email: get(values, 'email', null),
  decimalPrecision: get(values, 'decimalPrecision.key', null),
  regime: get(values, 'regime.key', null),
  sector: get(values, 'sector.value', null),
  multicurrency: get(values, 'multicurrency', null),
  localSettings: {
    shiftsEnabled: get(values, 'shiftsEnabled', null),
    multicurrencyEnabled: get(values, 'multicurrencyEnabled', null),
    idCategoryDefaultIncome: get(values, 'idCategoryDefaultIncome.id', null),
    idCategoryDefaultExpense: get(values, 'idCategoryDefaultExpense.id', null),
  },
})

export const transform = (values, props) => {
  let newValues = defaultValues(values);

  switch (props.country) {
    case 'colombia':
      newValues = colombiaValues(values, newValues, props);
      break;
    case 'costaRica':
      newValues = costaRicaValues(values, newValues);
      break;
    case 'argentina':
      newValues = argentinaValues(values, newValues);
      break;
    case 'peru':
      newValues = peruValues(values, newValues);
      break;
    case 'mexico':
      newValues = mexicoValues(values, newValues);
      break;
    case 'republicaDominicana':
      newValues = republicaDominicanaValues(values, newValues);
      break;
    case 'panama':
      newValues = panamaValues(values, newValues);
      break;
    case 'spain':
      newValues = spainValues(values, newValues);
      break;
    default:
      break;
  }

  Object.keys(newValues).map(key => {
    if (isNull(newValues[key]) || isUndefined(newValues[key]))
      delete newValues[key];
    return null;
  })

  return newValues;
}