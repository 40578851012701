import { I18n } from "aws-amplify";
import { calculateItemsValues } from "../../../../utils";
import { useSelector } from "react-redux";
import BigNumber from "bignumber.js";
import { get, isEmpty } from "lodash";

import { decimalPrecision, country as countrySelector, companySelector, tipsSelector } from '../../../../selectors/company'

const ProvisionalPayment = ({ invoice }) => {
  let { items, currency, payments } = invoice
  const decimal = useSelector(decimalPrecision);
  const country = useSelector(countrySelector);
  const company = useSelector(companySelector);
  const tipsSettings = useSelector(tipsSelector);
  let tip = new BigNumber(0);
  let totalReceived = new BigNumber(0);
  const preInvoiceTip = get(invoice, 'tip', false);
  const isPreInvoice = !!preInvoiceTip;
  const chargeId = tipsSettings.additionalChargeId

  if (!items) return null;

  const prefix = !!get(currency, 'symbol')
    ? get(currency, 'symbol') : null;

  const fmt = prefix ? {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  } : null;

  if (!isEmpty(payments)) {
    payments.forEach(payment => {
      totalReceived = totalReceived.plus(payment.amount);
    });
  }

  const { total, subtotal } = calculateItemsValues({ items, decimal, tips: preInvoiceTip, country, company, ignoreDisableTaxes: true });

  if (isPreInvoice) {
    if (get(preInvoiceTip, 'include', false)) {
      tip = get(preInvoiceTip, "type") === 'PERCENTAGE'
        ? subtotal.times(get(preInvoiceTip, "percentage")).div(100)
        : new BigNumber(get(preInvoiceTip, "value"));
    }
  } else {
    const additionalCharges = !!get(invoice, 'additionalCharges', []) ? get(invoice, 'additionalCharges', []) : [];
    tip = new BigNumber(additionalCharges.find(charge => charge.idCharge === chargeId)?.amount || 0);
  }

  return (
    <div className="d-flex flex-column justify-content-end text-right">
      <h3 className="h3">
        <strong className="font-weight-bold">{I18n.get('total', 'Total')}</strong> {' '}
        {isPreInvoice
          ? total.toFormat(decimal, fmt)
          : total.plus(tip).toFormat(decimal, fmt)
        }
      </h3>
      <h3 className="h3">
        <strong className="font-weight-bold">{I18n.get('totalReceived', 'Total recibido')}</strong> {' '}
        {
          totalReceived.toFormat(decimal, fmt)
        }
      </h3>
    </div>
  )
}

export default ProvisionalPayment