import { I18n } from "aws-amplify";
import { get, isEmpty, find, replace } from "lodash";
import dayjs from "dayjs";

import { toast } from "../../../utils";

import deletedItemCodes from "../../countriesData/costaRica/deletedItemCodes.json"
import { Icon, Typography } from '@alegradev/smile-ui-react';
import { COUNTRIES } from "../../../utils/enums/countries";
import { useSelector } from "react-redux";
import { items } from "../../../selectors/activeInvoice";

export const renderErrors = ({ item, country, station, isElectronic }) => {
  const invoiceItems = useSelector(items);

  const renderItemCodeOutDated = () => {
    if (country !== 'costaRica') return null;
    if (!itemHasOutdatedProductKey(item)) return null;
    return (
      <p className="new-active-invoice__item-warning h5 text-capitalize-first pt-1 d-flex align-items-center">
        <span style={{ minWidth: "23px" }} className="d-flex justify-content-center align-items-center mr-2 rounded">
          <Icon icon='alert-triangle' />
        </span>
        <span style={{ color: "#334155" }}>{I18n.get('', 'Debes editar el producto en esta venta y actualizar su código.')}</span>
      </p>
    )
  }
  if (!!renderItemCodeOutDated())
    return renderItemCodeOutDated();

  const renderNoTaxWarning = () => {
    const taxes = get(item, 'tax', []);

    const hasItbis = !isEmpty(taxes.filter(tax => get(tax, 'type') === 'ITBIS' || get(tax, 'type') === 'EXENTO'))

    if (country === 'republicaDominicana' && isElectronic && !hasItbis) {
      return (
        <p className="new-active-invoice__item-warning h5 text-capitalize-first pt-1 d-flex align-items-center">
          <span style={{ minWidth: "23px" }} className='d-flex justify-content-center align-items-center mr-2 rounded'>
            <Icon icon='alert-triangle' />
          </span>
          <span>{I18n.get('noItbisWarning.items', 'Incluye el impuesto ITBIS de este producto para facturarlo')}</span>
        </p>
      )
    }
  }
  if (!!renderNoTaxWarning())
    return renderNoTaxWarning()

  const renderNoITBMSWarning = () => {
    if (country !== 'panama' || get(item, 'tax', []).length > 0 || !isElectronic) return null;

    return (
      <p className="new-active-invoice__item-warning h5 text-capitalize-first pt-1 d-flex align-items-center">
        <span style={{ minWidth: "23px" }} className='d-flex justify-content-center align-items-center mr-2 rounded'>
          <Icon icon='alert-triangle' />
        </span>
        <span>
          {I18n.get('noITBMS', 'Incluye el impuesto ITBMS para facturar este ítem.')}
        </span>
      </p>
    )
  }
  if (!!renderNoITBMSWarning())
    return renderNoITBMSWarning()

  const renderAvailableQuantityWarning = () => {
    if (!get(item, 'inventory', null)) return null;

    const currentQuantity = invoiceItems
      .filter((i) => i.id === get(item, 'id', null))
      .reduce((acc, curr) => (acc += Number(get(curr, 'quantity', 0))), 0);



    let availableQuantity = null;
    let minQuantity = 0;

    const warehouses = get(item, 'inventory.warehouses')
    if (!!warehouses) {
      const stationWarehouse = warehouses.find(warehouse => +get(warehouse, 'id') === +get(station, 'idWarehouse'))

      if (!!stationWarehouse) {
        availableQuantity = +get(stationWarehouse, 'availableQuantity', null);
        minQuantity = +get(stationWarehouse, 'minQuantity', 0);
      }
    }
    minQuantity = Math.max(minQuantity, 0);

    if (availableQuantity == null) return null;

    availableQuantity = Math.max(availableQuantity, 0);
    if (availableQuantity - currentQuantity <= minQuantity && availableQuantity >= currentQuantity) {
      return (
        <p style={{ color: "#334155" }} className="new-active-invoice__item-warning h5 text-capitalize-first pt-1 d-flex align-items-center">
          {I18n.get('itemMinimumQuantityReached', 'Este producto llegó a su cantidad mínima, recuerda abastecerte')}
        </p>
      )
    }

    
    if (
      availableQuantity === 0 &&
      !get(item, 'inventory.negativeSale', false)
    ) {
      return (
        <div className='d-flex align-items-center gap-2'>
          <Icon icon='circle-minus' color='#E11D48' size='small' />
          <Typography
            type='caption-regular'
            text={I18n.get(
              'invoice.outOfStock',
              'Agotado y con "venta en negativo" desactivada'
            )}
            variant='error'
          />
        </div>
      );
    } else if (
      availableQuantity < currentQuantity &&
      !get(item, 'inventory.negativeSale', false)
    ) {
      return (
        <div className='d-flex align-items-center gap-2'>
          <Icon icon='circle-minus' color='#E11D48' size='small' />
          <Typography
            type='caption-regular'
            text={replace(
              I18n.get(
                'invoice.underAvailableQuantity',
                'Agotado y con "venta en negativo" desactivada'
              ),
              '{}',
              availableQuantity
            )}
            variant='error'
          />
        </div>
      );
    }

    if (availableQuantity >= currentQuantity) return null;

    return (
      <div className='d-flex align-items-center gap-2'>
        <Icon icon='alert-triangle-filled' color='#FBBF24' size='small' />
        <Typography
          type='caption-regular'
          text={I18n.get(
            'itemQuantityIsGreaterThanAvailable',
            'La cantidad vendida supera la disponible en el inventario'
          )}
          variant='warning'
        />
      </div>
    );
  }
  if (!!renderAvailableQuantityWarning())
    return renderAvailableQuantityWarning();
}

export const itemsInSaleHaveOutdatedProductKey = (items) => {
  return items.some(itemHasOutdatedProductKey)
}

export const itemHasOutdatedProductKey = (item) => {
  return deletedItemCodes.includes(Number(item.productKey))
}

export const itemsHaveReferenceMaxExceeded = (items, showNotification = false) => {
  return items.some(item => itemExceedsReferenceMax(item, showNotification));
}

export const itemExceedsReferenceMax = (item, showNotification = false) => {
  let result = get(item, 'reference.reference.length') > 20
  if (showNotification && result) {
    toast.warning({
      title: I18n.get('youMustEditThisItem', 'Debes editar este ítem'),
      subtitle: I18n.get('referenceMaximumCharactersForEInvoice', 'La referencia puede tener máximo 20 caracteres para elegirlo en un documento electrónico.')
    })
  }
  return result;
}

export const checkNumerationBlock = (numeration, country, registryDate, onlyInformative = false, isOnline = true, isApiAvailable = true, isElectronicOffline = false) => {
  // Early return para paises en los que no controlamos el bloqueo de numeraciones
  const BLOCKING_CHECKING_COUNTRIES = ['colombia', 'usa', 'spain', 'other'];
  if (!BLOCKING_CHECKING_COUNTRIES.includes(country)) return null;

  // Desactivacion del bloquo de numeraciones antes del 13-10-2023
  if (dayjs().isBefore('2023-07-13') && !onlyInformative) return null;

  // Separamos el despliegue para dos grupos
  const isFirstDeploymentGroup = (country === 'republicaDominicana') || (country === 'colombia' && dayjs(registryDate).isBefore('2021-10-25')) || (country === 'mexico');
  if (dayjs().isBefore('2023-07-17') && !isFirstDeploymentGroup && !onlyInformative)
    return null;

  const numerationEndDate = !!get(numeration, 'endDate', null) ? dayjs(get(numeration, 'endDate', null)).add(1, 'day') : null;
  const numerationMaxInvoiceNumber = parseInt(get(numeration, 'maxInvoiceNumber'));
  const nextInvoiceNumber = parseInt(get(numeration, 'nextInvoiceNumber'));

  const blockingReasons = [
    {
      key: 'NUMERATION_EXPIRED',
      name: 'numerationExpired',

    },
    {
      key: 'NUMERATION_MAX_NUMBER_REACHED',
      name: 'numerationMaxNumberReached',
    },
    {
      key: 'NUMERATION_ELECTRONIC_OFFLINE',
      name: 'numerationElectronicOffline',
    },
    {
      key: 'NUMERATION_ELECTRONIC_INTERMITENCE_ERROR',
      name: 'numerationElectronicIntermitenceError',
    }
  ]

  const checkExpiry = !!numerationEndDate && !dayjs().isBefore(numerationEndDate);
  const checkMaxNumberReached = !!numerationMaxInvoiceNumber && (nextInvoiceNumber > numerationMaxInvoiceNumber);

  if (
    (!isOnline)&&
    get(numeration, 'isElectronic', false) &&
    [COUNTRIES.COLOMBIA, COUNTRIES.ARGENTINA, COUNTRIES.PERU, COUNTRIES.COSTA_RICA, COUNTRIES.PANAMA, COUNTRIES.REPUBLICA_DOMINICANA, COUNTRIES.MEXICO].includes(country) &&
    !isElectronicOffline
  ) {
    return find(blockingReasons, { key: "NUMERATION_ELECTRONIC_OFFLINE" });
  }

  if (
    (!isApiAvailable)&&
    get(numeration, 'isElectronic', false) &&
    [COUNTRIES.COLOMBIA, COUNTRIES.ARGENTINA, COUNTRIES.PERU, COUNTRIES.COSTA_RICA, COUNTRIES.PANAMA, COUNTRIES.REPUBLICA_DOMINICANA, COUNTRIES.MEXICO].includes(country)
  ) {
    return find(blockingReasons, { key: "NUMERATION_ELECTRONIC_INTERMITENCE_ERROR" });
  }  

  switch (country) {
    case 'colombia':
    case 'republicaDominicana':
    case 'usa':
    case 'spain':
    case 'other':
      if (checkExpiry)
        return find(blockingReasons, { key: "NUMERATION_EXPIRED" })
      if (checkMaxNumberReached)
        return find(blockingReasons, { key: "NUMERATION_MAX_NUMBER_REACHED" })
      return null

    default:
      return null;
  }
}

export const checkContactBlock = (contact, isTicketBaiEnabled) => {
  const allowedIdentifications = ['DNI', 'NIF']
  if (!contact) return null;

  if (isTicketBaiEnabled && !allowedIdentifications.includes(get(contact, 'identificationObject.type')))
    return 'notValidContact';
  return null;
};

export const getResolutionHelpUrl = (numeration, country, blockReason) => {
  switch (country) {
    case 'colombia':
      if (get(blockReason, 'name') === 'numerationExpired')
        return 'https://ayuda.alegra.com/es/habilita-los-rangos-de-numeraci%C3%B3n-de-resoluciones-vencidas-en-la-dian-col'
      if (get(numeration, 'documentType') === 'saleTicket')
        return 'https://ayuda.alegra.com/es/solicita-en-l%C3%ADnea-tu-resoluci%C3%B3n-de-documento-equivalente-p.o.s.-a-la-dian-colombia';
      return 'https://ayuda.alegra.com/es/solicita-en-l%C3%ADnea-tu-resoluci%C3%B3n-de-facturaci%C3%B3n-a-la-dian';
    case 'republicaDominicana':
      return 'https://ayuda.alegra.com/es/configura-la-numeraci%C3%B3n-de-tus-comprobantes-ncf-en-alegra-rep.-dominicana'

    default:
      return 'https://ayuda.alegra.com/es/configuraci%C3%B3n-de-la-numeraci%C3%B3n-de-tus-comprobantes-en-alegra';
  }
}