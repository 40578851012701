import React, { useEffect, useState, useCallback } from 'react';
import { I18n } from '@aws-amplify/core';
import { get, isString } from 'lodash';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { country as countrySelector } from '../../../../selectors/company';
import { getID, getDV, renderAddress, getIVACondition } from '../utilities';
import { getClientById as getClientbyIdFromDb } from '../../../../database/contactsDB';
import alegraAPI from '../../../../reducers/alegraAPI';
import { isDefaultClient } from '../../../../utils/invoices';

const ProvisionalClient = ({ invoice, setting: { align, template } }) => {
  const country = useSelector(countrySelector);
  const [fullClient, setFullClient] = useState('');

  const client = get(invoice, 'client', null);
  console.log(invoice)
  const documentNumber = get(invoice, 'provisionalTicket.number', '');

  const fetchCurrentClient = useCallback(async (id) => {
    try {
      const responseDB = await getClientbyIdFromDb(id);
      if (isEmpty(responseDB)) {
        const responseAPI = await alegraAPI.get(`/contacts/${id}`);
        setFullClient(responseAPI.data);
      } else {
        setFullClient(responseDB);
      }
    } catch (error) {
      setFullClient('');
    }
  }, []);

  useEffect(() => {
    if (!client) setFullClient('');
    else fetchCurrentClient(get(client, 'id', "").toString());
  }, [client, fetchCurrentClient]);

  if (!client) return null;

  const renderClientName = () => {
    if (!!get(client, 'name')) {
      if (!!isString(get(client, 'name'))) return get(client, 'name');
      return `${get(client, 'name.firstName', '')}${!!get(client, 'name.secondName', null)
        ? ' ' + get(client, 'name.secondName')
        : ''
        }${!!get(client, 'name.lastName', null)
          ? ' ' + get(client, 'name.lastName')
          : ''
        }`;
    }
    return '';
  };

  const renderClientIdentification = (client, fullClient, country) => {
    if (
      ['peru', 'republicaDominicana'].includes(country) &&
      !!get(fullClient, 'identificationObject.type') &&
      !!get(fullClient, 'identificationObject.number')
    )
      return (
        <p>
          <strong>{fullClient.identificationObject.type}</strong>
          {fullClient.identificationObject.number}
        </p>
      );

    if (country === 'spain' && isDefaultClient(client, country)) {
      return null;
    }

    if (
      !!get(client, 'identification', null) ||
      !!get(client, 'identificationObject', null)
    )
      return (
        <p>
          {getIdTitle() ? <strong>{getIdTitle()}</strong> : null}
          {`${getID(client)} `}
          {getDV(fullClient ? fullClient : client, country) ? (
            <>
              <span className='font-weight-bold'>{I18n.get('dv', 'DV')}: </span>{' '}
              {getDV(fullClient ? fullClient : client, country)}
            </>
          ) : null}
        </p>
      );

    return null;
  };

  const getIdTitle = () => {
    if (country === 'panama') {
      const currentClient = fullClient ? fullClient : client;
      const kindOfPerson = get(
        currentClient,
        'identificationObject.kindOfPerson',
        null
      );
      if (kindOfPerson) {
        return 'RUC';
      } else {
        return null;
      }
    }

    return null;
  };

  return (
    <div
      className={`w-100 text-${align} py-2 d-flex flex-column border-top`}
    >
      <h3 className='h3 font-weight-bold'>
        {I18n.get('document', 'Documento')} N {documentNumber}
      </h3>

      <p>
        {I18n.get('client', 'cliente')}: {renderClientName()}
      </p>

      {renderClientIdentification(client, fullClient, country)}
    </div>
  );
};

export default ProvisionalClient;
