import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';

import conditionsIibb from '../../../countriesData/argentina/conditionsIibb';
import { renderSelect, renderField, renderDate } from '../../fields/V0/Fields';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { hasPermissionTo } from '../../../../selectors/auth';

const renderArgValues = (props) => {
  const can = useSelector(hasPermissionTo);
  return get(props, 'country') === 'argentina' ? (
    <>
      <Field
        name='conditionIibb'
        component={renderSelect}
        className='col-md-6'
        label={I18n.get('iibbCondition', 'Condición IIBB')}
        options={conditionsIibb}
        getOptionLabel={(option) => option.value}
        getOptionValue={(option) => option.key}
        disabled={!can('edit', 'company')}
        required
      />

      <Field
        name='numberIibb'
        component={renderField}
        className='col-md-6'
        label={I18n.get('numberIibb', 'Número de IIBB')}
        type='text'
        disabled={!can('edit', 'company')}
        required
      />

      <Field
        name='activityStartDate'
        component={renderDate}
        className='col-md-6'
        label={I18n.get('activityStartDate', 'Inicio de actividades')}
        disabled={!can('edit', 'company')}
        required
      />
    </>
  ) : null;
};

export default renderArgValues;
