import React from 'react';
import { useSelector } from 'react-redux'
import { Field } from 'react-final-form';
import { get } from 'lodash'

import { country as countrySelector, electronicInvoicing } from '../../../../selectors/company'
import { regimes as regimesSelector } from '../../../../selectors/regimes'
import regimesLabel from '../../../countriesData/general/regimesLabel'
import { renderSelect } from '../../fields/V0/Fields';
import { hasPermissionTo } from '../../../../selectors/auth';

const RenderRegime = props => {
  const regimes = useSelector(regimesSelector)
  const country = useSelector(countrySelector)
  const isElectronic = useSelector(electronicInvoicing)
  const identificationType = get(props, 'values.identification.type.key', '');
  const can = useSelector(hasPermissionTo);

  if (country === 'colombia' && !!isElectronic && identificationType === 'NIT')
    return <Field
      name="regime"
      options={regimes}
      component={renderSelect}
      className="col-12"
      label={regimesLabel(country)}
      getOptionLabel={option => option.value}
      getOptionValue={option => option.key}
      required
    />

  if (country === 'mexico')
    return <Field
      name="regime"
      options={regimes}
      component={renderSelect}
      className={"col-12"}
      label={regimesLabel(country)}
      getOptionLabel={option => option.value}
      getOptionValue={option => option.key}
      disabled={!can('edit', 'company')}
      required
    />

  if (country === 'peru')
    return (
      <Field
        name='regime'
        options={regimes}
        component={renderSelect}
        className={'col-12'}
        label={regimesLabel(country)}
        getOptionLabel={(option) => option.value}
        getOptionValue={(option) => option.key}
        disabled={!can('edit', 'company')}
        required
      />
    );

  if (country === 'costaRica')
    return <Field
      name="regime"
      options={regimes}
      component={renderSelect}
      className={"col-md-6"}
      label={regimesLabel(country)}
      getOptionLabel={option => option.value}
      getOptionValue={option => option.key}
      disabled={!can('edit', 'company')}
      required
    />

  return null;
}

export default RenderRegime;