import { capitalize, forEach, get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { allPaymentMethods } from '../../../../selectors/paymentMethods';
import { Icon, Typography, useDeviceType } from '@alegradev/smile-ui-react';
import { Field } from 'react-final-form';
import { hasPermissionTo } from '../../../../selectors/auth';
import { activeBanks } from '../../../../selectors/banks';
import { renderField, renderSelect } from '../../fields/V0/Fields';
import { I18n } from 'aws-amplify';
import { useEffect, useMemo, useState } from 'react';
import { activeSellers } from '../../../../selectors/sellers';
import { checkFeatureLimit } from '../../../../reducers/membership';
import UseField from './UseField';
import {
  companySelector,
  country as countrySelector,
} from '../../../../selectors/company';
import { COUNTRIES } from '../../../../utils/enums/countries';
import { client as clientSelector } from '../../../../selectors/activeInvoice';
import { DEFAULT_CFDI_USE } from '../../../countriesData/mexico/cfdiUses';
import configAPI from '../../../../reducers/configAPI';
import {
  MEXICO_DEFAULT_OTHER_RFC,
  MEXICO_DEFAULT_RFC,
} from '../../../countriesData/mexico/rfc';
import useElectronicPayrollStatus from '../../../../hooks/useElectronicPayrollStatus/hook';
import { isMexicoFeAvailable } from '../../../../utils/deploys/mexicoFeDeployGroups';
import { allRegimes } from '../../../countriesData/mexico/regimes';

const CustomPaymentMethod = ({ form, values, total, anotationMaxLimit }) => {
  const sellers = useSelector(activeSellers);
  const dispatch = useDispatch();
  const paymentMethods = useSelector(allPaymentMethods);
  const paymentMethod = paymentMethods.find((p) => p.name === values.method);
  const can = useSelector(hasPermissionTo);
  const banks = useSelector(activeBanks);
  const country = useSelector(countrySelector);
  const client = useSelector(clientSelector);
  const [uses, setUses] = useState([]);
  const company = useSelector(companySelector);
  const { isActive } = useElectronicPayrollStatus();

  const deviceType = useDeviceType();

  useEffect(() => {
    const loadCfdiUses = async () => {
      if (country !== COUNTRIES.MEXICO) return;
      const response = await configAPI.get('/cfdi-uses', {
        application_version: country,
      });
      setUses(get(response, 'data', []));
    };
    loadCfdiUses();
  }, [client]);

  const isFeMexActive = useMemo(() => {
    return isMexicoFeAvailable(country, isActive);
  }, [country, company, isActive]);

  const isUseDisabled = useMemo(() => {
    if (!client) return true;
    if (
      (client && client?.identification === MEXICO_DEFAULT_RFC) ||
      client?.identification === MEXICO_DEFAULT_OTHER_RFC
    )
      return true;
    return false;
  }, [client]);

  const useOptions = useMemo(() => {
    const options = [];
    if (country === COUNTRIES.MEXICO) {
      if (client) {
        forEach(client?.regimeObject, (value) => {
          if (allRegimes[value]) {
            forEach(uses, (use) => {
              const splitRegimes = use?.regimes?.split('|');
              if (splitRegimes.includes(`${allRegimes[value].code}`)) {
                options.push({
                  key: use.key,
                  value: use.value,
                });
              }
            });
          }
        });
      } else {
        options.push(DEFAULT_CFDI_USE);
      }
    }

    return options;
  }, [uses, client]);
  return (
    <>
      {get(paymentMethod, 'logo.url', null) ||
      get(paymentMethod, 'qr.url', null) ? (
        <>
          {paymentMethod && (
            <div className='form-row'>
              <div className='modal__invoice-custom-payment-instruction col-md-12'>
                <Typography
                  type='body-3-bold'
                  text={get(paymentMethod, 'instructions', null)}
                />
              </div>
              <div className='col-md-4 d-flex justify-content-center'>
                {get(paymentMethod, 'qr.url', null) ? (
                  <img
                    src={get(paymentMethod, 'qr.url', null)}
                    height={192}
                    width={192}
                  />
                ) : get(paymentMethod, 'logo.url', null) ? (
                  <img
                    src={get(paymentMethod, 'logo.url', null)}
                    height={192}
                    width={192}
                  />
                ) : (
                  <div className='d-flex justify-content-center align-items-center'>
                    <Icon icon='transfer-in' size='huge' />
                  </div>
                )}
              </div>
              <div className='col-md-8'>
                <div className='col-md-12 p-0'>
                  <Field
                    name={'transfer'}
                    className='col-md-12 p-0 mt-3'
                    defaultValue={total}
                    type='number'
                    label={
                      <Typography
                        type='label-2'
                        text={
                          'Valor del pago en ' + get(paymentMethod, 'name', '')
                        }
                        variant='secondary'
                      />
                    }
                    required
                  >
                    {(props) => (
                      <>
                        {renderField({
                          ...props,
                          fontSize: '16px',
                          height: '48px',
                          fontColor: '#334155',
                        })}
                      </>
                    )}
                  </Field>
                  <Field
                    name='transferBank'
                    className='col-md-12 p-0 mt-3'
                    component={(fieldProps) =>
                      renderSelect({
                        ...fieldProps,
                        height: '48px',
                        borderRadius: '12px',
                        fontSize: '16px',
                        borderColor: '#CBD5E1',
                        fontColor: '#334155',
                      })
                    }
                    label={
                      <Typography
                        type='label-2'
                        text={capitalize(I18n.get('bank', 'banco'))}
                        variant='secondary'
                      />
                    }
                    options={
                      !can('index', 'bank-accounts') ||
                      !can('view', 'bank-accounts')
                        ? []
                        : banks
                    }
                    selectInfo={
                      !can('index', 'bank-accounts') ||
                      !can('view', 'bank-accounts')
                        ? I18n.get(
                            'userNotAllowed.bank-accounts.view',
                            'no tienes permisos para ver la información de las cuentas de bancos'
                          )
                        : null
                    }
                    hideNoOptionMessage={
                      !can('index', 'bank-accounts') ||
                      !can('view', 'bank-accounts')
                    }
                    required
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    menuPosition='absolute'
                  />
                </div>
              </div>
            </div>
          )}
          {country === COUNTRIES.MEXICO &&
            isFeMexActive &&
            documentType === 'invoice' && (
              <div className='d-flex justify-content-center mt-4'>
                <Field
                  name='use'
                  className='col-sm-12 pl-2 pr-0'
                  options={useOptions}
                  disabled={isUseDisabled}
                  required
                  label={
                    <Typography
                      text={capitalize(I18n.get('use', 'Uso'))}
                      type='label-2'
                      variant='secondary'
                    />
                  }
                  defaultValue={useOptions.length > 1 ? null : useOptions[0]}
                  getOptionLabel={(option) => option.value}
                  getOptionValue={(option) => option.key}
                >
                  {(props) => (
                    <>
                      {renderSelect({
                        ...props,
                        fontSize: '16px',
                        borderRadius: '12px',
                        height: '48px',
                        fontColor: '#334155',
                      })}
                    </>
                  )}
                </Field>
              </div>
            )}
          <div className='d-flex justify-content-center mt-4'>
            <Field
              name='seller'
              className='col-sm-12 pl-2 pr-0'
              options={sellers}
              label={
                <Typography
                  text={capitalize(I18n.get('seller', 'vendedor'))}
                  type='label-2'
                  variant='secondary'
                />
              }
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              addOptionText={
                can('add', 'sellers')
                  ? capitalize(I18n.get('newSeller', 'Nuevo vendedor'))
                  : null
              }
              addOptionAction={() =>
                dispatch(
                  checkFeatureLimit(
                    'sellers',
                    can('add', 'sellers')
                      ? () => dispatch(openModal({ modal: 'seller' }))
                      : null
                  )
                )
              }
              menuPosition='absolute'
            >
              {(props) => (
                <>
                  {renderSelect({
                    ...props,
                    fontSize: '16px',
                    borderRadius: '12px',
                    height: '48px',
                    fontColor: '#334155',
                    borderColor: '#CBD5E1',
                  })}
                </>
              )}
            </Field>
          </div>
          <div className='col-sm-12 pl-2 pr-0'>
            <Field
              name='anotation'
              className={`modal__invoice-anotation-field ${
                anotationMaxLimit ? 'has-error' : ''
              }`}
              label={
                <Typography
                  type='label-2'
                  text={capitalize(I18n.get('observations', 'observaciones'))}
                  variant='secondary'
                />
              }
              placeholder={I18n.get(
                'addYourObservation',
                'Ingresa tu observación'
              )}
              type='text'
            >
              {(props) =>
                renderField({
                  ...props,
                  fontSize: '16px',
                  height: '48px',
                  borderRadius: '12px',
                })
              }
            </Field>
          </div>
        </>
      ) : (
        <>
          <div className='form-row col-sm-12'>
            <div className='col-sm-12 col-md-6'>
              <Field
                name='transfer'
                className='col-md-12 pl-0 pr-2'
                defaultValue={total}
                type='number'
                label={
                  <Typography
                    type='label-2'
                    text={'Valor del pago en ' + get(paymentMethod, 'name', '')}
                    variant='secondary'
                  />
                }
                required
                placeholder={'$'}
              >
                {(props) => (
                  <>
                    {renderField({
                      ...props,
                      fontSize: '16px',
                      height: '48px',
                    })}
                  </>
                )}
              </Field>
              <Field
                name='transferBank'
                className='col-md-12 pl-0 pr-2'
                component={(fieldProps) =>
                  renderSelect({
                    ...fieldProps,
                    height: '48px',
                    fontSize: '16px',
                    borderColor: '#CBD5E1',
                    fontColor: '#334155',
                    borderRadius: '12px',
                  })
                }
                label={
                  <Typography
                    type='label-2'
                    text={capitalize(I18n.get('bank', 'banco'))}
                    variant='secondary'
                  />
                }
                options={
                  !can('index', 'bank-accounts') ||
                  !can('view', 'bank-accounts')
                    ? []
                    : banks
                }
                selectInfo={
                  !can('index', 'bank-accounts') ||
                  !can('view', 'bank-accounts')
                    ? I18n.get(
                        'userNotAllowed.bank-accounts.view',
                        'no tienes permisos para ver la información de las cuentas de bancos'
                      )
                    : null
                }
                hideNoOptionMessage={
                  !can('index', 'bank-accounts') ||
                  !can('view', 'bank-accounts')
                }
                required
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                menuPosition='absolute'
              />
              <UseField values={values} />
            </div>
            <div
              className={`col-sm-12 col-md-6 ${deviceType === 'mobile' ? 'mobile' : 'form__invoice-advanced-data '}`}
            >
              <div className='d-flex justify-content-center'>
                <Field
                  name='seller'
                  className='col-sm-12 pl-2 pr-0'
                  options={sellers}
                  label={
                    <Typography
                      text={capitalize(I18n.get('seller', 'vendedor'))}
                      type='label-2'
                      variant='secondary'
                    />
                  }
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  addOptionText={
                    can('add', 'sellers')
                      ? capitalize(I18n.get('newSeller', 'Nuevo vendedor'))
                      : null
                  }
                  addOptionAction={() =>
                    dispatch(
                      checkFeatureLimit(
                        'sellers',
                        can('add', 'sellers')
                          ? () => dispatch(openModal({ modal: 'seller' }))
                          : null
                      )
                    )
                  }
                  menuPosition='absolute'
                >
                  {(props) => (
                    <>
                      {renderSelect({
                        ...props,
                        fontSize: '16px',
                        borderRadius: '16px',
                        height: '48px',
                        fontColor: '#334155',
                        borderColor: '#CBD5E1',
                      })}
                    </>
                  )}
                </Field>
              </div>
              <div className='col-sm-12 pl-2 pr-0'>
                <Field
                  name='anotation'
                  className={`modal__invoice-anotation-field ${
                    anotationMaxLimit ? 'has-error' : ''
                  }`}
                  label={
                    <Typography
                      type='label-2'
                      text={capitalize(
                        I18n.get('observations', 'observaciones')
                      )}
                      variant='secondary'
                    />
                  }
                  placeholder={I18n.get(
                    'addYourObservation',
                    'Ingresa tu observación'
                  )}
                  type='textarea'
                >
                  {(props) =>
                    renderField({
                      ...props,
                      fontSize: '16px',
                      height: '48px',
                      borderRadius: '12px',
                      fontColor: '#334155',
                      borderColor: '#CBD5E1',
                    })
                  }
                </Field>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CustomPaymentMethod;
