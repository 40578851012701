import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useDispatch, useSelector } from 'react-redux';
import { get, upperCase } from 'lodash';

import {
  decimalPrecision,
  currency as companyCurrencySelector,
} from '../../../selectors/company';
import {
  currency,
  isPaymentMethodSelected,
  paymentMethod,
  itemsCount as itemsCountSelector,
  numeration as numerationSelector,
} from '../../../selectors/activeInvoice';
import { getMainCurrency } from '../../../selectors/currencies';
import { useDeviceType } from '../../../hooks/useDeviceType';
import { Icon, Typography, useModal } from '@alegradev/smile-ui-react';
import { setIsPaymentMethodSelected } from '../../../reducers/activeInvoice';
import { sendGTMEvent } from '../../../reducers/company';

const NewTotal = ({ form, values, total }) => {
  const decimal = useSelector(decimalPrecision);
  const mainCurrency = useSelector(getMainCurrency);
  const selectedCurrency = useSelector(currency);
  const isPaymentSelected = useSelector(isPaymentMethodSelected);
  const paymentMethodSelected = useSelector(paymentMethod);
  const companyCurrency = useSelector(companyCurrencySelector);
  const itemsCount = useSelector(itemsCountSelector);
  const numeration = useSelector(numerationSelector);
  const { updateModal } = useModal();

  const dispatch = useDispatch();

  const deviceType = useDeviceType();

  const prefix = !!get(selectedCurrency, 'symbol')
    ? get(selectedCurrency, 'symbol')
    : !!get(mainCurrency, 'symbol')
      ? get(mainCurrency, 'symbol')
      : '';

  const fmt = {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  };

  const sendPaymentMethodSelectedEvent = (paymentMethod) => {
    dispatch(
      sendGTMEvent('customer-payment-method-selected', {
        isElectronicInvoice: get(numeration, 'isElectronic', false),
        customerPaymentMethod: paymentMethod,
        currency: get(selectedCurrency, 'code', get(companyCurrency, 'code')),
        invoiceTotal: parseFloat(total),
        totalItems: itemsCount,
      })
    );
  };

  return (
    <div className='d-flex justify-content-center p-3 position-relative '>
      <div className='d-flex flex-column align-items-center modal__invoice-total gap-3'>
        <div className='modal__invoice-total-title'>
          <Typography
            text={upperCase(I18n.get('total', 'total'))}
            type='body-3-bold'
            variant='placeholder'
          />
        </div>
        <div className='modal__invoice-total-amount'>
          <Typography
            text={total.toFormat(decimal, fmt)}
            type={'heading-3'}
            variant='secondary'
          />
        </div>
      </div>
      {get(values, 'method') && isPaymentSelected && (
        <button
          className={`change-payment-btn ${deviceType === 'mobile' ? 'mobile' : ''}`}
          onClick={() => {
            form.change('method', '');
            form.reset();
            if (paymentMethodSelected) {
              form.change(paymentMethodSelected, null);
            }
            updateModal('NewInvoice', {
              primaryAction: {
                text: I18n.get('continue', 'continuar'),
                disabled: paymentMethodSelected ? false : true,
                onClick: () => {
                  sendPaymentMethodSelectedEvent(paymentMethodSelected);
                  form.change('method', paymentMethodSelected);
                  dispatch(setIsPaymentMethodSelected(true));
                  if (
                    paymentMethodSelected !== 'cash' &&
                    paymentMethodSelected !== 'debit' &&
                    paymentMethodSelected !== 'credit' &&
                    paymentMethodSelected !== 'transfer' &&
                    paymentMethodSelected !== 'combined'
                  ) {
                    form.change('transfer', total.toString());
                  }
                },
              },
            });
            dispatch(setIsPaymentMethodSelected(false));
          }}
        >
          <Icon icon='switch-vertical' size='small' color='#30ABA9' />
          <Typography
            text='Cambiar método'
            type={deviceType === 'mobile' ? 'caption-regular' : 'body-3-bold'}
            variant='success'
          />
        </button>
      )}
    </div>
  );
};

export default NewTotal;
