import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

import { calculateItemsValues } from '../utils'
import { companySelector, country, decimalPrecision } from './company';

const itemsSelector = state => get(state, 'editInvoice.items');
const clientSelector = state => get(state, 'editInvoice.client');
const priceListSelector = state => get(state, 'editInvoice.priceList');
const currencySelector = state => get(state, 'editInvoice.currency');
const numerationSelector = state => get(state, 'editInvoice.numeration');

export const tipSelector = state => get(state, "editInvoice.additionalCharges");

export const client = createDraftSafeSelector(
  clientSelector,
  client => !!client ? client : null
)

export const priceList = createDraftSafeSelector(
  priceListSelector,
  priceList => !!priceList ? priceList : null
)

export const currency = createDraftSafeSelector(
  currencySelector,
  currency => !!currency ? currency : null
)

export const numeration = createDraftSafeSelector(
  numerationSelector,
  numeration => !!numeration ? numeration : null
)

export const items = createDraftSafeSelector(
  itemsSelector,
  items => !!items ? items : []
)

export const itemsCount = createDraftSafeSelector(
  itemsSelector,
  items => !!items 
    ? items
        .map(item => +get(item, 'quantity', 0))
        .reduce((prev, curr) => prev + curr, 0)
    : 0
)

export const itemQuantity = itemId => createDraftSafeSelector(
  itemsSelector,
  items => !!items 
    ? items
        .filter(i => i.id === itemId)
        .map(item => +get(item, 'quantity', 0))
        .reduce((prev, curr) => prev + curr, 0)
    : 0
)

export const itemByIndex = index => createDraftSafeSelector(
  itemsSelector,
  items => !!items ? items[index]: null
)

export const subtotal = createDraftSafeSelector(
  [itemsSelector, decimalPrecision, country, companySelector],
  (items, decimal, country, companySelector) => !!items 
    ? calculateItemsValues({items, decimal, country, company: companySelector}).subtotal
    : 0
)

export const discount = createDraftSafeSelector(
  [itemsSelector, decimalPrecision, country, companySelector],
  (items, decimal, country, companySelector) => !!items 
    ? calculateItemsValues({items, decimal, country, company: companySelector}).discount
    : 0
)

export const discSubtotal = createDraftSafeSelector(
  [itemsSelector, decimalPrecision, country, companySelector],
  (items, decimal, country, companySelector) => !!items 
    ? calculateItemsValues({items, decimal, country, company: companySelector}).discSubtotal
    : 0
)

export const taxes = createDraftSafeSelector(
  [itemsSelector, decimalPrecision, country, companySelector],
  (items, decimal, country, companySelector) => !!items 
    ? calculateItemsValues({items, decimal, country, company: companySelector}).taxes
    : 0
)

export const total = createDraftSafeSelector(
  [itemsSelector, decimalPrecision, tipSelector, country, companySelector],
  (items, decimal, tips, country, companySelector) => {
    let transformTip = null

    if (tips?.length > 0) {
      transformTip = {
        firstCheck: true,
        include: true,
        type: 'VALUE',
        percentage: null,
        value: tips[0]?.amount
      }
    }

    return !!items 
      ? calculateItemsValues({items, decimal, tips: transformTip, country, company: companySelector}).total
      : 0
  }
)

export const settings = createDraftSafeSelector(
  [currency, priceList, numeration],
  (currency, priceList, numeration) => ({
    currency,
    priceList,
    numeration
  })
)