import React, { useEffect, useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize, forEach, get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { BigNumber } from 'bignumber.js';

import {
  companySelector,
  country as countrySelector,
  decimalPrecision,
} from '../../../../selectors/company';
import { renderField, renderSelect } from '../../fields/V0/Fields';
import UseField from './UseField';
import { Divider, Typography, useDeviceType } from '@alegradev/smile-ui-react';
import { activeSellers } from '../../../../selectors/sellers';
import { openModal } from '../../../../reducers/modals';
import { hasPermissionTo } from '../../../../selectors/auth';
import { checkFeatureLimit } from '../../../../reducers/membership';
import { COUNTRIES } from '../../../../utils/enums/countries';
import { isMexicoFeAvailable } from '../../../../utils/deploys/mexicoFeDeployGroups';
import useElectronicPayrollStatus from '../../../../hooks/useElectronicPayrollStatus/hook';

import { allRegimes } from '../../../countriesData/mexico/regimes';
import {
  MEXICO_DEFAULT_OTHER_RFC,
  MEXICO_DEFAULT_RFC,
} from '../../../countriesData/mexico/rfc';
import configAPI from '../../../../reducers/configAPI';
import { client as clientSelector } from '../../../../selectors/activeInvoice';
import { DEFAULT_CFDI_USE } from '../../../countriesData/mexico/cfdiUses';

const countryDenominations = (code) => {
  switch (code) {
    case 'COP':
      return [1000, 2000];
    case 'CRC':
      return [1000, 2000];
    case 'DOP':
      return [20, 50];
    case 'MXN':
      return [20, 50];
    case 'PEN':
      return [10, 20];
    case 'ARL':
      return [20, 50];
    case 'CLP':
      return [1000, 2000];
    default:
      return [5, 10];
  }
};

const fastOptions = (code, total) => {
  const denominations = countryDenominations(code);
  const aux = Math.floor(total / denominations[0]);
  return [total, denominations[0] * (aux + 1), denominations[0] * (aux + 2)];
};

const NewCash = ({ values, total, fmt, currencyCode, anotationMaxLimit }) => {
  const country = useSelector(countrySelector);
  const company = useSelector(companySelector);
  const { isActive } = useElectronicPayrollStatus();
  const documentType = get(values, 'numeration.documentType', '');
  const client = useSelector(clientSelector);
  const [uses, setUses] = useState([]);
  const sellers = useSelector(activeSellers);
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const decimal = 2;
  const cashFastOptions = fastOptions(currencyCode, total.toNumber());
  const deviceType = useDeviceType();

  useEffect(() => {
    const loadCfdiUses = async () => {
      if (country !== COUNTRIES.MEXICO) return;
      const response = await configAPI.get('/cfdi-uses', {
        application_version: country,
      });
      setUses(get(response, 'data', []));
    };
    loadCfdiUses();
  }, [client]);

  const isFeMexActive = useMemo(() => {
    return isMexicoFeAvailable(country, isActive);
  }, [country, company, isActive]);

  const isUseDisabled = useMemo(() => {
    if (!client) return true;
    if (
      (client && client?.identification === MEXICO_DEFAULT_RFC) ||
      client?.identification === MEXICO_DEFAULT_OTHER_RFC
    )
      return true;
    return false;
  }, [client]);

  const useOptions = useMemo(() => {
    const options = [];
    if (country === COUNTRIES.MEXICO) {
      if (client) {
        forEach(client?.regimeObject, (value) => {
          if (allRegimes[value]) {
            forEach(uses, (use) => {
              const splitRegimes = use?.regimes?.split('|');
              if (splitRegimes.includes(`${allRegimes[value].code}`)) {
                options.push({
                  key: use.key,
                  value: use.value,
                });
              }
            });
          }
        });
      } else {
        options.push(DEFAULT_CFDI_USE);
      }
    }

    return options;
  }, [uses, client]);

  const getFastOptionFontSize = (value) => {
    const length = value.length;

    if (length >= 14) {
      return '9px';
    } else if (length === 13) {
      return '9.5px';
    } else if (length === 12) {
      return '10px';
    } else if (length === 11) {
      return '11px';
    } else if (length === 10) {
      return '12.5px';
    } else if (length === 9) {
      return '13px';
    } else if (length === 8) {
      return '14px';
    } else if (length === 7) {
      return '15px';
    } else {
      return '16px';
    }
  };

  return (
    <>
      <div className='col-sm-12 form-row pl-2 pr-0'>
        <div className='col-sm-12 col-md-6'>
          <Field
            name='cash'
            className='col-sm-12 p-0'
            type='number'
            label={
              <Typography
                type='label-2'
                text={capitalize(
                  I18n.get('cashValueInput', 'valor del pago en efectivo')
                )}
                variant='secondary'
              />
            }
            placeholder={I18n.get('enterTheValue', 'Ingresa el valor')}
            required
          >
            {(props) => (
              <>
                {renderField({
                  ...props,
                  fontSize: '16px',
                  height: '48px',
                  fontColor: '#334155',
                  paddingLeft: '10px',
                })}
                <Divider text={I18n.get('fastOptions', 'opciones rápidas')} />
                {!!total && (
                  <div className='col-sm-12 p-0 ml-1 d-flex form-row form__invoice-cash-options'>
                    <button
                      type='button'
                      className='btn btn-cancel'
                      onClick={() => props.input.onChange(cashFastOptions[0])}
                    >
                      <Typography
                        maxLength={15}
                        text={new BigNumber(cashFastOptions[0]).toFormat(
                          decimal,
                          fmt
                        )}
                        style={{
                          fontSize: getFastOptionFontSize(
                            new BigNumber(cashFastOptions[0]).toFormat(
                              decimal,
                              fmt
                            )
                          ),
                        }}
                        type='label-1'
                      />
                    </button>

                    <button
                      type='button'
                      className='btn btn-cancel'
                      onClick={() => props.input.onChange(cashFastOptions[1])}
                    >
                      <Typography
                        text={new BigNumber(cashFastOptions[1]).toFormat(
                          decimal,
                          fmt
                        )}
                        type='label-1'
                        style={{
                          fontSize: getFastOptionFontSize(
                            new BigNumber(cashFastOptions[1]).toFormat(
                              decimal,
                              fmt
                            )
                          ),
                        }}
                      />
                    </button>

                    <button
                      type='button'
                      className='btn btn-cancel'
                      onClick={() => props.input.onChange(cashFastOptions[2])}
                    >
                      <Typography
                        text={new BigNumber(cashFastOptions[2]).toFormat(
                          decimal,
                          fmt
                        )}
                        type='label-1'
                        style={{
                          fontSize: getFastOptionFontSize(
                            new BigNumber(cashFastOptions[2]).toFormat(
                              decimal,
                              fmt
                            )
                          ),
                        }}
                      />
                    </button>
                  </div>
                )}
              </>
            )}
          </Field>
        </div>

        <div
          className={`col-sm-12 col-md-6 pr-0 ${deviceType === 'mobile' ? '' : 'form__invoice-advanced-data'}`}
        >
          {country === COUNTRIES.MEXICO &&
            isFeMexActive &&
            documentType === 'invoice' && (
              <Field
                name='use'
                className={`col-sm-12 ${deviceType === 'mobile' ? 'p-0' : 'pl-2 pr-0'}`}
                options={useOptions}
                disabled={isUseDisabled}
                required
                label={
                  <Typography
                    text={capitalize(I18n.get('use', 'Uso'))}
                    type='label-2'
                    variant='secondary'
                  />
                }
                defaultValue={useOptions.length > 1 ? null : useOptions[0]}
                getOptionLabel={(option) => option.value}
                getOptionValue={(option) => option.key}
              >
                {(props) => (
                  <>
                    {renderSelect({
                      ...props,
                      fontSize: '16px',
                      borderRadius: '12px',
                      height: '48px',
                      fontColor: '#334155',
                    })}
                  </>
                )}
              </Field>
            )}
          <div className='d-flex justify-content-center'>
            <Field
              name='seller'
              className={`col-sm-12 ${deviceType === 'mobile' ? 'p-0' : 'pl-2 pr-0'}`}
              options={sellers}
              label={
                <Typography
                  text={capitalize(I18n.get('seller', 'vendedor'))}
                  type='label-2'
                  variant='secondary'
                />
              }
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              addOptionText={
                can('add', 'sellers')
                  ? capitalize(I18n.get('newSeller', 'Nuevo vendedor'))
                  : null
              }
              addOptionAction={() =>
                dispatch(
                  checkFeatureLimit(
                    'sellers',
                    can('add', 'sellers')
                      ? () => dispatch(openModal({ modal: 'seller' }))
                      : null
                  )
                )
              }
              menuPosition='absolute'
            >
              {(props) => (
                <>
                  {renderSelect({
                    ...props,
                    fontSize: '16px',
                    borderRadius: '12px',
                    height: '48px',
                    fontColor: '#334155',
                  })}
                </>
              )}
            </Field>
          </div>
          <div
            className={`col-sm-12 ${deviceType === 'mobile' ? 'p-0' : 'pl-2 pr-0'}`}
          >
            <Field
              name='anotation'
              className={`modal__invoice-anotation-field ${
                anotationMaxLimit ? 'has-error' : ''
              }`}
              label={
                <Typography
                  type='label-2'
                  text={capitalize(I18n.get('observations', 'observaciones'))}
                  variant='secondary'
                />
              }
              placeholder={I18n.get(
                'addYourObservation',
                'Ingresa tu observación'
              )}
              type='textarea'
            >
              {(props) =>
                renderField({
                  ...props,
                  fontSize: '16px',
                  height: '48px',
                  borderRadius: '12px',
                  fontColor: '#334155',
                  borderColor: '#CBD5E1',
                  paddingLeft: '10px',
                })
              }
            </Field>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewCash;
