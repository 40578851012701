import { capitalize } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';

import useElectronicPayrollStatus from '../../../hooks/useElectronicPayrollStatus/hook';

import { closeMenu } from '../../../reducers/app';
import { openModal } from '../../../reducers/modals';
import Tag from '../../common/Tag';
import Typography from '../../common/Typography';
import { country as countrySelector } from '../../../selectors/company';
import { COUNTRIES } from '../../../utils/enums/countries';
import { Icon } from '@alegradev/smile-ui-react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function ElectronicPayrollStatus() {
  const dispatch = useDispatch();
  const history = useHistory();
  const country = useSelector(countrySelector);
  const { isActive } = useElectronicPayrollStatus();

  const handleOpenModal = (e) => {
    e.preventDefault();

    if (isActive) {
      return;
    }

    dispatch(closeMenu());

    if (country === COUNTRIES.MEXICO) {
      window.open(
        'https://ayuda.alegra.com/es/pasos-para-habilitar-tu-facturaci%C3%B3n-electr%C3%B3nica-en-alegra-m%C3%A9xico',
        '_blank',
        'noopener noreferrer'
      );
      return;
    }

    if (country === COUNTRIES.SPAIN) {
      history.push('/settings/company');
      return;
    };

    dispatch(
      openModal({
        modal: 'electronicPayrollTutorial',
      })
    );
  };

  const renderElectronicPayrollText = (country) => {
    switch (country) {
      case COUNTRIES.COLOMBIA:
        return I18n.get('dianActivation', 'Habilitación DIAN');
      case COUNTRIES.SPAIN:
        return I18n.get('ticketBai', 'TicketBAI');
      default:
        return I18n.get('', 'Facturación electrónica');
    }
  };

  return (
    <div
      className='electronic-payroll-status w-100 d-flex align-items-center justify-content-between p-0'
      onClick={(e) => handleOpenModal(e)}
    >
      <div className='d-flex align-items-center'>
        <Icon
          icon='receipt-2'
        />
        <Typography
          type='body-3-bold'
          variant='secondary'
          text={renderElectronicPayrollText(country)}
        />
      </div>

      <Tag
        text={capitalize(
          isActive
            ? I18n.get('activated', 'activada')
            : I18n.get('deactivated', 'desactivada')
        )}
        variant={isActive ? 'green' : 'slate'}
      />
    </div>
  );
}

export default ElectronicPayrollStatus;
