import React, { useEffect, useRef, useState } from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { useSelector, useDispatch } from 'react-redux'
import { country as countrySelector } from '../../../../selectors/company'
import economicActivities from '../../../countriesData/costaRica/economicActivities.json';
import { renderSearchField } from '../../fields/V0/Fields';
import { addEconomicActivity, deleteEconomicActivity, selectedMainActivity, setAllEconomicActivities } from '../../../../reducers/economicActyivity';
import { allEconomicActivities, mainActivity as mainActivitySelector } from '../../../../selectors/economicActivity';
import { economicActivitiesSelector, hasPermissionTo } from '../../../../selectors/auth';

const RenderEconomicActivity = (props) => {
  const [economicActivitiesOptions, setEconomicActivitiesOptions] = useState(economicActivities);
  const refs = useRef(economicActivitiesOptions.map(() => React.createRef()));
  const inputRef = useRef(null);
  const country = useSelector(countrySelector);
  const dispatch = useDispatch();
  const [openListActivities, setOpenListActivities] = useState(false);
  const selectedListActivities = useSelector(allEconomicActivities);
  const economicActivitiesList = useSelector(economicActivitiesSelector);
  const mainActivity = useSelector(mainActivitySelector);
  const [showMessageDelete, setShowMessageDelete] = useState(false);
  const [isOnTheListActivities, setIsOnTheListActivities] = useState(false);
  const can = useSelector(hasPermissionTo);

  const handleChecked = (e) => {
    const { value, checked, name } = e.target;
    let item = { code: name, main: !selectedListActivities.length ? true : false, value };
    // reset input search
    inputRef.current && (inputRef.current.value = '');
    if (checked) {
      dispatch(addEconomicActivity(item));
    } else {
      const activity = selectedListActivities.find(activity => activity.code === e.target.name && activity.main === true);
      if (activity) {
        e.target.checked = true;
        setShowMessageDelete(true);
        return;
      }
      deleteChecked(item);
    }

  }

  const deleteChecked = (selectedActivity) => {
    const activity = { ...selectedActivity };
    if (activity.main) {
      setShowMessageDelete(true);
      return;
    };
    onChangeChecked(activity, false);
    dispatch(deleteEconomicActivity(activity));
  }

  const handleSelectFavorite = (selectedActivity) => {
    dispatch(selectedMainActivity(selectedActivity));
  }

  const loadEconomicActivities = async (economicActivities) => {
    economicActivities.forEach(activity => {
      onChangeChecked(activity, true);
    });
  }

  // metodo para marcar actividad economica seleccionada
  const onChangeChecked = (activity, isChecked) => {
    refs.current.length > 0 && refs.current.forEach(ref => {
      if (ref.current && ref.current.value === activity.value && activity.code === ref.current.name) {
        ref.current.checked = isChecked;
        return;
      }
    })
  }

  const searchActivityByTerm = (values) => {
    const term = values.target.value;
    const activities = economicActivities.filter(activity => activity.value.toLowerCase().includes(term.toLowerCase()) || activity.key.toLowerCase().includes(term.toLowerCase()));
    setEconomicActivitiesOptions(activities);
    setTimeout(() => {
      loadEconomicActivities(selectedListActivities);
    }, 1000);

  }

  useEffect(() => {
    if (country === 'costaRica') {
      dispatch(setAllEconomicActivities(economicActivitiesList));
      loadEconomicActivities(economicActivitiesList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const time = setTimeout(() => {
      setShowMessageDelete(false)
    }, 3000);

    return () => {
      clearTimeout(time)
    }
  }, [showMessageDelete])

  useEffect(() => {
    if (!isOnTheListActivities) {
      setOpenListActivities(false);
    }
  }, [isOnTheListActivities])

  useEffect(() => {
    if (mainActivity) {
      inputRef.current && (inputRef.current.placeholder = mainActivity ? mainActivity.value : '');
    } else {
      inputRef.current && (inputRef.current.placeholder = I18n.get('searchActivity', 'Buscar actividad'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainActivity])

  return country === 'costaRica' ? (
    <Field
      name="economicActivity"
      component={renderSearchField}
      className="col-md-6"
      label={I18n.get('economicActivity', 'actividad económica')}
      type="text"
      placeholder={I18n.get('searchActivity', 'Buscar actividad')}
      options={economicActivitiesOptions}
      onViewOptions={() => setOpenListActivities(true)}
      onSearchByTerm={searchActivityByTerm}
      onChange={handleChecked}
      onBlur={() => setOpenListActivities(false)}
      showActivities={openListActivities}
      selectedActivities={selectedListActivities}
      deleteActivity={deleteChecked}
      selectedFavorite={handleSelectFavorite}
      onCloseList={() => setOpenListActivities(false)}
      showMessageDelete={showMessageDelete}
      helpTooltip={I18n.get('economicActivityTooltip', 'Selecciona todas tus actividades económicas y marca una como preferida desde la ⭐')}
      refs={refs}
      isOnTheListActivities={isOnTheListActivities}
      setIsOnTheListActivities={(value) => setIsOnTheListActivities(value)}
      inputRef={inputRef}
      disabled={!can('edit', 'company')}
    />
  ) : null
}

export default RenderEconomicActivity;