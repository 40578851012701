import { useState, useEffect } from 'react';

const useDetectOS = () => {
  const [os, setOS] = useState(null);

  useEffect(() => {
    const detectOS = () => {
      const userAgent = window.navigator.userAgent;
      const platform = window.navigator.userAgentData?.platform || window.navigator.platform;

      const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'Mac'];
      const windowsPlatforms = ['Win64', 'Windows', 'WinCE'];
      const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
      let os = null;

      if (macosPlatforms.includes(platform)) {
        os = 'dmg';
      } else if (iosPlatforms.includes(platform)) {
        os = 'ipa';
      } else if (windowsPlatforms.includes(platform)) {
        os = 'msi';
      } else if (/Android/.test(userAgent)) {
        os = 'apk';
      } else if (!os && /Linux/.test(platform)) {
        os = 'tar.gz';
      }

      return os;
    };

    setOS(detectOS());
  }, []);

  //return msi always for testing purposes
  return 'msi' || os;
};

export default useDetectOS;
