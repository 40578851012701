import { get } from 'lodash';

import countries from '../../../countriesData/general/countries';
import colombiaAddresses from '../../../countriesData/colombia/citiesDepartments.json';
import panamaAddresses from '../../../countriesData/panama/cities.json';
import costaRicaAddresses from '../../../countriesData/costaRica/districtsCantonsProvinces.json';
import neighborhoods from '../../../countriesData/costaRica/dataNeighborhood';
import argentinaProvinces from '../../../countriesData/argentina/provinces';
import argentinaCities from '../../../countriesData/argentina/cities.json';
import argentinaIVAConditions from '../../../countriesData/argentina/companyIvaConditions';
import argentinaIibbConditions from '../../../countriesData/argentina/conditionsIibb';
import dominicanaAddresses from '../../../countriesData/republicaDominicana/municipalityProvince.json';
import { idTypes as identificationsColombia } from '../../../countriesData/colombia/idTypes';
import kindOfPersonOptions from '../../../countriesData/colombia/kindOfPerson';
import identificationsCostaRica from '../../../countriesData/costaRica/identificationTypes';
import identificationsArgentina from '../../../countriesData/argentina/identificationTypes';
import spainProvinces from '../../../countriesData/spain/provinces.json'
import spainMunicipalities from '../../../countriesData/spain/municipalityProvince.json';

const initialValuesPerCountry = ({ company, country, isElectronic }) => {
  switch (country) {
    case 'colombia':
      return {
        identification: !!get(company, 'identificationObject.number')
          ? {
            type: identificationsColombia(isElectronic).find(option =>
              option.key === get(company, 'identificationObject.type', null)),
            number: get(company, 'identificationObject.number', null),
            dv: get(company, 'identificationObject.dv', null)
          } : {
            type: identificationsColombia(isElectronic)[0]
          },
        firstName: get(company, 'kindOfPerson', 'LEGAL_ENTITY') === 'PERSON_ENTITY' ? get(company, 'nameObject.firstName', '') : get(company, 'name', ''),
        lastName: get(company, 'kindOfPerson', 'LEGAL_ENTITY') === 'PERSON_ENTITY' ? get(company, 'nameObject.lastName', '') : null,
        kindOfPerson: kindOfPersonOptions.find(option => option.key === get(company, 'kindOfPerson', 'LEGAL_ENTITY')),
        address: {
          country: countries.find(option => option.key === 'COL'),
          combined: colombiaAddresses.find(option =>
            option.value === `${get(company, 'address.city', null)}, ${get(company, 'address.department', null)}`),
          zipCode: get(company, 'address.zipCode', null),
          address: get(company, 'address.address', null),
        }
      }
    case 'peru':
      return {
        identification: get(company, 'identification', ''),
        address: {
          country: countries.find(option => option.key === 'PER'),
          district: get(company, 'address.district', null),
          state: get(company, 'address.state', null),
          city: get(company, 'address.city', null),
          ubigeoCode: get(company, 'address.ubigeoCode', null),
          urbanization: get(company, 'address.urbanization', null),
          address: get(company, 'address.address', null),
        }
      }
    case 'argentina':
      return {
        identification: !!get(company, 'identificationObject.number')
          ? {
            type: identificationsArgentina.find(option =>
              option.key === get(company, 'identificationObject.type', null)),
            number: get(company, 'identificationObject.number', null),
          } : {
            type: identificationsArgentina[0]
          },
        ivaCondition: argentinaIVAConditions.find(option =>
          option.key === get(company, 'ivaCondition', null)),
        address: {
          province: argentinaProvinces.find(option =>
            option.value === get(company, 'address.province', null)),
          city: argentinaCities.find(option =>
            option.value === get(company, 'address.city', null)),
          postalCode: get(company, 'address.postalCode', null),
          address: get(company, 'address.address', null),
        },
        conditionIibb: argentinaIibbConditions.find(option => option.key === get(company, 'conditionIibb')),
      }
    case 'costaRica':
      return {
        identification: !!get(company, 'identificationObject.number')
          ? {
            type: identificationsCostaRica.find(option =>
              option.key === get(company, 'identificationObject.type', null)),
            number: get(company, 'identificationObject.number', null)
          } : {
            type: identificationsCostaRica[0]
          },
        address: {
          country: countries.find(option => option.key === 'CRI'),
          combined: costaRicaAddresses.find(option =>
            option.value === `${get(company, 'address.district', null)}, ${get(company, 'address.city', null)}, ${get(company, 'address.department', null)}`),
          neighborhood: neighborhoods.find(option =>
            option.value === get(company, 'address.neighborhood', null)),
          address: get(company, 'address.address', null),
        }
      }
    case 'republicaDominicana':
      return {
        address: {
          combined: dominicanaAddresses.find(option =>
            option.value === `${get(company, 'address.municipality', null)}, ${get(company, 'address.province', null)}`),
          description: get(company, 'address.description', null),
        }
      }
    case 'mexico':
      return {
        address: {
          country: countries.find(option => option.key === 'MEX'),
          street: get(company, 'address.street', null),
          locality: get(company, 'address.locality', null),
          exteriorNumber: get(company, 'address.exteriorNumber', null),
          interiorNumber: get(company, 'address.interiorNumber', null),
          zipCode: get(company, 'address.zipCode', null),
          state: get(company, 'address.state', null),
          municipality: get(company, 'address.municipality', null),
          colony: get(company, 'address.colony', null),
        }
      }
    case 'panama':
      const kindOfPersonKey = get(company, 'identificationObject.kindOfPerson', 'LEGAL_ENTITY');
      return {
        kindOfPerson: kindOfPersonOptions.find(option => option.key === kindOfPersonKey),
        tradeName: get(company, 'tradeName', ''),
        address: {
          address: get(company, 'address.address', null),
          geographicCoordinates: get(company, 'address.geographicCoordinates', null),
          combined: panamaAddresses.find(option =>
            option.value === get(company, 'address.township') && option.parentValue === get(company, 'address.district') && option.provinceValue === get(company, 'address.province')),
        },
        firstName: kindOfPersonKey === 'PERSON_ENTITY' ? get(company, 'nameObject.firstName', '') : get(company, 'name', ''),
        secondName: kindOfPersonKey === 'PERSON_ENTITY' ? get(company, 'nameObject.secondName', '') : null,
        lastName: kindOfPersonKey === 'PERSON_ENTITY' ? get(company, 'nameObject.lastName', '') : null,
      }
    case 'spain':
      return {
        tradeName: get(company, 'localSettings.tradeName', ''),
        address: {
          ...get(company, 'address', {}),
          province: spainProvinces.find(
            (option) => option.value === get(company, 'address.province', null)
          ) || null,
          city: get(company, 'address.province', null) ? spainMunicipalities.find(
            (option) => option.municipality === get(company, 'address.city', null)
          ) : null
        },
        decimalSeparator: !!get(company, 'decimalSeparator', null)
          ? {
            key: get(company, 'decimalSeparator'),
            label: get(company, 'decimalSeparator'),
          }
          : { key: '', label: '' },
      };
    default:
      return {
        address: {
          city: get(company, 'address.city', null),
          address: get(company, 'address.address', null),
        }
      };
  }
}

const initialValues = ({ company, ...props }) => {
  return {
    firstName: get(company, 'name', ''),
    ...company,
    decimalPrecision: !!get(company, 'decimalPrecision', null)
      ? { key: get(company, 'decimalPrecision'), label: get(company, 'decimalPrecision') }
      : { key: '0', label: '0' },
    decimalSeparator: !!get(company, 'decimalSeparator', null)
      ? { key: get(company, 'decimalSeparator'), label: get(company, 'decimalSeparator') }
      : { key: ',', label: ',' },
    ...initialValuesPerCountry({ company, ...props }),
    sector: !!get(company, 'sector', null)
      ? { key: get(company, 'sector'), value: get(company, 'sector') }
      : { key: 'RETAIL', label: 'Comercio al detal' }
  }
}

export default initialValues;